import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

export const MenuParts = ['item'] as const;

export const MenuComponentName = 'Menu' as const;

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(MenuParts);

const baseStyleItem = defineStyle({
  bg: '#fff',
  color: 'neutral.500',

  _hover: {
    bg: 'neutral.50',
  },
});

const baseStyle = definePartsStyle({
  item: baseStyleItem,
});

export const Menu = defineMultiStyleConfig({
  baseStyle,
});
