import { defineStyleConfig } from '@chakra-ui/react';

export const DescriptionListComponentName = 'DescriptionList' as const;
export const DescriptionListItemComponentName = 'DescriptionListItem' as const;
export const DescriptionListTermComponentName = 'DescriptionListTerm' as const;

export const DescriptionListStyle = defineStyleConfig({
  baseStyle: {
    bgColor: 'surface',
    borderRadius: 'default',
    padding: 4,
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 1fr) minmax(min-content, 2fr)',
    gridTemplateRows: '1fr',
    gridColumnGap: 2,
    gridRowGap: 4,
  },
  variants: {
    alignRight: {
      gridTemplateColumns: '1fr max-content',
      dd: {
        textAlign: 'right',
      },
    },
  },
});

export const DescriptionListTermStyle = defineStyleConfig({
  baseStyle: {
    fontWeight: 'bold',
    gridColumnStart: 1,
  },
  variants: {
    small: {
      fontWeight: 'normal',
      fontSize: 'sm',
      marginTop: '-5px',
    },
  },
});

export const DescriptionListItemStyle = defineStyleConfig({
  baseStyle: {
    gridColumnStart: 2,
  },
  variants: {
    small: {
      fontWeight: 'normal',
      fontSize: 'sm',
      marginTop: '-5px',
    },
  },
});
