import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// TODO translate currency label?
export const useCurrencyFormatter = (currency: string = 'CHF') => {
  const { i18n } = useTranslation();
  const locale = useMemo(() => {
    switch (i18n.language) {
      case 'de':
      case 'fr':
      case 'it':
        return i18n.language + '-CH';

      default:
        return i18n.language;
    }
  }, [i18n.language]);
  const formatter = useMemo(() => new Intl.NumberFormat(locale, { style: 'currency', currency }), [currency, locale]);
  return formatter;
};
