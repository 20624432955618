import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { BaseQueryFn, fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { QueryErrorPayload } from './queryErrorMiddleware';
import queryString from 'query-string';

type ErrorHandler = (error: QueryErrorPayload, dispatch: Dispatch) => void;

type GlobalConfig = {
  queryOptions: FetchBaseQueryArgs;
  hydrateActionType?: string;
  errorHandler?: ErrorHandler;
};

let globalConfig: GlobalConfig = {
  queryOptions: {
    paramsSerializer: queryString.stringify,
  },
  errorHandler: (error) => {
    console.error(error);
  },
};

export const baseQuery: BaseQueryFn = async (args, api, options) => {
  const internalBaseQuery = fetchBaseQuery(globalConfig.queryOptions);
  return internalBaseQuery(args, api, options);
};

export const extractRehydrationInfo = <ReducerPath extends string>(
  action: AnyAction,
  { reducerPath }: { reducerPath: ReducerPath }
) => {
  if (globalConfig.hydrateActionType && action.type === globalConfig.hydrateActionType) {
    return action.payload[reducerPath];
  }
};

export const dispatchError: ErrorHandler = (error, dispatch) => {
  if (globalConfig?.errorHandler) {
    globalConfig.errorHandler(error, dispatch);
  }
};

export const initialize = (config: GlobalConfig) => {
  globalConfig = {
    ...globalConfig,
    ...config,
    queryOptions: {
      ...globalConfig.queryOptions,
      ...config.queryOptions,
    },
  };
};
