import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Link, List, ListItem, useMultiStyleConfig } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

import { paths } from '@/config';
import { adPath } from '@/lib/routes';
import { CommonSteps } from '@/pages/Ad/constants';

import { AccountSelectionButton } from './AccountSelectionButton';
import { LanguageSwitcher } from './LanguageSwitcher';
import { SidebarComponentName } from '@/theme';
import { RouterLink } from '@/components/global';

export const Sidebar: FC<BoxProps> = ({ ...rest }) => {
  const { t } = useTranslation();
  const styles = useMultiStyleConfig(SidebarComponentName);

  const manualText = t('ui.navigation.manual');

  return (
    <Box sx={styles.container} {...rest}>
      <List sx={styles.list}>
        <ListItem sx={styles.listItem}>
          <RouterLink sx={styles.listItemLink} to={paths.ACCOUNT}>
            {t('ui.navigation.account')}
          </RouterLink>
        </ListItem>
        <ListItem sx={styles.listItem}>
          <RouterLink sx={styles.listItemLink} to={paths.MYADS}>
            {t('ui.navigation.myads')}
          </RouterLink>
        </ListItem>
        <ListItem sx={styles.listItem}>
          <RouterLink sx={styles.listItemLink} to={adPath(0, CommonSteps[0].key)}>
            {t('ui.navigation.newad')}
          </RouterLink>
        </ListItem>
        {manualText && (
          <Link
            href="https://www.tierwelt.ch/kleinanzeigen/anleitung-zur-erfassung-von-kleinanzeigen"
            target="_blank"
            rel="noreferrer"
            display="block"
            marginTop={6}
          >
            <ExternalLinkIcon marginRight={1} />
            {manualText}
          </Link>
        )}
      </List>

      <AccountSelectionButton
        type="button"
        colorScheme="gray"
        width="full"
        mb={8}
        display={{ base: 'block', md: 'none' }}
      />

      <LanguageSwitcher display={{ base: 'block', md: 'none' }} />
    </Box>
  );
};
