import { defineStyleConfig } from '@chakra-ui/react';
import { SelectableSystemStyleObject } from '../types';

export const SelectableTagComponentName = 'SelectableTag' as const;

export const SelectableTagStyle = defineStyleConfig({
  baseStyle: ({ colorScheme }) => {
    return {
      bgColor: 'surface',
      color: 'dark',
      cursor: 'pointer',
      fontWeight: 'bold',
      px: 4,
      py: 3,
      _selected: {
        _hover: {
          bgColor: `${colorScheme}.300`,
        },
        bgColor: `${colorScheme}.500`,
      },
      _disabled: {
        cursor: 'default',
      },
      _hover: {
        bgColor: 'surfaceHover',
      },
    } as SelectableSystemStyleObject;
  },
});
