import { ThemeOverride } from '@chakra-ui/react';

const saezTheme: ThemeOverride = {
  styles: {
    global: {
      ':root': {
        fontSize: '16px',
      },
      a: {
        textDecoration: 'underline',
      },
    },
  },
  colors: {
    primary: {
      main: '#000AB0',
      '50': '#636cff',
      '100': '#313cff',
      '200': '#1724ff',
      '300': '#000efc',
      '400': '#000de3',
      '500': '#000AB0',
      '600': '#00077d',
      '700': '#000663',
      '800': '#00044a',
      '900': '#000117',
    },
    secondary: {
      main: '#0098D9',
      '50': '#8dddff',
      '100': '#5acdff',
      '200': '#40c6ff',
      '300': '#26beff',
      '400': '#0db7ff',
      '500': '#0098D9',
      '600': '#0074a6',
      '700': '#00628c',
      '800': '#005173',
      '900': '#002d40',
    },
  },

  semanticTokens: {
    sizes: {
      'layout.sm': '480px',
      'layout.md': '768px',
      'layout.lg': '1200px',
      'layout.xl': '1200px',
      'layout.2xl': '1200px',
    },
  },
  components: {
    Button: {
      variants: {
        secondary: {
          bgColor: 'neutral.50',
        },
        outline: {
          border: 'default',
          color: '#fff',
        },
        ghost: {
          color: 'inherit',
        },
      },
    },
    Header: {
      baseStyle: {
        logo: {
          pl: 3,
        },
      },
    },
    Steps: {
      baseStyle: {
        'step.active': {
          color: 'neutral.50',
        },
      },
    },
    SelectableCard: {
      baseStyle: {
        _selected: {
          bgColor: `primary.500`,
          color: 'neutral.50',
          _hover: {
            bgColor: `primary.600`,
          },
        },
      },
    },
    SelectableTag: {
      baseStyle: {
        bgColor: 'surface',
        color: 'dark',
        _selected: {
          _hover: {
            bgColor: `secondary.600`,
          },
          color: 'neutral.50',
          bgColor: `secondary.500`,
        },
      },
    },
  },
  fonts: {
    body: `'IBM Plex Sans', sans-serif`,
    heading: `'IBM Plex Sans', sans-serif`,
  },
};

export default saezTheme;
