import { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultLayout as Layout } from '@/components/layout';
import { useAppSelector } from '@/store';

import { AccountDetails, AccountForm, NewUserNotification } from './components';

export const AccountPage: FC = memo(() => {
  const { isNewUser } = useAppSelector((state) => state.account);
  const { t } = useTranslation();
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (isNewUser) {
      setIsEditable(true);
    }
  }, [setIsEditable, isNewUser]);

  return (
    <Layout title={t('account.title')}>
      {!isEditable && <AccountDetails onEdit={() => setIsEditable(true)} />}
      {isEditable && (
        <>
          {isNewUser && <NewUserNotification />}
          <AccountForm onCancel={() => setIsEditable(false)} />
        </>
      )}
    </Layout>
  );
});
