import { FC, useCallback, useMemo, useRef } from 'react';
import { StackDivider, VStack } from '@chakra-ui/react';
import { LexicalEditor } from 'lexical';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';

import { Toolbar, ToolbarTexts } from './components';
import { FormattedTextPlugin } from './FormattedTextPlugin';
import { HtmlValuePlugin } from './HtmlValuePlugin';
import { getConverter, HtmlConverter } from './converters';
import { ThemeClasses } from './constants';

export type HtmlEditorProps = {
  disabled?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  converter?: HtmlConverter | string;
  singleFormat?: boolean;
  toolbarTexts?: ToolbarTexts;
};

export const HtmlEditor: FC<HtmlEditorProps> = ({
  disabled,
  value,
  onChange,
  converter,
  singleFormat,
  toolbarTexts,
}) => {
  const handleError = useCallback((error: Error, editor: LexicalEditor) => {
    console.error('Unhandled error in editor.', error, editor);
  }, []);

  const htmlConverter = useMemo<HtmlConverter>(() => getConverter(converter), [converter]);
  const prevValue = useRef(value);

  const handleChange = useCallback(
    (newValue: string) => {
      if (newValue !== prevValue.current) {
        prevValue.current = newValue;
        onChange?.(htmlConverter.convertFromEditorHtml(newValue));
      }
    },
    [htmlConverter, onChange]
  );

  return (
    <VStack divider={<StackDivider />} spacing={2} align="stretch" border="default" borderRadius="default">
      <LexicalComposer
        initialConfig={{
          editable: !disabled,
          namespace: 'SimpleHtmlEditor',
          theme: ThemeClasses,
          onError: handleError,
        }}
      >
        <Toolbar texts={toolbarTexts} />
        <FormattedTextPlugin singleFormat={singleFormat} />
        <HtmlValuePlugin converter={htmlConverter} value={value} onChange={handleChange} />
        <HistoryPlugin />
      </LexicalComposer>
    </VStack>
  );
};
