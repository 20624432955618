import { FC } from 'react';
import { Box, chakra, Spinner, SpinnerProps } from '@chakra-ui/react';

const Wrapper = chakra(Box, {
  baseStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const Overlay = chakra(Box, {
  baseStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'black',
    opacity: '25%',
  },
});

type LoaderProps = SpinnerProps & {
  isLoading: boolean;
  showOverlay?: boolean;
};

export const Loader: FC<LoaderProps> = ({ isLoading, showOverlay, color = 'primary', size = 'md', ...rest }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <Wrapper>
      {showOverlay && <Overlay />}
      <Spinner color={color} size={size} {...rest} />
    </Wrapper>
  );
};
