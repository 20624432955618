import { ContentEditable as LexicalContentEditable } from '@lexical/react/LexicalContentEditable';
import styled from '@emotion/styled';

export const ContentEditable = styled(LexicalContentEditable)`
  width: 100%;
  height: 200px;
  padding: ${({ theme }) => theme.space['2']};
  overflow: auto;
  font-size: 1em;
  resize: vertical;

  &:focus {
    outline: none;
  }

  .paragraph {
    margin: 0 0 ${({ theme }) => theme.space['1']} 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .bold {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  .italic {
    font-style: italic;
  }

  .underline {
    text-decoration: underline;
  }
`;
