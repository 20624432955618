import { FC, useEffect } from 'react';
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_EDITOR, FORMAT_TEXT_COMMAND } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { ContentEditable } from './components';

type FormattedTextPluginProps = {
  singleFormat?: boolean;
};

export const FormattedTextPlugin: FC<FormattedTextPluginProps> = ({ singleFormat }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const removeFormatTextCommandHandler = editor.registerCommand(
      FORMAT_TEXT_COMMAND,
      (format) => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) {
          return false;
        }

        if (singleFormat) {
          const hasFormat = selection.hasFormat(format);
          // remove all formats
          selection.hasFormat('bold') && selection.formatText('bold');
          selection.hasFormat('italic') && selection.formatText('italic');
          selection.hasFormat('underline') && selection.formatText('underline');
          if (!hasFormat) {
            selection.formatText(format);
          }
        } else {
          selection.formatText(format);
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
    return () => {
      removeFormatTextCommandHandler();
    };
  }, [editor, singleFormat]);

  return (
    <PlainTextPlugin contentEditable={<ContentEditable />} ErrorBoundary={LexicalErrorBoundary} placeholder={null} />
  );
};
