import { Button, ButtonProps } from '@chakra-ui/react';
import { FC } from 'react';

export type ToolbarButtonProps = ButtonProps & {
  isActive?: boolean;
};

export const ToolbarButton: FC<ToolbarButtonProps> = ({ isActive, ...rest }) => {
  return (
    <Button
      type="button"
      colorScheme={isActive ? 'primary' : 'gray'}
      minWidth={'38px'}
      size="sm"
      fontSize="inherit"
      tabIndex={-1}
      {...rest}
    />
  );
};
