import { Link } from 'react-router-dom';
import { chakra, Flex, Image } from '@chakra-ui/react';
import { SelectableCard } from '@netiva/classifieds-ui';

export const AdCard = chakra(SelectableCard, {
  baseStyle: {
    minHeight: 32,
    minWidth: 40,
    padding: 0,
    position: 'relative',
    textAlign: 'center',
    shadow: 'around',
  },
});

export const AdCardLink = chakra(Link, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 0,
    textDecoration: 'none',
    width: 'full',
    height: 'full',
  },
});

export const AdCardImage = chakra(Image, {
  baseStyle: {
    borderRadius: 'default',
    borderBottomRadius: 0,
    borderBottom: `default`,
    height: '150px',
    width: '100%',
    objectFit: 'cover',
    margin: 0,
    marginBottom: 2,
  },
});

export const AdCardContent = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: 'full',
    height: 'full',
    padding: 4,
  },
});
