import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertIcon, AlertTitle } from '@chakra-ui/react';

export const NewUserNotification: FC = () => {
  const { t } = useTranslation();
  return (
    <Alert status="info">
      <AlertIcon />
      <AlertTitle>{t('account.newUserNotification')}</AlertTitle>
    </Alert>
  );
};
