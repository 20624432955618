import { AdFile, AdFormData } from '@/store/ad/types';
import { AccountSharedDataObjectValueRec } from '@netiva/classifieds-api';

/** Converts ad form data to values request objects */
export const getFormDataValues = (formData: AdFormData): AccountSharedDataObjectValueRec[] => {
  const values = Object.entries(formData).reduce<AccountSharedDataObjectValueRec[]>((values, keyValuePair) => {
    const [key, value] = keyValuePair;
    return [
      ...values,
      {
        attributeId: parseInt(key),
        value: value.value,
        entries: value.entries,
      },
    ];
  }, []);

  return values;
};

export const getFilesRequestParams = (images: AdFile[], documents: AdFile[]) => {
  return images.concat(documents).map((file) => {
    if (file.uploaded) {
      return file.uploaded;
    }
    return { id: file.existing!.id, filename: '', contentType: '', base64Data: '' };
  });
};
