import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AccountModel } from '@netiva/classifieds-api';

import { RequiredAccountFields } from '@/config';
import { getSelectedAccountId, setSelectedAccountId } from '@/lib/storage';
import type { AccountFormData, AccountFormDataPayload, AccountState } from './types';

export const initialAccountState: AccountState = {
  accountId: getSelectedAccountId(),
  formData: {},
  isFormValid: true,
  isLoading: true,
  isNewUser: false,
  showAccountSelection: false,
};

const validateFormData = (formData: AccountFormData) => {
  return !RequiredAccountFields.some((prop) => !formData[prop]);
};

export const accountSlice = createSlice({
  name: 'account',
  initialState: initialAccountState,
  reducers: {
    setAccountId: (state, action: PayloadAction<number | undefined>) => {
      state.accountId = action.payload;
      setSelectedAccountId(action.payload);
    },
    setAccount: (state, action: PayloadAction<AccountModel | undefined>) => {
      state.accountId = action.payload?.accountId;
      setSelectedAccountId(action.payload?.accountId);
      state.accountAddress = action.payload?.address;
      if (typeof action.payload?.isNew !== 'undefined') {
        state.isNewUser = action.payload.isNew;
      }
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsNewUser: (state, action: PayloadAction<boolean>) => {
      state.isNewUser = action.payload;
    },
    setFormData: (state, action: PayloadAction<AccountFormData>) => {
      state.formData = action.payload;
      state.isFormValid = validateFormData(state.formData);
    },
    updateFormData: (state, action: PayloadAction<AccountFormDataPayload>) => {
      state.formData = { ...state.formData, [action.payload.key]: action.payload.value };
      state.isFormValid = validateFormData(state.formData);
    },
    showAccountSelection: (state) => {
      state.showAccountSelection = true;
    },
    hideAccountSelection: (state) => {
      state.showAccountSelection = false;
    },
  },
});

export default accountSlice.reducer;

export const accountActions = accountSlice.actions;
