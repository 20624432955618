import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Heading, Text, Tooltip } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import { DescriptionList, DescriptionListItem, DescriptionListTerm, Loader } from '@netiva/classifieds-ui';

import { useCurrencyFormatter, useDate } from '@/hooks';
import { useAppDispatch } from '@/store';
import { adActions } from '@/store/ad';
import { useAd, useAdPublishing } from '../../hooks';
import { CardSection, ProductCard } from './components';

export const Publishing: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currencyFormatter = useCurrencyFormatter();
  const dateFormatter = useDate();
  const {
    isLoading,
    activePublication,
    availableContracts,
    availableProducts,
    availableExtensionProducts,
    selectedContractId,
    selectedExtensionProductIds,
    selectedProductId,
  } = useAdPublishing();
  const { dataObject } = useAd();

  const handleContractClick = (id: number) => {
    dispatch(adActions.setSelectedContractId(id));
  };

  const handleMainProductClick = (id: number) => {
    dispatch(adActions.setSelectedProductId(id));
  };

  const handleExtensionProductClick = (id: number, group?: string | null) => {
    if (!selectedExtensionProductIds.includes(id)) {
      dispatch(adActions.selectExtensionProductId(id));

      // only one selection per group is permitted; deselect all products of the same group
      if (group) {
        availableExtensionProducts
          .filter((ext) => (ext.id !== id && ext.group) === group)
          .forEach((ext) => dispatch(adActions.deselectExtensionProductId(ext.id)));
      }
    } else {
      dispatch(adActions.deselectExtensionProductId(id));
    }
  };

  useEffect(() => {
    if (availableProducts.length === 1 && !selectedProductId) {
      dispatch(adActions.setSelectedProductId(availableProducts[0].id));
    }
  }, [availableContracts, availableProducts, dispatch, selectedProductId]);

  if (isLoading) {
    return <Loader isLoading showOverlay />;
  }

  return (
    <>
      {!!activePublication && (
        <>
          <Heading size="sm" my={4}>
            {t('ad.steps.publishing.currentPublication')}
          </Heading>
          <DescriptionList>
            <DescriptionListTerm>{t('ad.steps.publishing.product')}</DescriptionListTerm>
            <DescriptionListItem>
              <Flex align="center" gap={2}>
                {activePublication.contract.product.name} ({currencyFormatter.format(activePublication.contract.price)})
                {!!activePublication.contract.product.description && (
                  <Tooltip
                    label={
                      <Box
                        padding={6}
                        dangerouslySetInnerHTML={{ __html: activePublication.contract.product.description }}
                      />
                    }
                  >
                    <InfoIcon tabIndex={0} />
                  </Tooltip>
                )}
              </Flex>
            </DescriptionListItem>

            <DescriptionListTerm>{t('ad.steps.publishing.duration')}</DescriptionListTerm>
            <DescriptionListItem>
              {t('ad.steps.publishing.dateFrom')} {dateFormatter.formatDate(activePublication.startDate)}{' '}
              {activePublication.endDate
                ? t('ad.steps.publishing.dateTo') + ' ' + dateFormatter.formatDate(activePublication.endDate)
                : t('ad.steps.publishing.noEndDate')}
            </DescriptionListItem>

            <DescriptionListTerm>{t('ad.steps.publishing.status')}</DescriptionListTerm>
            <DescriptionListItem>{t(`ad.status.${dataObject?.status.toLowerCase()}`)}</DescriptionListItem>

            {activePublication.contract.activeExtensions.length ? (
              <>
                <DescriptionListTerm>{t('ad.steps.publishing.extensionProducts')}</DescriptionListTerm>
                {activePublication.contract.activeExtensions.map((ext) => (
                  <DescriptionListItem key={ext.id}>
                    {ext.product.name} ({currencyFormatter.format(ext.price)})
                  </DescriptionListItem>
                ))}
              </>
            ) : null}
          </DescriptionList>
        </>
      )}

      {!!availableContracts.length && (
        <CardSection>
          {availableContracts.map((contract) => {
            const isSelected = contract.id === selectedContractId;
            return (
              <ProductCard
                key={contract.id}
                title={contract.product.name}
                isSelected={isSelected}
                onClick={() => handleContractClick(contract.id)}
              >
                <Box dangerouslySetInnerHTML={{ __html: contract.product.description || '' }} mb={4} />
                {contract.available > 0 && (
                  <Text>
                    {contract.quantity - contract.available} / {contract.quantity}
                  </Text>
                )}
              </ProductCard>
            );
          })}
        </CardSection>
      )}

      {!!availableProducts.length && (
        <CardSection>
          {availableProducts.map((product) => {
            const isSelected = product.id === selectedProductId;
            return (
              <ProductCard
                key={product.id}
                title={product.name}
                subTitle={currencyFormatter.format(product.price)}
                isSelected={isSelected}
                onClick={() => handleMainProductClick(product.id)}
              >
                <Box dangerouslySetInnerHTML={{ __html: product.description || '' }} />
              </ProductCard>
            );
          })}
        </CardSection>
      )}

      {!!availableExtensionProducts.length && (
        <>
          <Heading size="sm" my={4}>
            {t('ad.steps.publishing.extensionProducts')}
          </Heading>
          <CardSection>
            {availableExtensionProducts.map((p) => {
              const isSelected = selectedExtensionProductIds.includes(p.id);
              return (
                <ProductCard
                  key={p.id}
                  title={p.name}
                  subTitle={currencyFormatter.format(p.price)}
                  isSelected={isSelected}
                  onClick={() => handleExtensionProductClick(p.id, p.group)}
                  width={{ base: 'full', lg: '30%' }}
                >
                  <Box fontSize="sm" dangerouslySetInnerHTML={{ __html: p.description || '' }} />
                </ProductCard>
              );
            })}
          </CardSection>
        </>
      )}
    </>
  );
};
