import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Flex, VStack } from '@chakra-ui/react';
import { Loader } from '@netiva/classifieds-ui';

import { AccountCountrySelect, AccountInput } from './components';
import { useAccountForm } from './useAccountForm';

export type AccountFormProps = {
  onCancel: () => void;
};

export const AccountForm: FC<AccountFormProps> = ({ onCancel }) => {
  const { t } = useTranslation();
  const { saveAccount, isLoading } = useAccountForm({ onCancel });

  return (
    <>
      <Loader isLoading={isLoading} size="lg" showOverlay />
      <VStack maxWidth="500px" spacing={3} align="stretch">
        <AccountInput name="firstname" label={t('account.form.firstName')} />
        <AccountInput name="lastname" label={t('account.form.lastName')} />
        <AccountInput name="company" label={t('account.form.company')} />
        <AccountInput name="company2" label={t('account.form.company2')} />
        <Flex gap={2}>
          <AccountInput name="street" label={t('account.form.street')} />
          <AccountInput name="streetNo" label={t('account.form.streetNo')} maxWidth="110px" />
        </Flex>
        <AccountInput name="street2" label={t('account.form.street2')} />
        <Flex gap={2}>
          <AccountInput name="zip" label={t('account.form.zipCode')} maxWidth="90px" />
          <AccountInput name="city" label={t('account.form.city')} />
        </Flex>
        <AccountInput name="email" label={t('account.form.email')} />
        <AccountInput name="phone" label={t('account.form.phone')} />
        <AccountCountrySelect name="countryIso2" label={t('account.form.country')} />

        <Divider my={4} />

        <Flex justify="space-between" align="center">
          <Button type="button" onClick={saveAccount}>
            {t('ui.buttons.save')}
          </Button>
          <Button type="button" onClick={onCancel} colorScheme="neutral">
            {t('ui.buttons.cancel')}
          </Button>
        </Flex>
      </VStack>
    </>
  );
};
