import React, { createContext, FC, FunctionComponent, PropsWithChildren, useContext, useRef, useState } from 'react';
import { Box, BoxProps, ChakraProps, Flex, forwardRef, Input, useMultiStyleConfig } from '@chakra-ui/react';

import { FileInputComponentName } from '../theme';

export type FileInputProps = PropsWithChildren<ChakraProps> & {
  accept?: string;
  onFilesSelected: (files: FileList) => void;
};

const FileInputContext = createContext({ onClick: () => {} });

export const useFileInput = () => {
  return useContext(FileInputContext);
};

const FileInput: FunctionComponent<FileInputProps> = ({ onFilesSelected, accept, children, ...rest }) => {
  const styles = useMultiStyleConfig(FileInputComponentName, { ...rest });
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  // using a counter prevents flickering when dragging over a child element
  const dragCounter = useRef(0);

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragging(true);
    dragCounter.current++;
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setIsDragging(false);
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = e.dataTransfer.types.includes('Files') ? 'copy' : 'none';
  };

  const handleDrop = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    dragCounter.current = 0;
    setIsDragging(false);
    if (e.dataTransfer?.files?.length) {
      onFilesSelected(e.dataTransfer.files);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      onFilesSelected(e.target.files);
      e.target.value = '';
    }
  };

  return (
    <FileInputContext.Provider value={{ onClick: handleClick }}>
      <Flex
        __css={styles.container}
        borderColor={isDragging ? 'secondary' : undefined}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        {...rest}
      >
        <Flex sx={styles.content}>{children}</Flex>
        <Input ref={inputRef} type="file" accept={accept} onChange={handleInputChange} display="none" />
      </Flex>
    </FileInputContext.Provider>
  );
};

export default FileInput;

export const FileInputClickBox: FC<BoxProps> = forwardRef((props, ref) => {
  const { onClick } = useContext(FileInputContext);
  return <Box ref={ref} {...props} onClick={onClick} />;
});
