import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CardProps, Flex, IconButton, Link, Text, Tooltip } from '@chakra-ui/react';
import { DeleteIcon, DownloadIcon, EditIcon } from '@chakra-ui/icons';
import { FileInputClickBox, SelectableCard } from '@netiva/classifieds-ui';

import { AdFile } from '@/store/ad/types';

export interface DocumentListItemProps extends CardProps {
  document: AdFile;
  onRemove: (e: React.MouseEvent) => void;
  onReplace: (e: React.MouseEvent) => void;
}

export const DocumentListItem: FC<DocumentListItemProps> = ({ document, onRemove, onReplace, ...rest }) => {
  const { t } = useTranslation();

  return (
    <SelectableCard
      padding={0}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      shadow="around"
      cursor="default"
      _hover={{ bgColor: 'surface', shadow: 'heavy' }}
      sx={{}}
      {...rest}
    >
      <Text padding={4}>{document.name}</Text>
      <Flex justify="center" borderBottomRadius="default" bgColor="surface">
        {!!document.existing && (
          <Tooltip label={t('ad.steps.files.documents.download')}>
            <Link href={document.existing.url} target="_blank">
              <IconButton
                icon={<DownloadIcon />}
                variant="unstyled"
                aria-label={t('ad.steps.files.documents.download')}
              />
            </Link>
          </Tooltip>
        )}
        <Tooltip label={t('ad.steps.files.documents.replace')}>
          <FileInputClickBox>
            <IconButton
              icon={<EditIcon />}
              variant="unstyled"
              onClick={onReplace}
              aria-label={t('ad.steps.files.documents.replace')}
            />
          </FileInputClickBox>
        </Tooltip>
        <Tooltip label={t('ad.steps.files.documents.remove')}>
          <IconButton
            icon={<DeleteIcon />}
            variant="unstyled"
            onClick={onRemove}
            aria-label={t('ad.steps.files.documents.remove')}
          />
        </Tooltip>
      </Flex>
    </SelectableCard>
  );
};
