import { theme as chakraTheme, extendTheme, ThemeOverride, withDefaultColorScheme } from '@chakra-ui/react';

import {
  DescriptionListComponentName,
  DescriptionListItemComponentName,
  DescriptionListItemStyle,
  DescriptionListStyle,
  DescriptionListTermComponentName,
  DescriptionListTermStyle,
  FileInputComponentName,
  FileInputStyle,
  PaginationComponentName,
  PaginationStyle,
  SelectableCardComponentName,
  SelectableCardStyle,
  SelectableTagComponentName,
  SelectableTagStyle,
  TypeaheadComponentName,
  TypeaheadStyle,
} from './components';

export * from './components';
export * from './resetChakraGlobalsTheme';
export * from './types';

// redefine grays since the default grays from chakra have a blueish hue
const gray = {
  '50': '#f8f8f8',
  '100': '#f0f0f0',
  '200': '#e4e4e4',
  '300': '#d1d1d1',
  '400': '#b4b4b4',
  '500': '#9a9a9a',
  '600': '#878787',
  '700': '#6a6a6a',
  '800': '#5a5a5a',
  '900': '#4e4e4e',
};

const darkGray = {
  '50': '#f7f7f7',
  '100': '#e3e3e3',
  '200': '#c8c8c8',
  '300': '#a4a4a4',
  '400': '#818181',
  '500': '#666666',
  '600': '#515151',
  '700': '#434343',
  '800': '#383838',
  '900': '#0f0f0f',
};

const uiTheme: ThemeOverride = {
  colors: {
    primary: chakraTheme.colors.blue,
    secondary: chakraTheme.colors.cyan,
    gray: gray,
    neutral: darkGray,
  },
  semanticTokens: {
    colors: {
      primary: 'primary.main',
      secondary: 'secondary.main',
      dark: 'neutral.900',
      surface: 'gray.100',
      surfaceHover: 'gray.200',
    },
    borders: {
      default: `1px solid ${gray['200']}`,
    },
    shadows: {
      default: '0 1px 4px rgba(0, 0, 0, .15)',
      heavy: '0px 3px 6px rgba(0, 0, 0, .25)',
      around: '0 0 4px rgba(0, 0, 0, .2)',
    },
    sizes: {
      'layout.sm': '480px',
      'layout.md': '768px',
      'layout.lg': '1100px',
      'layout.xl': '1400px',
      'layout.2xl': '1500px',
    },
    radii: {
      default: 'md',
    },
  },
  components: {
    [DescriptionListComponentName]: DescriptionListStyle,
    [DescriptionListItemComponentName]: DescriptionListItemStyle,
    [DescriptionListTermComponentName]: DescriptionListTermStyle,
    [FileInputComponentName]: FileInputStyle,
    [PaginationComponentName]: PaginationStyle,
    [SelectableCardComponentName]: SelectableCardStyle,
    [SelectableTagComponentName]: SelectableTagStyle,
    [TypeaheadComponentName]: TypeaheadStyle,
  },
};

const theme = extendTheme(uiTheme, withDefaultColorScheme({ colorScheme: 'primary' }));

export default theme;
