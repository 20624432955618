import { FunctionComponent } from 'react';
import { AccountPage, AdPage, LoginPage, LogoutPage, MyAdsPage } from './pages';
import { AccountFormData } from './store/account/types';

export type Page = {
  id: string;
  component: FunctionComponent;
  exact?: boolean;
  title?: string;
  name: string;
  path: string;
  restricted: boolean;
  requiresAccount: boolean;
};

type Pages = Array<Page>;

export const paths = {
  ACCOUNT: '/',
  AD: '/ad/:id/:step',
  LOGIN: '/login',
  LOGOUT: '/logout',
  MYADS: '/ads',
  NEWAD: '/ad',
};

export const pages: Pages = [
  {
    id: '2',
    name: 'ad',
    title: 'ad.edit.title',
    component: AdPage,
    path: paths.AD,
    restricted: true,
    requiresAccount: true,
  },
  {
    id: '3',
    name: 'newAd',
    title: 'ad.create.title',
    component: AdPage,
    path: paths.NEWAD,
    restricted: true,
    requiresAccount: true,
  },
  {
    id: '4',
    name: 'myads',
    title: 'myads.title',
    component: MyAdsPage,
    path: paths.MYADS,
    restricted: true,
    requiresAccount: true,
  },
  {
    id: '5',
    name: 'account',
    title: 'account.title',
    component: AccountPage,
    path: paths.ACCOUNT,
    restricted: true,
    requiresAccount: false,
  },
  {
    id: '100',
    name: 'login',
    title: 'login.title',
    component: LoginPage,
    path: paths.LOGIN,
    restricted: false,
    requiresAccount: false,
  },
  {
    id: '101',
    name: 'logout',
    title: 'logout.title',
    component: LogoutPage,
    path: paths.LOGOUT,
    restricted: false,
    requiresAccount: false,
  },
];

export const RequiredAccountFields: (keyof AccountFormData)[] = [
  'firstname',
  'lastname',
  'street',
  'zip',
  'city',
  'email',
  'countryIso2',
];

export const SupportedCountries = ['ch', 'de', 'at', 'li', 'fr', 'it'];
