import { FC } from 'react';
import { chakra, HTMLChakraProps, ThemingProps, useStyleConfig } from '@chakra-ui/react';
import { DescriptionListComponentName } from '../../theme';

export type DescriptionListProps = HTMLChakraProps<'dl'> & ThemingProps<'DescriptionList'>;

export const DescriptionList: FC<DescriptionListProps> = (props) => {
  const style = useStyleConfig(DescriptionListComponentName, props);
  return <chakra.dl __css={style} {...props} />;
};
