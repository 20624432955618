import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@chakra-ui/react';

import { useAccounts } from '@/hooks';
import { useAppDispatch } from '@/store';
import { accountActions } from '@/store/account';

export const AccountSelectionButton: FC<ButtonProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accounts } = useAccounts();

  const handleClick = () => {
    dispatch(accountActions.showAccountSelection());
  };

  if (accounts.length < 2) {
    return null;
  }

  return (
    <Button onClick={handleClick} {...props}>
      {t('accounts.selectionButton')}
    </Button>
  );
};
