import { emptyApi as api } from './emptyApi';
export const addTagTypes = [
  'Account/Account',
  'Account/Address',
  'Attribute',
  'Account/Attribute',
  'Category',
  'Account/Category',
  'Account/Configuration',
  'Account/Contracts',
  'DataObject',
  'Account/DataObject',
  'Favorite',
  'File',
  'Geo',
  'Account/Logins',
  'Account/Logo',
  'PageContent',
  'Account/PrintAdvert',
  'Account/Products',
  'Account/Publication',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      postV1AccountAccount: build.mutation<PostV1AccountAccountResponse, PostV1AccountAccountParams>({
        query: (queryArg) => ({
          url: `/v1/Account/Account`,
          method: 'POST',
          body: queryArg.accountAccountCreateAccountRequest,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Account/Account'],
      }),
      getV1AccountAccount: build.query<GetV1AccountAccountResponse, GetV1AccountAccountParams>({
        query: (queryArg) => ({
          url: `/v1/Account/Account`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        providesTags: ['Account/Account'],
      }),
      getV1AccountAccountById: build.query<GetV1AccountAccountByIdResponse, GetV1AccountAccountByIdParams>({
        query: (queryArg) => ({
          url: `/v1/Account/Account/${queryArg.id}`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        providesTags: ['Account/Account'],
      }),
      putV1AccountAccountById: build.mutation<PutV1AccountAccountByIdResponse, PutV1AccountAccountByIdParams>({
        query: (queryArg) => ({
          url: `/v1/Account/Account/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.accountAccountUpdateAccountRequest,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Account/Account'],
      }),
      getV1AccountAccountLoginInfo: build.query<
        GetV1AccountAccountLoginInfoResponse,
        GetV1AccountAccountLoginInfoParams
      >({
        query: (queryArg) => ({
          url: `/v1/Account/Account/LoginInfo`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        providesTags: ['Account/Account'],
      }),
      getV1AccountAddress: build.query<GetV1AccountAddressResponse, GetV1AccountAddressParams>({
        query: (queryArg) => ({
          url: `/v1/Account/Address`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: { typeIds: queryArg.typeIds },
        }),
        providesTags: ['Account/Address'],
      }),
      postV1AccountAddress: build.mutation<PostV1AccountAddressResponse, PostV1AccountAddressParams>({
        query: (queryArg) => ({
          url: `/v1/Account/Address`,
          method: 'POST',
          body: queryArg.accountAddressesCreateAddressRequest,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Account/Address'],
      }),
      deleteV1AccountAddressById: build.mutation<DeleteV1AccountAddressByIdResponse, DeleteV1AccountAddressByIdParams>({
        query: (queryArg) => ({
          url: `/v1/Account/Address/${queryArg.id}`,
          method: 'DELETE',
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Account/Address'],
      }),
      putV1AccountAddressById: build.mutation<PutV1AccountAddressByIdResponse, PutV1AccountAddressByIdParams>({
        query: (queryArg) => ({
          url: `/v1/Account/Address/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.accountAddressesUpdateAddressRequest,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Account/Address'],
      }),
      getV1Attribute: build.query<GetV1AttributeResponse, GetV1AttributeParams>({
        query: (queryArg) => ({
          url: `/v1/Attribute`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: {
            categoryId: queryArg.categoryId,
            objectTypeId: queryArg.objectTypeId,
            includeDisabled: queryArg.includeDisabled,
            onlySearchable: queryArg.onlySearchable,
          },
        }),
        providesTags: ['Attribute'],
      }),
      getV1AccountAttribute: build.query<GetV1AccountAttributeResponse, GetV1AccountAttributeParams>({
        query: (queryArg) => ({
          url: `/v1/Account/Attribute`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: { categoryId: queryArg.categoryId, onlyEditable: queryArg.onlyEditable },
        }),
        providesTags: ['Account/Attribute'],
      }),
      getV1Category: build.query<GetV1CategoryResponse, GetV1CategoryParams>({
        query: (queryArg) => ({
          url: `/v1/Category`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: { parentId: queryArg.parentId, recursive: queryArg.recursive },
        }),
        providesTags: ['Category'],
      }),
      getV1AccountCategory: build.query<GetV1AccountCategoryResponse, GetV1AccountCategoryParams>({
        query: (queryArg) => ({
          url: `/v1/Account/Category`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: { parentId: queryArg.parentId, recursive: queryArg.recursive },
        }),
        providesTags: ['Account/Category'],
      }),
      getV1AccountConfigurationDataObjectTypes: build.query<
        GetV1AccountConfigurationDataObjectTypesResponse,
        GetV1AccountConfigurationDataObjectTypesParams
      >({
        query: (queryArg) => ({
          url: `/v1/Account/Configuration/DataObjectTypes`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        providesTags: ['Account/Configuration'],
      }),
      getV1AccountConfigurationPlatforms: build.query<
        GetV1AccountConfigurationPlatformsResponse,
        GetV1AccountConfigurationPlatformsParams
      >({
        query: (queryArg) => ({
          url: `/v1/Account/Configuration/Platforms`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        providesTags: ['Account/Configuration'],
      }),
      getV1AccountConfigurationProcess: build.query<
        GetV1AccountConfigurationProcessResponse,
        GetV1AccountConfigurationProcessParams
      >({
        query: (queryArg) => ({
          url: `/v1/Account/Configuration/Process`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: { categoryId: queryArg.categoryId, dataObjectType: queryArg.dataObjectType },
        }),
        providesTags: ['Account/Configuration'],
      }),
      getV1AccountContracts: build.query<GetV1AccountContractsResponse, GetV1AccountContractsParams>({
        query: (queryArg) => ({
          url: `/v1/Account/Contracts`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: { dataObjectType: queryArg.dataObjectType, includeExpired: queryArg.includeExpired },
        }),
        providesTags: ['Account/Contracts'],
      }),
      getV1DataObjectById: build.query<GetV1DataObjectByIdResponse, GetV1DataObjectByIdParams>({
        query: (queryArg) => ({
          url: `/v1/DataObject/${queryArg.id}`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        providesTags: ['DataObject'],
      }),
      getV1DataObject: build.query<GetV1DataObjectResponse, GetV1DataObjectParams>({
        query: (queryArg) => ({
          url: `/v1/DataObject`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: {
            query: queryArg.query,
            categories: queryArg.categories,
            attributeEntries: queryArg.attributeEntries,
            regions: queryArg.regions,
            accountId: queryArg.accountId,
            objectIds: queryArg.objectIds,
            latitude: queryArg.latitude,
            longitude: queryArg.longitude,
            locationRadius: queryArg.locationRadius,
            location: queryArg.location,
            locationIds: queryArg.locationIds,
            calculateAggregates: queryArg.calculateAggregates,
            dateFrom: queryArg.dateFrom,
            dateTo: queryArg.dateTo,
            timestampFrom: queryArg.timestampFrom,
            timestampTo: queryArg.timestampTo,
            pageIndex: queryArg.pageIndex,
            pageSize: queryArg.pageSize,
            sortField: queryArg.sortField,
            sortOrder: queryArg.sortOrder,
            attributeKeys: queryArg.attributeKeys,
          },
        }),
        providesTags: ['DataObject'],
      }),
      postV1DataObjectByIdContact: build.mutation<
        PostV1DataObjectByIdContactResponse,
        PostV1DataObjectByIdContactParams
      >({
        query: (queryArg) => ({
          url: `/v1/DataObject/${queryArg.id}/Contact`,
          method: 'POST',
          body: queryArg.publicDataObjectContactRequest,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['DataObject'],
      }),
      putV1AccountDataObjectByIdActivate: build.mutation<
        PutV1AccountDataObjectByIdActivateResponse,
        PutV1AccountDataObjectByIdActivateParams
      >({
        query: (queryArg) => ({
          url: `/v1/Account/DataObject/${queryArg.id}/Activate`,
          method: 'PUT',
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Account/DataObject'],
      }),
      postV1AccountDataObject: build.mutation<PostV1AccountDataObjectResponse, PostV1AccountDataObjectParams>({
        query: (queryArg) => ({
          url: `/v1/Account/DataObject`,
          method: 'POST',
          body: queryArg.accountDataObjectCreateDataObjectRequest,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Account/DataObject'],
      }),
      getV1AccountDataObject: build.query<GetV1AccountDataObjectResponse, GetV1AccountDataObjectParams>({
        query: (queryArg) => ({
          url: `/v1/Account/DataObject`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: {
            platform: queryArg.platform,
            status: queryArg.status,
            filter: queryArg.filter,
            pageIndex: queryArg.pageIndex,
            pageSize: queryArg.pageSize,
          },
        }),
        providesTags: ['Account/DataObject'],
      }),
      putV1AccountDataObjectByIdDeactivate: build.mutation<
        PutV1AccountDataObjectByIdDeactivateResponse,
        PutV1AccountDataObjectByIdDeactivateParams
      >({
        query: (queryArg) => ({
          url: `/v1/Account/DataObject/${queryArg.id}/Deactivate`,
          method: 'PUT',
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Account/DataObject'],
      }),
      deleteV1AccountDataObjectById: build.mutation<
        DeleteV1AccountDataObjectByIdResponse,
        DeleteV1AccountDataObjectByIdParams
      >({
        query: (queryArg) => ({
          url: `/v1/Account/DataObject/${queryArg.id}`,
          method: 'DELETE',
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Account/DataObject'],
      }),
      getV1AccountDataObjectById: build.query<GetV1AccountDataObjectByIdResponse, GetV1AccountDataObjectByIdParams>({
        query: (queryArg) => ({
          url: `/v1/Account/DataObject/${queryArg.id}`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        providesTags: ['Account/DataObject'],
      }),
      putV1AccountDataObjectById: build.mutation<PutV1AccountDataObjectByIdResponse, PutV1AccountDataObjectByIdParams>({
        query: (queryArg) => ({
          url: `/v1/Account/DataObject/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.accountDataObjectUpdateDataObjectRequest,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Account/DataObject'],
      }),
      postV1AccountDataObjectByIdDuplicate: build.mutation<
        PostV1AccountDataObjectByIdDuplicateResponse,
        PostV1AccountDataObjectByIdDuplicateParams
      >({
        query: (queryArg) => ({
          url: `/v1/Account/DataObject/${queryArg.id}/Duplicate`,
          method: 'POST',
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Account/DataObject'],
      }),
      getV1Favorite: build.query<GetV1FavoriteResponse, GetV1FavoriteParams>({
        query: (queryArg) => ({
          url: `/v1/Favorite`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        providesTags: ['Favorite'],
      }),
      deleteV1Favorite: build.mutation<DeleteV1FavoriteResponse, DeleteV1FavoriteParams>({
        query: (queryArg) => ({
          url: `/v1/Favorite`,
          method: 'DELETE',
          body: queryArg.publicFavoritesRemoveFavoritesRequest,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Favorite'],
      }),
      postV1Favorite: build.mutation<PostV1FavoriteResponse, PostV1FavoriteParams>({
        query: (queryArg) => ({
          url: `/v1/Favorite`,
          method: 'POST',
          body: queryArg.publicFavoritesSaveFavoritesRequest,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Favorite'],
      }),
      getV1FileById: build.query<GetV1FileByIdResponse, GetV1FileByIdParams>({
        query: (queryArg) => ({
          url: `/v1/File/${queryArg.id}`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: { width: queryArg.width, height: queryArg.height },
        }),
        providesTags: ['File'],
      }),
      getV1GeoRegions: build.query<GetV1GeoRegionsResponse, GetV1GeoRegionsParams>({
        query: (queryArg) => ({
          url: `/v1/Geo/Regions`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: { filter: queryArg.filter },
        }),
        providesTags: ['Geo'],
      }),
      getV1GeoLocations: build.query<GetV1GeoLocationsResponse, GetV1GeoLocationsParams>({
        query: (queryArg) => ({
          url: `/v1/Geo/Locations`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: { filter: queryArg.filter, maxCount: queryArg.maxCount, distinctByName: queryArg.distinctByName },
        }),
        providesTags: ['Geo'],
      }),
      putV1AccountLoginsAcceptInvitation: build.mutation<
        PutV1AccountLoginsAcceptInvitationResponse,
        PutV1AccountLoginsAcceptInvitationParams
      >({
        query: (queryArg) => ({
          url: `/v1/Account/Logins/AcceptInvitation`,
          method: 'PUT',
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: { accountId: queryArg.accountId },
        }),
        invalidatesTags: ['Account/Logins'],
      }),
      postV1AccountLogins: build.mutation<PostV1AccountLoginsResponse, PostV1AccountLoginsParams>({
        query: (queryArg) => ({
          url: `/v1/Account/Logins`,
          method: 'POST',
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: { email: queryArg.email, role: queryArg.role },
        }),
        invalidatesTags: ['Account/Logins'],
      }),
      getV1AccountLogins: build.query<GetV1AccountLoginsResponse, GetV1AccountLoginsParams>({
        query: (queryArg) => ({
          url: `/v1/Account/Logins`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: { filter: queryArg.filter, pageIndex: queryArg.pageIndex, pageSize: queryArg.pageSize },
        }),
        providesTags: ['Account/Logins'],
      }),
      getV1AccountLoginsInvitations: build.query<
        GetV1AccountLoginsInvitationsResponse,
        GetV1AccountLoginsInvitationsParams
      >({
        query: (queryArg) => ({
          url: `/v1/Account/Logins/Invitations`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        providesTags: ['Account/Logins'],
      }),
      deleteV1AccountLoginsById: build.mutation<DeleteV1AccountLoginsByIdResponse, DeleteV1AccountLoginsByIdParams>({
        query: (queryArg) => ({
          url: `/v1/Account/Logins/${queryArg.id}`,
          method: 'DELETE',
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Account/Logins'],
      }),
      putV1AccountLoginsSetRole: build.mutation<PutV1AccountLoginsSetRoleResponse, PutV1AccountLoginsSetRoleParams>({
        query: (queryArg) => ({
          url: `/v1/Account/Logins/SetRole`,
          method: 'PUT',
          body: queryArg.accountLoginsSetLoginRoleRequest,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Account/Logins'],
      }),
      deleteV1AccountLogo: build.mutation<DeleteV1AccountLogoResponse, DeleteV1AccountLogoParams>({
        query: (queryArg) => ({
          url: `/v1/Account/Logo`,
          method: 'DELETE',
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Account/Logo'],
      }),
      postV1AccountLogo: build.mutation<PostV1AccountLogoResponse, PostV1AccountLogoParams>({
        query: (queryArg) => ({
          url: `/v1/Account/Logo`,
          method: 'POST',
          body: queryArg.body,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Account/Logo'],
      }),
      getV1AccountLogoByAccountId: build.query<GetV1AccountLogoByAccountIdResponse, GetV1AccountLogoByAccountIdParams>({
        query: (queryArg) => ({
          url: `/v1/Account/Logo/${queryArg.accountId}`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        providesTags: ['Account/Logo'],
      }),
      getV1PageContent: build.query<GetV1PageContentResponse, GetV1PageContentParams>({
        query: (queryArg) => ({
          url: `/v1/PageContent`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: { url: queryArg.url },
        }),
        providesTags: ['PageContent'],
      }),
      postV1AccountPrintAdvert: build.mutation<PostV1AccountPrintAdvertResponse, PostV1AccountPrintAdvertParams>({
        query: (queryArg) => ({
          url: `/v1/Account/PrintAdvert`,
          method: 'POST',
          body: queryArg.accountPrintAdvertCreatePrintAdvertRequest,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Account/PrintAdvert'],
      }),
      getV1AccountPrintAdvertIssueDates: build.query<
        GetV1AccountPrintAdvertIssueDatesResponse,
        GetV1AccountPrintAdvertIssueDatesParams
      >({
        query: (queryArg) => ({
          url: `/v1/Account/PrintAdvert/IssueDates`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: { dataObjectId: queryArg.dataObjectId },
        }),
        providesTags: ['Account/PrintAdvert'],
      }),
      getV1AccountPrintAdvertById: build.query<GetV1AccountPrintAdvertByIdResponse, GetV1AccountPrintAdvertByIdParams>({
        query: (queryArg) => ({
          url: `/v1/Account/PrintAdvert/${queryArg.id}`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: { platform: queryArg.platform },
        }),
        providesTags: ['Account/PrintAdvert'],
      }),
      putV1AccountPrintAdvertById: build.mutation<
        PutV1AccountPrintAdvertByIdResponse,
        PutV1AccountPrintAdvertByIdParams
      >({
        query: (queryArg) => ({
          url: `/v1/Account/PrintAdvert/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.accountPrintAdvertUpdatePrintAdvertRequest,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Account/PrintAdvert'],
      }),
      postV1AccountPrintAdvertPreview: build.mutation<
        PostV1AccountPrintAdvertPreviewResponse,
        PostV1AccountPrintAdvertPreviewParams
      >({
        query: (queryArg) => ({
          url: `/v1/Account/PrintAdvert/Preview`,
          method: 'POST',
          body: queryArg.accountPrintAdvertPreviewPrintAdvertRequest,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Account/PrintAdvert'],
      }),
      getV1AccountProducts: build.query<GetV1AccountProductsResponse, GetV1AccountProductsParams>({
        query: (queryArg) => ({
          url: `/v1/Account/Products`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: { dataObjectType: queryArg.dataObjectType, includeDisabled: queryArg.includeDisabled },
        }),
        providesTags: ['Account/Products'],
      }),
      getV1AccountPublicationOptions: build.query<
        GetV1AccountPublicationOptionsResponse,
        GetV1AccountPublicationOptionsParams
      >({
        query: (queryArg) => ({
          url: `/v1/Account/Publication/Options`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: {
            dataObjectId: queryArg.dataObjectId,
            categoryId: queryArg.categoryId,
            dataObjectType: queryArg.dataObjectType,
          },
        }),
        providesTags: ['Account/Publication'],
      }),
      getV1AccountPublicationDataObjectById: build.query<
        GetV1AccountPublicationDataObjectByIdResponse,
        GetV1AccountPublicationDataObjectByIdParams
      >({
        query: (queryArg) => ({
          url: `/v1/Account/Publication/DataObject/${queryArg.id}`,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
          params: { statuses: queryArg.statuses },
        }),
        providesTags: ['Account/Publication'],
      }),
      postV1AccountPublicationDataObjectById: build.mutation<
        PostV1AccountPublicationDataObjectByIdResponse,
        PostV1AccountPublicationDataObjectByIdParams
      >({
        query: (queryArg) => ({
          url: `/v1/Account/Publication/DataObject/${queryArg.id}`,
          method: 'POST',
          body: queryArg.accountPublicationPublishDataObjectRequest,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-AccountId': queryArg['X-AccountId'],
            'X-Platform': queryArg['X-Platform'],
          },
        }),
        invalidatesTags: ['Account/Publication'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as api };
export type PostV1AccountAccountResponse = /** status 200 Success */ AccountAccountCreateAccountResponse;
export type PostV1AccountAccountParams = {
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
  accountAccountCreateAccountRequest: AccountAccountCreateAccountRequest;
};
export type GetV1AccountAccountResponse = /** status 200 Success */ AccountAccountListAccountsResponse;
export type GetV1AccountAccountParams = {
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1AccountAccountByIdResponse = /** status 200 Success */ AccountAccountLoadAccountResponse;
export type GetV1AccountAccountByIdParams = {
  id: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type PutV1AccountAccountByIdResponse = /** status 200 Success */ AccountAccountUpdateAccountResponse;
export type PutV1AccountAccountByIdParams = {
  id: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
  accountAccountUpdateAccountRequest: AccountAccountUpdateAccountRequest;
};
export type GetV1AccountAccountLoginInfoResponse = /** status 200 Success */ AccountAccountLoadLoginInfoResponse;
export type GetV1AccountAccountLoginInfoParams = {
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1AccountAddressResponse = /** status 200 Success */ AccountAddressesListAddressesResponse;
export type GetV1AccountAddressParams = {
  /** The requested address types Netiva.Classifieds.Address.AddressType */
  typeIds?: AddressType[];
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type PostV1AccountAddressResponse = /** status 200 Success */ AccountAddressesCreateAddressResponse;
export type PostV1AccountAddressParams = {
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
  accountAddressesCreateAddressRequest: AccountAddressesCreateAddressRequest;
};
export type DeleteV1AccountAddressByIdResponse = unknown;
export type DeleteV1AccountAddressByIdParams = {
  id: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type PutV1AccountAddressByIdResponse = /** status 200 Success */ AccountAddressesUpdateAddressResponse;
export type PutV1AccountAddressByIdParams = {
  id: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
  accountAddressesUpdateAddressRequest: AccountAddressesUpdateAddressRequest;
};
export type GetV1AttributeResponse = /** status 200 Success */ PublicAttributeListResponse;
export type GetV1AttributeParams = {
  /** Optional filter for a specific category ID. */
  categoryId?: number;
  /** Optional filter for a specific object type. */
  objectTypeId?: number;
  /** Specifies whether to include disabled attributes (defaults to false). */
  includeDisabled?: boolean;
  /** Specifies whether to only return attributes marked as searchable (defaults to false). */
  onlySearchable?: boolean;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1AccountAttributeResponse = /** status 200 Success */ AccountAttributeAttributeListResponse;
export type GetV1AccountAttributeParams = {
  categoryId?: number;
  onlyEditable?: boolean;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1CategoryResponse = /** status 200 Success */ PublicCategoryListResponse;
export type GetV1CategoryParams = {
  /** When specified only returns children of the specified category, otherwise the root category ID of the current platform is used. */
  parentId?: number;
  /** Specifies whether to recursively populate the children (defaults to false). */
  recursive?: boolean;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1AccountCategoryResponse = /** status 200 Success */ AccountCategoryCategoryListResponse;
export type GetV1AccountCategoryParams = {
  parentId?: number;
  recursive?: boolean;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1AccountConfigurationDataObjectTypesResponse =
  /** status 200 Success */ AccountConfigurationDataObjectTypeListResponse;
export type GetV1AccountConfigurationDataObjectTypesParams = {
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1AccountConfigurationPlatformsResponse =
  /** status 200 Success */ AccountConfigurationPlatformListResponse;
export type GetV1AccountConfigurationPlatformsParams = {
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1AccountConfigurationProcessResponse = /** status 200 Success */ AccountConfigurationGetProcessResponse;
export type GetV1AccountConfigurationProcessParams = {
  /** The category ID. */
  categoryId?: number;
  /** The data object type. (1=Standard, 4=Print) */
  dataObjectType?: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1AccountContractsResponse = /** status 200 Success */ AccountContractsListContractsResponse;
export type GetV1AccountContractsParams = {
  /** Filter for data object types. */
  dataObjectType?: number;
  /** When true includes expired contracts in the response. */
  includeExpired?: boolean;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1DataObjectByIdResponse = /** status 200 Success */ PublicDataObjectDetailResponse;
export type GetV1DataObjectByIdParams = {
  /** The data object ID. */
  id: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1DataObjectResponse = /** status 200 Success */ PublicDataObjectListResponse;
export type GetV1DataObjectParams = {
  /** A query string to search for. */
  query?: string;
  /** Filter for category IDs. */
  categories?: number[];
  /** Filter for attribute entry IDs. */
  attributeEntries?: number[];
  /** Filter for region IDs */
  regions?: number[];
  /** Filter for an account ID (list all ads owned by an account). */
  accountId?: number;
  /** Filter for getting specific objects (i.e. favorites). */
  objectIds?: number[];
  /** Filter for geo location (in combination with LocationRadius). */
  latitude?: number;
  /** Filter for geo location (in combination with LocationRadius). */
  longitude?: number;
  /** The radius in km to search for around Latitude and Longitude. */
  locationRadius?: number;
  /** A generic location search query. */
  location?: string;
  /** Filter for location IDs. */
  locationIds?: number[];
  /** Specifies whether to calculate aggregates (i.e. category & attribute counts). */
  calculateAggregates?: boolean;
  /** Filter range start for the date where an ad was posted. */
  dateFrom?: string;
  /** Filter range end for the date where an ad was posted. */
  dateTo?: string;
  /** Filter range start for the date where an ad was indexed. */
  timestampFrom?: string;
  /** Filter range start for the date where an ad was indexed. */
  timestampTo?: string;
  /** The page index (0-based). */
  pageIndex?: number;
  /** The number of items per page. */
  pageSize?: number;
  /** The field name to sort by. */
  sortField?: string;
  /** The order when sorting. */
  sortOrder?: SortOrder;
  /** A list of attribute keys to include in the result items. If this is omitted or false, no attribute values are returned. */
  attributeKeys?: string[];
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type PostV1DataObjectByIdContactResponse = /** status 200 Success */ undefined;
export type PostV1DataObjectByIdContactParams = {
  /** The data object ID. */
  id: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
  /** The request. */
  publicDataObjectContactRequest: PublicDataObjectContactRequest;
};
export type PutV1AccountDataObjectByIdActivateResponse = unknown;
export type PutV1AccountDataObjectByIdActivateParams = {
  id: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type PostV1AccountDataObjectResponse = /** status 200 Success */ AccountDataObjectCreateDataObjectResponse;
export type PostV1AccountDataObjectParams = {
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
  accountDataObjectCreateDataObjectRequest: AccountDataObjectCreateDataObjectRequest;
};
export type GetV1AccountDataObjectResponse = /** status 200 Success */ AccountDataObjectListDataObjectsResponse;
export type GetV1AccountDataObjectParams = {
  platform?: string;
  status?: DataObjectStatus;
  filter?: string;
  pageIndex?: number;
  pageSize?: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type PutV1AccountDataObjectByIdDeactivateResponse = unknown;
export type PutV1AccountDataObjectByIdDeactivateParams = {
  id: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type DeleteV1AccountDataObjectByIdResponse = unknown;
export type DeleteV1AccountDataObjectByIdParams = {
  id: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1AccountDataObjectByIdResponse = /** status 200 Success */ AccountDataObjectLoadDataObjectResponse;
export type GetV1AccountDataObjectByIdParams = {
  id: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type PutV1AccountDataObjectByIdResponse = /** status 200 Success */ AccountDataObjectUpdateDataObjectResponse;
export type PutV1AccountDataObjectByIdParams = {
  id: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
  accountDataObjectUpdateDataObjectRequest: AccountDataObjectUpdateDataObjectRequest;
};
export type PostV1AccountDataObjectByIdDuplicateResponse =
  /** status 200 Success */ AccountDataObjectDuplicateDataObjectResponse;
export type PostV1AccountDataObjectByIdDuplicateParams = {
  id: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1FavoriteResponse = /** status 200 Success */ PublicFavoritesFavoriteListResponse;
export type GetV1FavoriteParams = {
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type DeleteV1FavoriteResponse = /** status 200 Success */ PublicFavoritesRemoveFavoritesResponse;
export type DeleteV1FavoriteParams = {
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
  publicFavoritesRemoveFavoritesRequest: PublicFavoritesRemoveFavoritesRequest;
};
export type PostV1FavoriteResponse = /** status 200 Success */ PublicFavoritesSaveFavoritesResponse;
export type PostV1FavoriteParams = {
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
  publicFavoritesSaveFavoritesRequest: PublicFavoritesSaveFavoritesRequest;
};
export type GetV1FileByIdResponse = unknown;
export type GetV1FileByIdParams = {
  /** The ID of the file. */
  id: number;
  /** Optional width to resize an image to (only valid for image files). */
  width?: number;
  /** Optional height to resize an image to (only valid for image files). */
  height?: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1GeoRegionsResponse = /** status 200 Success */ PublicGeoRegionListResponse;
export type GetV1GeoRegionsParams = {
  /** Optional filter for region names. */
  filter?: string;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1GeoLocationsResponse = /** status 200 Success */ PublicGeoLocationListResponse;
export type GetV1GeoLocationsParams = {
  /** The filter to search for. */
  filter?: string;
  /** The maximum number of items returned (defaults to 10). */
  maxCount?: number;
  /** When true, only the first of multiple locations with the same name is returned (i.e. multiple ZIP codes per location). */
  distinctByName?: boolean;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type PutV1AccountLoginsAcceptInvitationResponse = unknown;
export type PutV1AccountLoginsAcceptInvitationParams = {
  /** The ID of the account to accept the invitation for. */
  accountId?: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type PostV1AccountLoginsResponse = unknown;
export type PostV1AccountLoginsParams = {
  /** The email address of the login to add. */
  email?: string;
  /** The role to assign to the login. */
  role?: AccountRole;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1AccountLoginsResponse =
  /** status 200 Success */ SharedListResponseAccountLoginsListLoginsResponseItem;
export type GetV1AccountLoginsParams = {
  filter?: string;
  pageIndex?: number;
  pageSize?: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1AccountLoginsInvitationsResponse = /** status 200 Success */ AccountLoginsListInvitationsResponse;
export type GetV1AccountLoginsInvitationsParams = {
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type DeleteV1AccountLoginsByIdResponse = unknown;
export type DeleteV1AccountLoginsByIdParams = {
  id: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type PutV1AccountLoginsSetRoleResponse = unknown;
export type PutV1AccountLoginsSetRoleParams = {
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
  accountLoginsSetLoginRoleRequest: AccountLoginsSetLoginRoleRequest;
};
export type DeleteV1AccountLogoResponse = unknown;
export type DeleteV1AccountLogoParams = {
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type PostV1AccountLogoResponse = /** status 200 Success */ AccountLogoSaveLogoResponse;
export type PostV1AccountLogoParams = {
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
  body: {
    file?: Blob;
  };
};
export type GetV1AccountLogoByAccountIdResponse = unknown;
export type GetV1AccountLogoByAccountIdParams = {
  accountId: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1PageContentResponse = /** status 200 Success */ MetadataPageContentGetResponse;
export type GetV1PageContentParams = {
  /** absolute url of the page that requests content. nullable to test how ssr behaves, might change to required later */
  url?: string;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type PostV1AccountPrintAdvertResponse = /** status 200 Success */ AccountPrintAdvertCreatePrintAdvertResponse;
export type PostV1AccountPrintAdvertParams = {
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
  accountPrintAdvertCreatePrintAdvertRequest: AccountPrintAdvertCreatePrintAdvertRequest;
};
export type GetV1AccountPrintAdvertIssueDatesResponse =
  /** status 200 Success */ AccountPrintAdvertLoadIssueDatesResponse;
export type GetV1AccountPrintAdvertIssueDatesParams = {
  dataObjectId?: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1AccountPrintAdvertByIdResponse = /** status 200 Success */ AccountPrintAdvertLoadPrintAdvertResponse;
export type GetV1AccountPrintAdvertByIdParams = {
  /** the dataObject Id of the advert */
  id: number;
  platform?: string;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type PutV1AccountPrintAdvertByIdResponse = /** status 200 Success */ AccountPrintAdvertUpdatePrintAdvertResponse;
export type PutV1AccountPrintAdvertByIdParams = {
  id: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
  accountPrintAdvertUpdatePrintAdvertRequest: AccountPrintAdvertUpdatePrintAdvertRequest;
};
export type PostV1AccountPrintAdvertPreviewResponse =
  /** status 200 Success */ AccountPrintAdvertPreviewPrintAdvertResponse;
export type PostV1AccountPrintAdvertPreviewParams = {
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
  accountPrintAdvertPreviewPrintAdvertRequest: AccountPrintAdvertPreviewPrintAdvertRequest;
};
export type GetV1AccountProductsResponse = /** status 200 Success */ AccountProductsListProductsResponse;
export type GetV1AccountProductsParams = {
  dataObjectType?: number;
  includeDisabled?: boolean;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1AccountPublicationOptionsResponse =
  /** status 200 Success */ AccountPublicationPublicationOptionListResponse;
export type GetV1AccountPublicationOptionsParams = {
  dataObjectId?: number;
  categoryId?: number;
  dataObjectType?: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type GetV1AccountPublicationDataObjectByIdResponse =
  /** status 200 Success */ AccountPublicationPublicationListResponse;
export type GetV1AccountPublicationDataObjectByIdParams = {
  id: number;
  statuses?: PublicationStatus[];
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
};
export type PostV1AccountPublicationDataObjectByIdResponse =
  /** status 200 Success */ AccountPublicationPublishDataObjectResponse;
export type PostV1AccountPublicationDataObjectByIdParams = {
  id: number;
  /** Sets the language code for the request (i.e. 'en', 'de'). */
  'Accept-Language'?: any;
  /** Sets the account ID to use for the current request. */
  'X-AccountId'?: any;
  /** Explicitly sets the platform name to use for the request. */
  'X-Platform'?: any;
  accountPublicationPublishDataObjectRequest: AccountPublicationPublishDataObjectRequest;
};
export type AccountGlobalsAccountType = 'Undefined' | 'Private' | 'Company';
export type AccountStatus = 'Default' | 'HasAddress' | 'Verified' | 'Locked';
export type GenderType = 'Unknown' | 'Male' | 'Female';
export type AddressRec = {
  gender: GenderType;
  firstname?: string | null;
  lastname?: string | null;
  company?: string | null;
  company2?: string | null;
  street: string;
  streetNo?: string | null;
  street2?: string | null;
  zip: string;
  city: string;
  email?: string | null;
  phone?: string | null;
  countryIso2: string;
};
export type AccountModel = {
  accountId: number;
  description: string;
  accountType: AccountGlobalsAccountType;
  accountStatus: AccountStatus;
  isNew: boolean;
  address?: AddressRec;
};
export type AccountAccountCreateAccountResponse = {
  account: AccountModel;
};
export type AccountAccountCreateAccountRequest = {
  address: AddressRec;
};
export type AccountAccountListAccountsResponseListItemModel = {
  id: number;
  name: string;
  type: AccountGlobalsAccountType;
  isNew: boolean;
};
export type AccountAccountListAccountsResponse = {
  accounts: AccountAccountListAccountsResponseListItemModel[];
};
export type AccountAccountLoadAccountResponse = {
  account: AccountModel;
};
export type AccountAccountUpdateAccountResponse = {
  account: AccountModel;
};
export type UpdateAccountModel = {
  accountId: number;
  address: AddressRec;
};
export type AccountAccountUpdateAccountRequest = {
  account: UpdateAccountModel;
};
export type AccountAccountLoadLoginInfoResponse = {
  id: number;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  streetAddress?: string | null;
  city?: string | null;
  zipCode?: string | null;
  countryCode?: string | null;
};
export type AddressType = 'Account' | 'Contact' | 'Billing' | 'Location';
export type AddressExtRec = {
  id: number;
  type: AddressType;
  data: AddressRec;
};
export type AccountAddressesListAddressesResponse = {
  addresses: AddressExtRec[];
};
export type AccountAddressesCreateAddressResponse = {
  address: AddressExtRec;
};
export type AccountAddressesCreateAddressRequest = {
  type: AddressType;
  address: AddressRec;
};
export type AccountAddressesUpdateAddressResponse = {
  address: AddressExtRec;
};
export type AccountAddressesUpdateAddressRequest = {
  address: AddressExtRec;
};
export type DataAttributeType =
  | 'Integer'
  | 'Decimal'
  | 'Date'
  | 'Text'
  | 'Boolean'
  | 'SelectSingle'
  | 'SelectSingleSearchMulti'
  | 'SelectMultiOr'
  | 'SelectMultiAnd'
  | 'SelectMultiSearchSingle'
  | 'Price'
  | 'VirtualGroup'
  | 'MultilineText'
  | 'HTML'
  | 'HTMLEInserat';
export type PublicAttributeListResponseAttributeEntry = {
  id: number;
  key?: string | null;
  name: string;
};
export type PublicAttributeListResponseAttribute = {
  id: number;
  key?: string | null;
  name: string;
  type: DataAttributeType;
  entries?: PublicAttributeListResponseAttributeEntry[] | null;
  searchable: boolean;
  hidden: boolean;
};
export type PublicAttributeListResponse = {
  attributes: PublicAttributeListResponseAttribute[];
};
export type AccountAttributeAttributeListResponseAttributeEntry = {
  id: number;
  key?: string | null;
  name?: string | null;
};
export type AccountAttributeAttributeListResponseAttributeCondition = {
  attributeKey: string;
  value: string;
};
export type AccountAttributeAttributeListResponseAttribute = {
  id: number;
  type: DataAttributeType;
  key?: string | null;
  name?: string | null;
  sortOrder: number;
  mandatory: boolean;
  editable: boolean;
  maxLength?: number | null;
  items: AccountAttributeAttributeListResponseAttributeEntry[];
  conditions: AccountAttributeAttributeListResponseAttributeCondition[];
};
export type AccountAttributeAttributeListResponse = {
  count: number;
  attributes: AccountAttributeAttributeListResponseAttribute[];
};
export type PublicCategoryListResponseCategory = {
  id: number;
  key?: string | null;
  name?: string | null;
  parentId?: number | null;
  isTop: boolean;
  children?: PublicCategoryListResponseCategory[] | null;
};
export type PublicCategoryListResponse = {
  categories: PublicCategoryListResponseCategory[];
};
export type AccountCategoryCategoryListResponseCategory = {
  id: number;
  key?: string | null;
  name?: string | null;
  icon?: string | null;
  parentId?: number | null;
  children?: AccountCategoryCategoryListResponseCategory[] | null;
};
export type AccountCategoryCategoryListResponse = {
  categories: AccountCategoryCategoryListResponseCategory[];
};
export type DataObjectType = 'Default' | 'EInserat' | 'ChMediaPdf';
export type AccountConfigurationDataObjectTypeListResponseItem = {
  id: number;
  type: DataObjectType;
};
export type AccountConfigurationDataObjectTypeListResponse = {
  items: AccountConfigurationDataObjectTypeListResponseItem[];
};
export type AccountConfigurationPlatformListResponseDataObjectType = {
  id: number;
  type: DataObjectType;
};
export type AccountConfigurationPlatformListResponseItem = {
  name: string;
  dataObjectTypes: AccountConfigurationPlatformListResponseDataObjectType[];
};
export type AccountConfigurationPlatformListResponse = {
  items: AccountConfigurationPlatformListResponseItem[];
};
export type AccountConfigurationGetProcessStepItemType = 'Attribute' | 'Component';
export type AccountConfigurationGetProcessResponseStepItem = {
  type: AccountConfigurationGetProcessStepItemType;
  attributeId?: number | null;
  attributeKey?: string | null;
  componentName?: string | null;
  sortOrder: number;
};
export type AccountConfigurationGetProcessResponseStep = {
  key: string;
  titleResourceKey: string;
  introResourceKey?: string | null;
  items: AccountConfigurationGetProcessResponseStepItem[];
  showPreview: boolean;
  showPriceSummary: boolean;
  showPriceSummaryTotal: boolean;
};
export type AccountConfigurationGetProcessResponse = {
  steps: AccountConfigurationGetProcessResponseStep[];
};
export type AccountContractsListContractsResponseItemExtension = {
  id: number;
  productId: number;
};
export type AccountContractsListContractsResponseItem = {
  id: number;
  productId: number;
  quantity: number;
  available: number;
  extensions: AccountContractsListContractsResponseItemExtension[];
};
export type AccountContractsListContractsResponse = {
  items: AccountContractsListContractsResponseItem[];
};
export type SharedFileMetadata = {
  key: string;
  value?: string | null;
};
export type SharedFile = {
  id: number;
  name: string;
  url: string;
  mimeType: string;
  metadata?: SharedFileMetadata[] | null;
};
export type PublicDataObjectDetailResponseAttributeValue = {
  id: number;
  key?: string | null;
  entryIds?: number[] | null;
  valueText?: string | null;
};
export type PublicDataObjectDetailResponseAccount = {
  id: number;
  phone?: string | null;
  email?: string | null;
  url?: string | null;
};
export type PublicDataObjectDetailResponseAddress = {
  id: number;
  name?: string | null;
  companyName?: string | null;
  street?: string | null;
  zipCode?: string | null;
  city?: string | null;
  latitude?: number | null;
  longitude?: number | null;
};
export type PublicDataObjectDetailResponse = {
  id: number;
  categoryId: number;
  categoryIdPath: number[];
  title?: string | null;
  description?: string | null;
  datePosted?: string | null;
  dateExpires?: string | null;
  files: SharedFile[];
  attributes: PublicDataObjectDetailResponseAttributeValue[];
  owner: PublicDataObjectDetailResponseAccount;
  location?: PublicDataObjectDetailResponseAddress;
};
export type ErrorResultValidationError = {
  property: string;
  message: string;
};
export type ErrorResult = {
  message: string;
  validation?: ErrorResultValidationError[] | null;
};
export type PublicDataObjectListResponseAttributeValue = {
  id?: number | null;
  key?: string | null;
  text: string;
};
export type PublicDataObjectListResponseAttribute = {
  id: number;
  type: DataAttributeType;
  key?: string | null;
  name: string;
  values: PublicDataObjectListResponseAttributeValue[];
  valueText?: string | null;
};
export type PublicDataObjectListResponseItem = {
  id: number;
  categoryId: number;
  title?: string | null;
  price?: number | null;
  image?: SharedFile;
  attributes?: PublicDataObjectListResponseAttribute[] | null;
  datePosted?: string | null;
  dateExpires?: string | null;
  location?: string | null;
};
export type PublicDataObjectListResponseAggregateContainer = {
  attributes: {
    [key: string]: number;
  };
  categories: {
    [key: string]: number;
  };
};
export type PublicDataObjectListResponse = {
  count: number;
  items: PublicDataObjectListResponseItem[];
  aggregates?: PublicDataObjectListResponseAggregateContainer;
  totalCount: number;
  pageSize: number;
};
export type SortOrder = 'Ascending' | 'Descending';
export type PublicDataObjectContactRequest = {
  dataObjectId: number;
  captcha: string;
  name: string;
  email: string;
  location?: string | null;
  phoneNumber?: string | null;
  message: string;
  flags?: string[] | null;
};
export type DataObjectStatus = 'Draft' | 'Active' | 'Disabled' | 'Deleted' | 'ToApprove' | 'Expired' | 'Approved';
export type AccountSharedDataObjectValueRec = {
  attributeId: number;
  value?: string | null;
  entries?: number[] | null;
};
export type AccountSharedDataObjectRec = {
  id: number;
  type: number;
  categoryId: number;
  platform: string;
  status: DataObjectStatus;
  values: AccountSharedDataObjectValueRec[];
};
export type AccountDataObjectCreateDataObjectResponse = {
  dataObject: AccountSharedDataObjectRec;
};
export type AccountSharedFileRec = {
  id: number;
  filename: string;
  contentType: string;
  base64Data: string;
};
export type AccountDataObjectCreateDataObjectRequest = {
  categoryId: number;
  type: number;
  values: AccountSharedDataObjectValueRec[];
  files?: AccountSharedFileRec[] | null;
};
export type AccountDataObjectListDataObjectsResponseItem = {
  id: number;
  type: number;
  categoryId: number;
  status: DataObjectStatus;
  title: string;
  category: string;
  imageUrl?: string | null;
  created: string;
  publicationStart?: string | null;
  publicationEnd?: string | null;
  allowDisable: boolean;
  allowEnable: boolean;
};
export type AccountDataObjectListDataObjectsResponse = {
  totalCount: number;
  items: AccountDataObjectListDataObjectsResponseItem[];
};
export type AccountDataObjectLoadDataObjectResponse = {
  dataObject: AccountSharedDataObjectRec;
  files: SharedFile[];
};
export type AccountDataObjectUpdateDataObjectResponse = {
  dataObject: AccountSharedDataObjectRec;
};
export type AccountDataObjectUpdateDataObjectRequest = {
  id: number;
  values: AccountSharedDataObjectValueRec[];
  files?: AccountSharedFileRec[] | null;
};
export type AccountDataObjectDuplicateDataObjectResponse = {
  id: number;
};
export type PublicFavoritesFavoriteListResponse = {
  dataObjectIds: number[];
};
export type PublicFavoritesRemoveFavoritesResponse = {
  dataObjectIds: number[];
};
export type PublicFavoritesRemoveFavoritesRequest = {
  dataObjectIds: number[];
};
export type PublicFavoritesSaveFavoritesResponse = {
  dataObjectIds: number[];
};
export type PublicFavoritesSaveFavoritesRequest = {
  dataObjectIds: number[];
  replace?: boolean | null;
};
export type PublicGeoRegionListResponseRegion = {
  id: number;
  name: string;
};
export type PublicGeoRegionListResponse = {
  regions: PublicGeoRegionListResponseRegion[];
};
export type PublicGeoLocationListResponseItem = {
  id: number;
  name: string;
  zipCode?: string | null;
  lat?: number | null;
  lng?: number | null;
};
export type PublicGeoLocationListResponse = {
  items: PublicGeoLocationListResponseItem[];
};
export type AccountRole = 'Invited' | 'Guest' | 'Member' | 'Admin' | 'Owner';
export type AccountLoginsListLoginsResponseItem = {
  id: number;
  email: string;
  roles: string[];
};
export type SharedListResponseAccountLoginsListLoginsResponseItem = {
  totalCount: number;
  items: AccountLoginsListLoginsResponseItem[];
};
export type AccountLoginsListInvitationsResponseResponseItem = {
  accountId: number;
  accountName: string;
};
export type AccountLoginsListInvitationsResponse = {
  items: AccountLoginsListInvitationsResponseResponseItem[];
};
export type AccountLoginsSetLoginRoleRequest = {
  id: number;
  role: AccountRole;
};
export type AccountLogoSaveLogoResponse = {
  fileId: number;
};
export type MetadataPageContentGetResponseContentBlock = {
  key: string;
  type: string;
  content: string;
};
export type MetadataPageContentGetResponse = {
  contentBlocks: MetadataPageContentGetResponseContentBlock[];
};
export type AccountPrintAdvertPrintAdvertRecPreview = {
  height: number;
  contentType: string;
  dataUri: string;
};
export type AccountPrintAdvertPrintAdvertRecPricePricePositionRec = {
  description: string;
  amount: number;
  isBasedOnHeight: boolean;
  currency: string;
};
export type AccountPrintAdvertPrintAdvertRecPrice = {
  productId: number;
  advertHeight: number;
  advertColumns: number;
  positions: AccountPrintAdvertPrintAdvertRecPricePricePositionRec[];
  amount: number;
  vatAmount: number;
  vatPercentage: number;
  grossAmount: number;
  currency: string;
};
export type AccountPrintAdvertPrintAdvertRecEInseratContext = {
  orderId?: string | null;
  orderItemId?: string | null;
  imageId?: string | null;
  imageHash?: string | null;
};
export type AccountPrintAdvertCreatePrintAdvertResponse = {
  dataObject: AccountSharedDataObjectRec;
  image?: AccountSharedFileRec;
  preview: AccountPrintAdvertPrintAdvertRecPreview;
  selectedIssues: string[];
  priceSummary: AccountPrintAdvertPrintAdvertRecPrice;
  eInseratContext: AccountPrintAdvertPrintAdvertRecEInseratContext;
};
export type AccountPrintAdvertCreatePrintAdvertRequest = {
  categoryId: number;
  type: number;
  values: AccountSharedDataObjectValueRec[];
  image?: AccountSharedFileRec;
  selectedIssues: string[];
  eInseratContext?: AccountPrintAdvertPrintAdvertRecEInseratContext;
};
export type AccountPrintAdvertLoadIssueDatesResponse = {
  dates: string[];
};
export type AccountPrintAdvertLoadPrintAdvertResponse = {
  dataObject: AccountSharedDataObjectRec;
  image?: AccountSharedFileRec;
  preview: AccountPrintAdvertPrintAdvertRecPreview;
  selectedIssues: string[];
  bookedIssues: string[];
  priceSummary: AccountPrintAdvertPrintAdvertRecPrice;
  eInseratContext?: AccountPrintAdvertPrintAdvertRecEInseratContext;
};
export type AccountPrintAdvertUpdatePrintAdvertResponse = {
  dataObject: AccountSharedDataObjectRec;
  preview: AccountPrintAdvertPrintAdvertRecPreview;
  selectedIssues: string[];
  bookedIssues: string[];
  priceSummary: AccountPrintAdvertPrintAdvertRecPrice;
  eInseratContext: AccountPrintAdvertPrintAdvertRecEInseratContext;
};
export type AccountPrintAdvertUpdatePrintAdvertRequest = {
  id: number;
  values: AccountSharedDataObjectValueRec[];
  image?: AccountSharedFileRec;
  selectedIssues?: string[] | null;
  eInseratContext?: AccountPrintAdvertPrintAdvertRecEInseratContext;
};
export type AccountPrintAdvertPreviewPrintAdvertResponse = {
  image?: AccountSharedFileRec;
  preview: AccountPrintAdvertPrintAdvertRecPreview;
  priceSummary: AccountPrintAdvertPrintAdvertRecPrice;
  eInseratContext: AccountPrintAdvertPrintAdvertRecEInseratContext;
};
export type AccountPrintAdvertPreviewPrintAdvertRequest = {
  dataObjectId?: number | null;
  values: AccountSharedDataObjectValueRec[];
  image?: AccountSharedFileRec;
  eInseratContext?: AccountPrintAdvertPrintAdvertRecEInseratContext;
};
export type AccountProductsListProductsResponseItem = {
  id: number;
  name: string;
  description?: string | null;
  price: number;
  duration?: number | null;
  numberOfAds?: number | null;
  extensionProductIds: number[];
  group?: string | null;
};
export type AccountProductsListProductsResponse = {
  items: AccountProductsListProductsResponseItem[];
};
export type AccountPublicationPublicationOptionListResponseProduct = {
  id: number;
  name: string;
  description?: string | null;
  price: number;
  duration?: number | null;
  numberOfAds?: number | null;
  extensionProducts: AccountPublicationPublicationOptionListResponseProduct[];
  group?: string | null;
};
export type AccountPublicationPublicationOptionListResponseContractExtension = {
  id: number;
  product: AccountPublicationPublicationOptionListResponseProduct;
  price: number;
};
export type AccountPublicationPublicationOptionListResponseContract = {
  id: number;
  product: AccountPublicationPublicationOptionListResponseProduct;
  price: number;
  quantity: number;
  available: number;
  activeExtensions: AccountPublicationPublicationOptionListResponseContractExtension[];
  availableExtensions: AccountPublicationPublicationOptionListResponseProduct[];
};
export type PublicationStatus = 'Active' | 'Disabled' | 'Deleted' | 'Blocked';
export type AccountPublicationPublicationOptionListResponsePublication = {
  id: number;
  contract: AccountPublicationPublicationOptionListResponseContract;
  status: PublicationStatus;
  startDate: string;
  endDate?: string | null;
};
export type AccountPublicationPublicationOptionListResponse = {
  activePublication?: AccountPublicationPublicationOptionListResponsePublication;
  availableContracts?: AccountPublicationPublicationOptionListResponseContract[] | null;
  availableProducts?: AccountPublicationPublicationOptionListResponseProduct[] | null;
};
export type AccountPublicationPublicationListResponseItem = {
  id: number;
  contractId: number;
  dataObjectId: number;
  status: PublicationStatus;
  startDate: string;
  endDate?: string | null;
};
export type AccountPublicationPublicationListResponse = {
  items: AccountPublicationPublicationListResponseItem[];
};
export type AccountPublicationPublishDataObjectResponse = {
  publicationId: number;
};
export type AccountPublicationPublishDataObjectRequest = {
  dataObjectId: number;
  contractId?: number | null;
  productId?: number | null;
  extensionProductIds?: number[] | null;
  billingAddressId?: number | null;
};
export const {
  usePostV1AccountAccountMutation,
  useGetV1AccountAccountQuery,
  useGetV1AccountAccountByIdQuery,
  usePutV1AccountAccountByIdMutation,
  useGetV1AccountAccountLoginInfoQuery,
  useGetV1AccountAddressQuery,
  usePostV1AccountAddressMutation,
  useDeleteV1AccountAddressByIdMutation,
  usePutV1AccountAddressByIdMutation,
  useGetV1AttributeQuery,
  useGetV1AccountAttributeQuery,
  useGetV1CategoryQuery,
  useGetV1AccountCategoryQuery,
  useGetV1AccountConfigurationDataObjectTypesQuery,
  useGetV1AccountConfigurationPlatformsQuery,
  useGetV1AccountConfigurationProcessQuery,
  useGetV1AccountContractsQuery,
  useGetV1DataObjectByIdQuery,
  useGetV1DataObjectQuery,
  usePostV1DataObjectByIdContactMutation,
  usePutV1AccountDataObjectByIdActivateMutation,
  usePostV1AccountDataObjectMutation,
  useGetV1AccountDataObjectQuery,
  usePutV1AccountDataObjectByIdDeactivateMutation,
  useDeleteV1AccountDataObjectByIdMutation,
  useGetV1AccountDataObjectByIdQuery,
  usePutV1AccountDataObjectByIdMutation,
  usePostV1AccountDataObjectByIdDuplicateMutation,
  useGetV1FavoriteQuery,
  useDeleteV1FavoriteMutation,
  usePostV1FavoriteMutation,
  useGetV1FileByIdQuery,
  useGetV1GeoRegionsQuery,
  useGetV1GeoLocationsQuery,
  usePutV1AccountLoginsAcceptInvitationMutation,
  usePostV1AccountLoginsMutation,
  useGetV1AccountLoginsQuery,
  useGetV1AccountLoginsInvitationsQuery,
  useDeleteV1AccountLoginsByIdMutation,
  usePutV1AccountLoginsSetRoleMutation,
  useDeleteV1AccountLogoMutation,
  usePostV1AccountLogoMutation,
  useGetV1AccountLogoByAccountIdQuery,
  useGetV1PageContentQuery,
  usePostV1AccountPrintAdvertMutation,
  useGetV1AccountPrintAdvertIssueDatesQuery,
  useGetV1AccountPrintAdvertByIdQuery,
  usePutV1AccountPrintAdvertByIdMutation,
  usePostV1AccountPrintAdvertPreviewMutation,
  useGetV1AccountProductsQuery,
  useGetV1AccountPublicationOptionsQuery,
  useGetV1AccountPublicationDataObjectByIdQuery,
  usePostV1AccountPublicationDataObjectByIdMutation,
} = injectedRtkApi;
