import { Converters } from '../constants';
import { defaultConverter } from './DefaultConverter';
import { eInseratConverter } from './EInseratConverter';
import type { HtmlConverter } from './types';
export * from './types';

const converters: Record<string, HtmlConverter> = {
  [Converters.default]: defaultConverter,
  [Converters.einserat]: eInseratConverter,
};

export const getConverter = (converter?: HtmlConverter | string) => {
  if (!converter) {
    return converters.default;
  }

  if (typeof converter === 'string') {
    const namedConverter = converters[converter];
    return namedConverter || converters.default;
  }

  return converter;
};
