import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

export const TypeaheadComponentName = 'Typeahead' as const;
export const TypeaheadParts = ['input', 'dropdown'] as const;

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(TypeaheadParts);

const baseStyle = definePartsStyle({
  input: {},
  dropdown: {
    backgroundColor: 'white',
    boxShadow: 'heavy',
    position: 'absolute',
    top: '100%',
    width: '100%',
    zIndex: 999,
  },
});

export const TypeaheadStyle = defineMultiStyleConfig({ baseStyle });
