import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import { api, initApi, queryErrorMiddleware } from '@netiva/classifieds-api';
import { getAcceptLanguage } from '@netiva/classifieds-common';

import { getAccessToken } from '@/authentication';
import { toast } from '@/components/ui';
import { BaseUrl, Platform } from '@/environment';
import i18n from '@/i18n';

import accountReducer from './account';
import adReducer from './ad';
import authReducer, { authActions } from './auth';
import { resetApiStateMiddleware } from './middlewares';

initApi({
  queryOptions: {
    baseUrl: BaseUrl,
    prepareHeaders: async (headers, api) => {
      const accessToken = await getAccessToken();
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }

      const state = api.getState() as RootState;

      if (!headers.get('X-Platform')) {
        const requestPlatform = state.ad.platform || Platform;
        if (requestPlatform) {
          headers.set('X-Platform', requestPlatform);
        }
      }

      if (state.account.accountId && !headers.get('X-AccountId')) {
        headers.set('X-AccountId', state.account.accountId.toString());
      }

      if (i18n.language) {
        headers.set('Accept-Language', getAcceptLanguage(i18n.language));
      }

      return headers;
    },
  },
  errorHandler(error, dispatch) {
    switch (error.status) {
      case 401:
      case 423:
        if (error.status === 423) {
          dispatch(authActions.setIsLocked(true));
        }
        if (window.location.pathname !== '/logout') {
          window.location.href = '/logout';
        }
        break;
      case 403:
      case 404:
        // those should be handled by the call site
        break;
      case 'FETCH_ERROR':
        console.log('Failed to access the API.', error);
        toast({
          status: 'error',
          description: 'Failed to access the API.',
          duration: 2000,
          isClosable: true,
        });
        break;
      default:
        toast({
          status: 'error',
          description: error.data?.message,
          duration: null,
          isClosable: true,
        });
        break;
    }
  },
});

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    account: accountReducer,
    ad: adReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware, queryErrorMiddleware).prepend(resetApiStateMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
