import { useEffect, useMemo } from 'react';
import { accountApi, QueryErrorPayload } from '@netiva/classifieds-api';

import { useAppDispatch, useAppSelector } from '@/store';
import { accountActions } from '@/store/account';
import { getSelectedAccountId } from '@/lib/storage';
import { useAuth } from './useAuth';

export const useAccounts = () => {
  const dispatch = useAppDispatch();
  const { accountId, isNewUser } = useAppSelector((s) => s.account);
  const { isAuthenticated } = useAuth();

  // set account id from url parameter for user impersonation or from local storage to use the previously selected account
  const searchParams = new URLSearchParams(window.location.search);
  const accountIdParam = searchParams.get('aid');
  useEffect(() => {
    if (accountIdParam) {
      const aid = Number(accountIdParam);
      if (aid && !isNaN(aid)) {
        dispatch(accountActions.setAccountId(aid));
      }
    } else {
      dispatch(accountActions.setAccountId(getSelectedAccountId()));
    }
  }, [accountIdParam, dispatch]);

  // fetch account list
  const {
    data: accountsData,
    isLoading: accountsLoading,
    isFetching: accountsFetching,
  } = accountApi.useGetAccounts({}, { skip: !isAuthenticated });

  // fetch selected account
  const {
    data: accountData,
    isLoading: accountLoading,
    isFetching: accountFetching,
    error: accountError,
  } = accountApi.useGetAccountById(
    { id: accountId!, 'X-AccountId': accountId! },
    { skip: !isAuthenticated || !accountId }
  );
  const isLoading = accountsLoading || accountsFetching || accountLoading || accountFetching;

  const accounts = useMemo(() => accountsData?.accounts || [], [accountsData?.accounts]);

  // reset the selected account ID in case of a forbidden error
  useEffect(() => {
    if (accountError) {
      const queryError = accountError as QueryErrorPayload;
      if (queryError.status === 403) {
        dispatch(accountActions.setAccountId(undefined));
      }
    }
  }, [accountError, dispatch]);

  // set account id if not set
  useEffect(() => {
    if (!isLoading && !accountId && accountsData?.accounts.length) {
      dispatch(accountActions.setAccountId(accountsData.accounts[0].id));
    }
  }, [accountId, accountsData?.accounts, dispatch, isLoading]);

  useEffect(() => {
    if (!isLoading && accountsData?.accounts && !accountsData.accounts.length) {
      dispatch(accountActions.setIsNewUser(true));
    }
  }, [accountsData?.accounts, isLoading, dispatch]);

  useEffect(() => {
    if (accountData) {
      dispatch(accountActions.setAccount(accountData?.account));
    }
  }, [accountData, dispatch]);

  useEffect(() => {
    dispatch(accountActions.setIsLoading(isLoading));
  }, [isLoading, dispatch]);

  return {
    accounts,
    hasAccount: !!accountsData?.accounts,
    isLoading: accountsLoading || accountLoading,
    isNewUser,
  };
};
