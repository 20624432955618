import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

export const SidebarParts = ['container', 'list', 'listItem', 'listItemLink'] as const;

export const SidebarComponentName = 'Sidebar' as const;

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(SidebarParts);

const baseStyleContainer = defineStyle({
  bg: 'white',
  borderRight: 'default',
  padding: 4,
  transition: 'default',
  w: { base: 'full', md: '250px', lg: '300px' },
});

const baseStyleList = defineStyle({
  listStyleType: 'none',
  mb: 8,
});

const baseStyleListItem = defineStyle({
  marginBottom: 2,
});

const baseStyleListItemLink = defineStyle({
  display: 'block',
  borderRadius: 'default',
  bgColor: 'surface',
  paddingX: 4,
  paddingY: 2,
  textDecoration: 'none',
  transition: '0.5s',
  fontWeight: 'bold',

  _hover: {
    bgColor: 'surfaceHover',
  },
});

const baseStyle = definePartsStyle({
  container: baseStyleContainer,
  list: baseStyleList,
  listItem: baseStyleListItem,
  listItemLink: baseStyleListItemLink,
});

export const Sidebar = defineMultiStyleConfig({
  baseStyle,
});
