import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { pages } from '@/config';
import { AuthPage } from '@/pages';

import LanguageRoute from './LanguageRoute';
import PageRoute from './PageRoute';

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {pages.map((page) => (
          <Route path={page.path} key={page.id} element={<PageRoute page={page} />} />
        ))}
        <Route path="/auth/:action" element={<AuthPage />} />
        <Route path="/:lng/*" element={<LanguageRoute />} />
      </Routes>
    </BrowserRouter>
  );
};
