import { ChangeEvent, FC, useMemo } from 'react';

import { AccountFormData } from '@/store/account/types';
import { FormControl, FormControlProps, FormLabel, Input } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '@/store';
import { accountActions } from '@/store/account';
import { RequiredAccountFields } from '@/config';

export interface AccountInputProps extends FormControlProps {
  name: keyof AccountFormData;
  label: string;
}

export const AccountInput: FC<AccountInputProps> = ({ name, label, ...rest }) => {
  const dispatch = useAppDispatch();
  const { formData } = useAppSelector((state) => state.account);
  const value = useMemo(() => (formData[name] as string) || '', [formData, name]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(accountActions.updateFormData({ key: name, value: e.target.value }));
  };

  const isRequired = RequiredAccountFields.includes(name);

  return (
    <FormControl isRequired={isRequired} isInvalid={isRequired && !value} {...rest}>
      <FormLabel>{label}</FormLabel>
      <Input name={name} onChange={handleOnChange} value={value} />
    </FormControl>
  );
};
