import { FC } from 'react';
import { chakra, HTMLChakraProps, ThemingProps, useStyleConfig } from '@chakra-ui/react';

import { DescriptionListItemComponentName } from '../../theme';

export type DescriptionListItemProps = HTMLChakraProps<'dd'> & ThemingProps<'DescriptionListItem'>;

export const DescriptionListItem: FC<DescriptionListItemProps> = (props) => {
  const style = useStyleConfig(DescriptionListItemComponentName, props);
  return <chakra.dd __css={style} {...props} />;
};
