import { extendTheme, ThemeOverride } from '@chakra-ui/react';
import { defaultTheme as uiTheme } from '@netiva/classifieds-ui';

import { Instance } from '@/environment';
import { Header, Footer, Menu, Sidebar, Steps } from './components';
export { default as reactCrop } from './reactCrop';
export * from './components';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const platformTheme = require(`./platforms/${Instance}`).default || {};

export const faviconPath = `/assets/favicons/${Instance}.ico`;

const theme = extendTheme(
  uiTheme,
  {
    breakpoints: {
      sm: '480px',
      md: '768px',
      lg: '1200px',
      xl: '1600px',
      '2xl': '1800px',
    },
    fonts: {
      body: `'IBM Plex Sans', sans-serif`,
      heading: `'IBM Plex Sans', sans-serif`,
    },
    borders: {
      default: '1px solid surface',
    },
    shadows: {
      default: '0px 4px 15px 0px #0000001A',
    },
    transitions: {
      default: '2s ease',
    },
    components: {
      Header,
      Footer,
      Menu,
      Sidebar,
      Steps,
    },
  } as ThemeOverride,
  platformTheme
);

export default theme;
