import { FC } from 'react';
import { Box, BoxProps, ChakraProps, Flex } from '@chakra-ui/react';
import {
  DndContext,
  DragEndEvent,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';

import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { AdFile } from '@/store/ad/types';
import { Thumbnail } from './Thumbnail';

export type ImagePanelProps = BoxProps & {
  allowReorder?: boolean;
  maxImageSize?: ChakraProps['maxWidth'];
  onRemoveImage: (event: React.MouseEvent, image: AdFile) => void;
  onReplaceImage: (event: React.MouseEvent, image: AdFile) => void;
};

export const ImagePanel: FC<ImagePanelProps> = ({
  allowReorder,
  maxImageSize,
  onRemoveImage,
  onReplaceImage,
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const { images } = useAppSelector((state) => state.ad);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = images.findIndex((img) => img.id === active.id);
      const newIndex = images.findIndex((img) => img.id === over.id);
      const newImages = arrayMove(images, oldIndex, newIndex);
      dispatch(adActions.setImages(newImages));
    }
  };

  return (
    <Box {...rest}>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={images} strategy={horizontalListSortingStrategy}>
          <Flex flexWrap="wrap" justify="center" align="stretch" gap={4}>
            {images.map((img) => (
              <Thumbnail
                key={img.id}
                image={img}
                allowReorder={allowReorder}
                onRemove={(e) => onRemoveImage(e, img)}
                onReplace={(e) => onReplaceImage(e, img)}
                maxImageSize={maxImageSize}
              />
            ))}
          </Flex>
        </SortableContext>
      </DndContext>
    </Box>
  );
};
