import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const LocationIcon: FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 9.33333333 13.3333333" {...props}>
      <path
        fill="currentColor"
        d="M4.66666667,0 C7.24666667,0 9.33333333,2.08666667 9.33333333,4.66666667 C9.33333333,8.16666667 4.66666667,13.3333333 4.66666667,13.3333333 C4.66666667,13.3333333 0,8.16666667 0,4.66666667 C0,2.08666667 2.08666667,0 4.66666667,0 Z M4.66666667,3 C3.74666667,3 3,3.74666667 3,4.66666667 C3,5.58666667 3.74666667,6.33333333 4.66666667,6.33333333 C5.58666667,6.33333333 6.33333333,5.58666667 6.33333333,4.66666667 C6.33333333,3.74666667 5.58666667,3 4.66666667,3 Z"
      ></path>
    </Icon>
  );
};
