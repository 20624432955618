import { FC, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Divider, Flex, SimpleGrid, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { accountApi, AccountDataObjectListDataObjectsResponseItem, DataObjectStatus } from '@netiva/classifieds-api';
import { parseQueryValue, useDebouncedState } from '@netiva/classifieds-common';
import { ConfirmationDialog, Loader, Pagination } from '@netiva/classifieds-ui';

import { DefaultLayout as Layout } from '@/components/layout';
import { usePagination, useQueryParams } from '@/hooks';
import { adPath } from '@/lib/routes';
import { Ad, Filter } from './components';

const PageSize = 20;

export const MyAdsPage: FC = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const currentPage = parseQueryValue(queryParams.get('p'), parseInt) || 1;

  const [filter, setFilter, debouncedFilter] = useDebouncedState<string>(500, '');
  const [status, setStatus] = useState<DataObjectStatus>();
  const hasFilter = !!(filter || status);

  const { data: adList, isFetching: adListLoading } = accountApi.useGetDataObjects({
    filter: debouncedFilter,
    status,
    pageIndex: currentPage - 1,
    pageSize: PageSize,
  });
  const items = useMemo(() => adList?.items || [], [adList?.items]);
  const [activateDataObject, { isLoading: isLoadingActivate }] = accountApi.useActivateDataObject();
  const [deactivateDataObject, { isLoading: isLoadingDeactivate }] = accountApi.useDeactivateDataObject();
  const [deleteDataObject, { isLoading: isLoadingDelete }] = accountApi.useDeleteDataObject();
  const [duplicateDataObject, { isLoading: isLoadingDuplicate }] = accountApi.useDuplicateDataObject();

  const totalPages = Math.ceil((adList?.totalCount || 0) / PageSize);
  const paginationProps = usePagination({ totalPages });

  const [deletingObjectId, setDeletingObjectId] = useState<number>();
  const {
    isOpen: isDeleteConfirmationOpen,
    onOpen: openDeleteConfirmation,
    onClose: closeDeleteConfirmation,
  } = useDisclosure();

  const handleDelete = (item: AccountDataObjectListDataObjectsResponseItem) => {
    setDeletingObjectId(item.id);
    openDeleteConfirmation();
  };
  const handleDeleteConfirmation = async () => {
    if (deletingObjectId) {
      await deleteDataObject({ id: deletingObjectId });
      setDeletingObjectId(undefined);
      closeDeleteConfirmation();
    }
  };
  const handleDeleteCancel = () => {
    setDeletingObjectId(undefined);
    closeDeleteConfirmation();
  };

  const handleEnable = (item: AccountDataObjectListDataObjectsResponseItem) => {
    activateDataObject({ id: item.id });
  };

  const handleDisable = (item: AccountDataObjectListDataObjectsResponseItem) => {
    deactivateDataObject({ id: item.id });
  };

  const handleDuplicate = async (item: AccountDataObjectListDataObjectsResponseItem) => {
    const result = await duplicateDataObject({ id: item.id }).unwrap();
    navigate(adPath(result.id, 'edit'));
  };

  const isLoading = adListLoading || isLoadingActivate || isLoadingDeactivate || isLoadingDelete || isLoadingDuplicate;

  return (
    <Layout title={t('myads.title')}>
      <Loader isLoading={isLoading} showOverlay />
      <Filter filter={filter} onFilterChanged={setFilter} onStatusChanged={setStatus} />
      <Divider my={4} />

      {!isLoading && items.length === 0 && (
        <Alert status="info">{hasFilter ? t('myads.emptyFiltered') : t('myads.empty')}</Alert>
      )}

      <SimpleGrid columns={[1, 2, 3, 4, 5]} gap={4}>
        {items.map((item) => (
          <Ad
            key={item.id}
            item={item}
            onDelete={() => handleDelete(item)}
            onDisable={() => handleDisable(item)}
            onDuplicate={() => handleDuplicate(item)}
            onEnable={() => handleEnable(item)}
          />
        ))}
      </SimpleGrid>

      <Flex mt={4} justify="center" align="center">
        <Pagination {...paginationProps} />
      </Flex>

      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirmation}
        cancelText={t('ui.buttons.cancel')}
        okText={t('ui.buttons.delete')}
        title={t('myads.actions.deleteConfirmation.title')}
        message={t('myads.actions.deleteConfirmation.message')}
      />
    </Layout>
  );
});
