import { AddressRec } from '@netiva/classifieds-api';
import { AccountFormData } from '@/store/account/types';

export const createFormData = (accountAddress?: AddressRec) => {
  const formData: AccountFormData = {};
  if (accountAddress) {
    Object.entries(accountAddress).forEach(([key, value]) => {
      formData[key as keyof AddressRec] = value;
    });
  }
  return formData;
};
