import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, FormControl, FormLabel, Text, useToast } from '@chakra-ui/react';
import { readFileAsBase64 } from '@netiva/classifieds-common';
import { FileInput, FileInputClickBox, Loader } from '@netiva/classifieds-ui';
import { v4 as uuid } from 'uuid';

import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { AdFile } from '@/store/ad/types';
import { AdComponentProps } from '../../types';
import { DocumentListItem } from './components';

export const DocumentSelector: FC<AdComponentProps> = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { documents } = useAppSelector((state) => state.ad);
  const [isLoading, setIsLoading] = useState(false);
  const [replacingDocument, setReplacingDocument] = useState<AdFile>();

  const hasDocument = documents.length > 0;

  const showInvalidFileNotification = (name: string) => {
    toast({
      status: 'error',
      title: t('ad.steps.files.documents.invalidFile.title'),
      description: t('ad.steps.files.documents.invalidFile.description', { name }),
      duration: 10000,
      isClosable: true,
    });
  };

  const handleFilesSelected = async (files: FileList) => {
    setIsLoading(true);
    for (const file of files) {
      try {
        const data = await readFileAsBase64(file);
        if (data?.contentType === 'application/pdf') {
          const document = {
            id: uuid(),
            name: file.name,
            uploaded: {
              id: 0,
              base64Data: data.data,
              contentType: data.contentType,
              filename: file.name,
            },
          };
          if (replacingDocument) {
            dispatch(adActions.replaceDocument({ oldDocument: replacingDocument, newDocument: document }));
          } else {
            dispatch(adActions.addDocument(document));
          }
        } else {
          showInvalidFileNotification(file.name);
        }
      } catch (err) {
        console.error('Failed to read file', file);
        showInvalidFileNotification(file.name);
      } finally {
        setReplacingDocument(undefined);
      }
    }
    setIsLoading(false);
  };

  const handleRemove = (e: React.MouseEvent, file: AdFile) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(adActions.removeDocument(file));
  };
  const handleReplace = (_: React.MouseEvent, image: AdFile) => {
    setReplacingDocument(image);
  };

  return (
    <FormControl my={2} position="relative">
      <Loader isLoading={isLoading} />
      <FormLabel>{t('ad.steps.files.documents.label')}</FormLabel>
      <FileInput onFilesSelected={handleFilesSelected} accept=".pdf">
        {!hasDocument && <Text>{t('ad.steps.files.documents.drag')}</Text>}
        {hasDocument && (
          <Flex flexWrap="wrap" justify="center" align="stretch" gap={4} mb={4}>
            {documents.map((doc) => (
              <DocumentListItem
                key={doc.id}
                document={doc}
                onRemove={(e) => handleRemove(e, doc)}
                onReplace={(e) => handleReplace(e, doc)}
              />
            ))}
          </Flex>
        )}
        <Flex wrap="wrap" gap={2}>
          <FileInputClickBox>
            <Button type="button" size="sm" colorScheme="secondary">
              {t('ad.steps.files.documents.upload')}
            </Button>
          </FileInputClickBox>
        </Flex>
      </FileInput>
    </FormControl>
  );
};
