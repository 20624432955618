import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex, FlexProps, IconButton, Image, Text, useMultiStyleConfig } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

import { Instance } from '@/environment';
import { useAuth } from '@/hooks';
import { AccountSelectionButton } from './AccountSelectionButton';
import { LanguageSwitcher } from './LanguageSwitcher';
import { HeaderComponentName } from '@/theme';

export interface HeaderProps extends FlexProps {
  onOpenMenu: () => void;
}

export const Header: FC<HeaderProps> = ({ onOpenMenu, ...rest }) => {
  const { t } = useTranslation();
  const { isAuthenticated, logout } = useAuth();
  const styles = useMultiStyleConfig(HeaderComponentName);

  return (
    <Flex sx={styles.container} {...rest}>
      <Box sx={styles.logo}>
        <Image src={`/assets/platforms/${Instance}/logo.svg`} height="full" px={4} color="red" padding="10px" />
      </Box>

      <Text fontSize="lg" fontWeight="bold" dangerouslySetInnerHTML={{ __html: t('application.title') }} />

      <Flex marginLeft="auto" align="center" gap={2} flexShrink={0}>
        <AccountSelectionButton
          type="button"
          size="sm"
          display={{ base: 'none', md: 'block' }}
          flexShrink={0}
          variant="ghost"
        />

        <LanguageSwitcher display={{ base: 'none', md: 'block' }} />

        {isAuthenticated && (
          <Button type="button" size="sm" display={{ base: 'none', md: 'block' }} flexShrink={0} onClick={logout}>
            {t('ui.buttons.signout')}
          </Button>
        )}

        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpenMenu}
          variant="solid"
          size="sm"
          aria-label="open menu"
          icon={<HamburgerIcon />}
        />
      </Flex>
    </Flex>
  );
};
