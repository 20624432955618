import { Card, CardProps, forwardRef, SystemStyleObject, useStyleConfig } from '@chakra-ui/react';
import { SelectableCardComponentName, SelectableSystemStyleObject } from '../theme';

export interface SelectableCardProps extends CardProps {
  isSelected?: boolean;
  _selected?: SystemStyleObject;
}

const SelectableCard = forwardRef<SelectableCardProps, typeof Card>(({ isSelected, ...rest }, ref) => {
  let styles = useStyleConfig(SelectableCardComponentName, { isSelected, ...rest }) as SelectableSystemStyleObject;
  if (isSelected) {
    styles = { ...styles, ...styles._selected };
  }
  return <Card ref={ref} sx={styles} {...rest} />;
});

export default SelectableCard;
