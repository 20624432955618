import { ThemeOverride } from '@chakra-ui/react';

const agrarmedienTheme: ThemeOverride = {
  styles: {
    global: {
      ':root': {
        fontSize: '15px',
      },
      a: {
        textDecoration: 'underline',
      },
    },
  },
  colors: {
    primary: {
      main: '#FECF39',
      '50': '#FFF9E6',
      '100': '#FFEEB8',
      '200': '#FEE38A',
      '300': '#FED85D',
      '400': '#FECD2F',
      '500': '#FEC101',
      '600': '#CB9B01',
      '700': '#987401',
      '800': '#654D01',
      '900': '#332700',
    },
    secondary: {
      main: '0E242F',
      '50': '#EBF5F9',
      '100': '#C8E2EF',
      '200': '#A5CFE4',
      '300': '#81BCDA',
      '400': '#5EA9CF',
      '500': '#3B96C4',
      '600': '#2F789D',
      '700': '#235A76',
      '800': '#173C4F',
      '900': '#0C1E27',
    },
  },
  semanticTokens: {
    sizes: {
      'layout.sm': '480px',
      'layout.md': '768px',
      'layout.lg': '1200px',
      'layout.xl': '1200px',
      'layout.2xl': '1200px',
    },
  },
  components: {
    Button: {
      variants: {
        solid: (p) => {
          if (p.colorScheme === 'primary') {
            // white text on yellow is hard to read
            return { color: 'dark' };
          }
          return {};
        },
      },
    },
  },
  fonts: {
    body: `'IBM Plex Sans', sans-serif`,
    heading: `'IBM Plex Sans', sans-serif`,
  },
};

export default agrarmedienTheme;
