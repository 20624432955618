import { forwardRef } from 'react';

export function forwardRefGeneric<T, P = object>(
  render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
): (props: P & React.RefAttributes<T>) => React.ReactElement | null {
  // type inference for generic props is lost when using the default react forwardRef function since the props get wrapped again to support plain JS "defaultProps" and "propTypes" on the component.
  // since we are using typescript and generally don't use those properties, we wrap the actual function so type inference works for generic props.
  // @ts-expect-error
  return forwardRef(render);
}
