import { useMemo } from 'react';

export interface UsePaginationProps {
  currentPage: number | undefined;
  totalPages: number;
  maxPages?: number;
}

export const usePaginationCore = ({
  currentPage: currentPageParam,
  totalPages,
  maxPages: maxPagesOverride,
}: UsePaginationProps) => {
  const maxPages = maxPagesOverride || 5;
  const currentPage = currentPageParam || 1;

  const { startPage, endPage } = useMemo(() => {
    let startPage: number;
    let endPage: number;

    if (totalPages <= maxPages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        startPage = 1;
        endPage = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    return { startPage, endPage };
  }, [currentPage, maxPages, totalPages]);

  const pages = useMemo(
    () => Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i),
    [endPage, startPage]
  );

  return {
    currentPage,
    pages,
    startPage,
    endPage,
    totalPages,
  };
};

export type UsePaginationResult = ReturnType<typeof usePaginationCore>;
