import { FC } from 'react';
import { Badge, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { RxComponentPlaceholder } from 'react-icons/rx';
import { FaCheckCircle, FaCopy, FaTimesCircle, FaTrashAlt } from 'react-icons/fa';
import { AccountDataObjectListDataObjectsResponseItem } from '@netiva/classifieds-api';

import { useDate } from '@/hooks';
import { adPath } from '@/lib/routes';
import { ActionButton } from './ActionButton';
import { AdCard, AdCardContent, AdCardImage, AdCardLink } from './AdCard';
import { StatusBadge } from './StatusBadge';

type AdItem = AccountDataObjectListDataObjectsResponseItem;

export interface AdProps {
  item: AdItem;
  onDelete: VoidFunction;
  onDisable: VoidFunction;
  onDuplicate: VoidFunction;
  onEnable: VoidFunction;
}

export const Ad: FC<AdProps> = ({ item, onDelete, onDisable, onDuplicate, onEnable }) => {
  const { formatDate } = useDate();
  const { t } = useTranslation();

  return (
    <AdCard key={item.id}>
      <AdCardLink to={adPath(item.id, 'edit')}>
        <StatusBadge status={item.status} />
        {item.imageUrl ? (
          <AdCardImage src={item.imageUrl} alt={item.title} />
        ) : (
          <Flex height={150} flexShrink={0} marginBottom={2} justify="center" align="center">
            <Icon as={RxComponentPlaceholder} boxSize={12} />
          </Flex>
        )}
        <AdCardContent>
          <Heading as="h3" size="md" mb={4} wordBreak="break-all">
            {item.title}
          </Heading>
          <Badge colorScheme="secondary" fontSize="sm" mb={4} whiteSpace="normal">
            {item.category}
          </Badge>
          <Text fontSize="sm">{formatDate(item.created)}</Text>
        </AdCardContent>
      </AdCardLink>
      <Flex justify="center" align="center" gap={4} paddingBottom={2}>
        <ActionButton iconType={FaTrashAlt} label={t('myads.actions.delete')} onClick={onDelete} />
        {item.allowEnable && (
          <ActionButton iconType={FaCheckCircle} label={t('myads.actions.activate')} onClick={onEnable} />
        )}
        {item.allowDisable && (
          <ActionButton iconType={FaTimesCircle} label={t('myads.actions.deactivate')} onClick={onDisable} />
        )}
        <ActionButton iconType={FaCopy} label={t('myads.actions.duplicate')} onClick={onDuplicate} />
      </Flex>
    </AdCard>
  );
};
