import { FC } from 'react';
import { CardBody, CardHeader, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { SelectableCard, SelectableCardProps } from '@netiva/classifieds-ui';

export type ProductCardProps = SelectableCardProps & {
  title: string;
  subTitle?: string;
};

export const ProductCard: FC<ProductCardProps> = ({ isSelected, title, subTitle, children, ...rest }) => {
  return (
    <SelectableCard width={{ base: 'full', lg: '30%' }} isSelected={isSelected} {...rest}>
      <CardHeader textAlign="center" position="relative">
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          {title}
        </Text>
        {!!subTitle && <Text>{subTitle}</Text>}
        {isSelected && <CheckCircleIcon position="absolute" top={2} right={2} />}
      </CardHeader>
      <CardBody>{children}</CardBody>
    </SelectableCard>
  );
};
