import { FC, PropsWithChildren, Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Provider as StoreProvider } from 'react-redux';
import { AuthenticationProvider } from './authentication';
import { ChakraProvider } from '@chakra-ui/react';
import { Global } from '@emotion/react';

import { resetApiState } from '@netiva/classifieds-api';
import { Loader } from '@netiva/classifieds-ui';

import { AccountSelection, Router } from './components/global';
import { ToastContainer } from './components/ui';
import { store, useAppDispatch } from './store';
import theme, { faviconPath, reactCrop } from './theme';

import './i18n';

import '@fontsource/ibm-plex-sans/200.css';
import '@fontsource/ibm-plex-sans/200-italic.css';
import '@fontsource/ibm-plex-sans/400.css';
import '@fontsource/ibm-plex-sans/400-italic.css';
import '@fontsource/ibm-plex-sans/700.css';
import '@fontsource/ibm-plex-sans/700-italic.css';

const AppContent = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  // reset API state on language change
  useEffect(() => {
    const onLanguageChanged = () => {
      dispatch(resetApiState());
    };

    i18n.on('languageChanged', onLanguageChanged);

    return () => {
      i18n.off('languageChanged', onLanguageChanged);
    };
  }, [dispatch, i18n]);

  return (
    <>
      <Helmet>
        <link rel="icon" href={faviconPath} />
      </Helmet>
      <Global styles={reactCrop} />
      <AccountSelection />
      <Router />
      <ToastContainer />
    </>
  );
};

const AppProviders: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ChakraProvider theme={theme}>
      <Suspense fallback={<Loader isLoading showOverlay />}>
        <AuthenticationProvider>
          <StoreProvider store={store}>{children}</StoreProvider>
        </AuthenticationProvider>
      </Suspense>
    </ChakraProvider>
  );
};

const App = () => {
  return (
    <AppProviders>
      <AppContent />
    </AppProviders>
  );
};

export default App;
