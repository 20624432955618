import { FC, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
} from '@chakra-ui/react';

export type ConfirmationDialogProps = Omit<AlertDialogProps, 'leastDestructiveRef' | 'onClose' | 'children'> & {
  title: string;
  message: string;
  cancelText: string;
  okText: string;
  onCancel: VoidFunction;
  onConfirm: VoidFunction;
};

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  title,
  message,
  cancelText,
  okText,
  onCancel,
  onConfirm,
  ...rest
}) => {
  const cancelRef = useRef(null);

  return (
    <AlertDialog {...rest} leastDestructiveRef={cancelRef} onClose={onCancel}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{message}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} colorScheme="secondary" onClick={onCancel}>
              {cancelText}
            </Button>
            <Button colorScheme="primary" onClick={onConfirm} ml={3}>
              {okText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
