import { accountApi } from '@netiva/classifieds-api';

import { useAppSelector } from '@/store';
import { useAdParams } from './useAdParams';
import { useMemo } from 'react';

export const useAdPublishing = () => {
  const { dataObjectId } = useAdParams();
  const { categoryId, dataObjectType, selectedContractId, selectedProductId, selectedExtensionProductIds } =
    useAppSelector((state) => state.ad);

  // requires either an objectId > 0 or both, dataObjectType and categoryId
  const skipLoading = dataObjectId === undefined || (dataObjectId === 0 && (!dataObjectType || !categoryId));
  const { data: publicationOptions, isLoading } = accountApi.useGetPublicationOptions(
    {
      dataObjectId: dataObjectId || undefined,
      categoryId: categoryId,
      dataObjectType,
    },
    {
      skip: skipLoading,
    }
  );

  const activePublication = useMemo(
    () => publicationOptions?.activePublication,
    [publicationOptions?.activePublication]
  );
  const availableContracts = useMemo(
    () => publicationOptions?.availableContracts || [],
    [publicationOptions?.availableContracts]
  );
  const availableProducts = useMemo(
    () => publicationOptions?.availableProducts || [],
    [publicationOptions?.availableProducts]
  );

  const selectedContract = useMemo(
    () => availableContracts.find((c) => c.id === selectedContractId),
    [availableContracts, selectedContractId]
  );
  const selectedProduct = useMemo(
    () => availableProducts.find((p) => p.id === selectedProductId),
    [availableProducts, selectedProductId]
  );

  const availableExtensionProducts = useMemo(
    () =>
      activePublication?.contract.availableExtensions ||
      selectedContract?.product.extensionProducts ||
      selectedProduct?.extensionProducts ||
      [],
    [
      activePublication?.contract.availableExtensions,
      selectedContract?.product.extensionProducts,
      selectedProduct?.extensionProducts,
    ]
  );

  const selectedExtensionProducts = useMemo(
    () => availableExtensionProducts.filter((ep) => selectedExtensionProductIds.includes(ep.id)),
    [availableExtensionProducts, selectedExtensionProductIds]
  );

  const requiresPublication = useMemo(() => {
    const hasPublication = !!activePublication;
    const hasSelectedContract = !!selectedContract;
    const hasSelectedProduct = !!selectedProduct;
    const hasSelectedExtensionProduct = selectedExtensionProducts.length > 0;
    return !hasPublication || hasSelectedContract || hasSelectedProduct || hasSelectedExtensionProduct;
  }, [activePublication, selectedContract, selectedExtensionProducts.length, selectedProduct]);

  return {
    isLoading,
    activePublication,
    availableContracts,
    availableProducts,
    availableExtensionProducts,
    selectedContractId,
    selectedContract,
    selectedProductId,
    selectedProduct,
    selectedExtensionProductIds,
    selectedExtensionProducts,
    requiresPublication,
  };
};
