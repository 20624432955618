import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, ListItem, OrderedList, Tooltip, useMultiStyleConfig } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';

import { AdStep, AdValidationStatus } from '@/store/ad/types';
import { StepsComponentName } from '@/theme';

export type NavigationBarProps = {
  currentStepIndex: number;
  steps: AdStep[];
  gotoStep: (step: string) => void;
  validationStatus: AdValidationStatus;
};

type StepStatus = 'inactive' | 'active' | 'completed' | 'invalid';

export const NavigationBar: FC<NavigationBarProps> = memo(({ currentStepIndex, steps, gotoStep, validationStatus }) => {
  const { t } = useTranslation();
  const styles = useMultiStyleConfig(StepsComponentName);

  const visibleSteps = useMemo(() => steps.filter((s) => !s.hidden), [steps]);

  const getStatus = (step: string): StepStatus => {
    const stepIndex = steps.findIndex((s) => s.key === step);
    if (stepIndex < currentStepIndex) return validationStatus[step] ? 'completed' : 'invalid';
    if (stepIndex === currentStepIndex) return 'active';
    return 'inactive';
  };

  const handleStepClick = (step: string) => {
    gotoStep(step);
  };

  if (!visibleSteps.length) {
    return null;
  }

  return (
    <Flex justify="center">
      <Box sx={styles.container}>
        <OrderedList sx={styles.steps}>
          {visibleSteps.map((step, i) => {
            const status = getStatus(step.key);
            return (
              <Tooltip
                key={i}
                label={
                  <>
                    {status === 'invalid' && <WarningIcon mr={2} />}
                    {step.titleResourceKey && t(step.titleResourceKey)}
                  </>
                }
                isDisabled={!step.titleResourceKey}
                hasArrow
              >
                <ListItem sx={styles[`step.${status}`]} onClick={() => handleStepClick(step.key)}>
                  {i + 1}
                </ListItem>
              </Tooltip>
            );
          })}
        </OrderedList>
      </Box>
    </Flex>
  );
});
