import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdFormData, AdFormDataPayload, AdFile, AdListItemValue, AdState, AdValidationStatus } from './types';
import { api, DataObjectStatus } from '@netiva/classifieds-api';

export const initialAdState: AdState = {
  bookedIssueDates: [],
  formData: {},
  images: [],
  documents: [],
  selectedExtensionProductIds: [],
  selectedIssueDates: [],
  validationStatus: {},
  hasChanges: false,
};

export const adSlice = createSlice({
  name: 'ad',
  initialState: initialAdState,
  reducers: {
    /** update ad category */
    setCategoryId: (state, action: PayloadAction<number>) => {
      state.categoryId = action.payload;
    },
    /** update ad status */
    setStatus: (state, action: PayloadAction<DataObjectStatus>) => {
      state.status = action.payload;
    },
    /** update existing ad atrribute values */
    updateValues: (state, action: PayloadAction<AdListItemValue[] | undefined>) => {
      state.values = action.payload;
    },
    /** update single form data object */
    updateFormData: (state, action: PayloadAction<AdFormDataPayload>) => {
      state.formData[action.payload.key] = {
        value: action.payload.value,
        entries: action.payload.entries,
      };
      state.hasChanges = true;
    },
    /** update form data object in a bulk */
    bulkUpdateFormData: (state, action: PayloadAction<AdFormData>) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    },
    /** reset all ad data */
    resetData: () => {
      return {
        ...initialAdState,
      };
    },
    addImage: (state, action: PayloadAction<AdFile>) => {
      state.images = [...state.images, action.payload];
      state.hasChanges = true;
    },
    setImages: (state, action: PayloadAction<AdFile[]>) => {
      state.images = action.payload;
      state.hasChanges = true;
    },
    removeImage: (state, action: PayloadAction<AdFile>) => {
      state.images = state.images.filter((img) => img.id !== action.payload.id);
      state.hasChanges = true;
    },
    replaceImage: (state, action: PayloadAction<{ oldImage: AdFile; newImage: AdFile }>) => {
      state.images = state.images.map((img) => (img.id === action.payload.oldImage.id ? action.payload.newImage : img));
      state.hasChanges = true;
    },
    addDocument: (state, action: PayloadAction<AdFile>) => {
      state.documents = [...state.documents, action.payload];
      state.hasChanges = true;
    },
    setDocuments: (state, action: PayloadAction<AdFile[]>) => {
      state.documents = action.payload;
      state.hasChanges = true;
    },
    removeDocument: (state, action: PayloadAction<AdFile>) => {
      state.documents = state.documents.filter((doc) => doc.id !== action.payload.id);
      state.hasChanges = true;
    },
    replaceDocument: (state, action: PayloadAction<{ oldDocument: AdFile; newDocument: AdFile }>) => {
      state.documents = state.documents.map((doc) =>
        doc.id === action.payload.oldDocument.id ? action.payload.newDocument : doc
      );
      state.hasChanges = true;
    },
    setSelectedIssueDates: (state, action: PayloadAction<string[]>) => {
      state.selectedIssueDates = action.payload;
      state.hasChanges = true;
    },
    updateValidationStatus: (state, action: PayloadAction<AdValidationStatus>) => {
      state.validationStatus = action.payload;
    },
    setPlatform: (state, action: PayloadAction<string>) => {
      state.platform = action.payload;
    },
    setDataObjectType: (state, action: PayloadAction<number>) => {
      state.dataObjectType = action.payload;
    },
    setSelectedContractId: (state, action: PayloadAction<number>) => {
      state.selectedContractId = action.payload;
    },
    setSelectedProductId: (state, action: PayloadAction<number>) => {
      state.selectedProductId = action.payload;
    },
    selectExtensionProductId: (state, action: PayloadAction<number>) => {
      state.selectedExtensionProductIds = [...state.selectedExtensionProductIds, action.payload];
    },
    deselectExtensionProductId: (state, action: PayloadAction<number>) => {
      state.selectedExtensionProductIds = state.selectedExtensionProductIds.filter((id) => id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getV1AccountDataObjectById.matchFulfilled, (state, { payload }) => {
      state.images = [];
      state.documents = [];
      payload.files.forEach((file) => {
        const adFile: AdFile = { id: file.id.toString(), name: file.name, existing: file };
        if (file.mimeType.startsWith('image/')) {
          state.images.push(adFile);
        } else {
          state.documents.push(adFile);
        }
      });
    });
    builder.addMatcher(api.endpoints.getV1AccountPrintAdvertById.matchFulfilled, (state, { payload }) => {
      state.images = payload.image
        ? [{ id: payload.image.id.toString(), name: payload.image.filename, uploaded: payload.image }]
        : [];
      state.previewContext = payload.eInseratContext;
      state.preview = payload.preview;
      state.price = payload.priceSummary;
      state.bookedIssueDates = payload.bookedIssues;
      state.selectedIssueDates = payload.selectedIssues;
    });
    builder.addMatcher(api.endpoints.putV1AccountPrintAdvertById.matchFulfilled, (state, { payload }) => {
      state.previewContext = payload.eInseratContext;
      state.preview = payload.preview;
      state.price = payload.priceSummary;
    });
    builder.addMatcher(api.endpoints.postV1AccountPrintAdvert.matchFulfilled, (state, { payload }) => {
      state.previewContext = payload.eInseratContext;
      state.images = payload.image
        ? [{ id: payload.image.id.toString(), name: payload.image.filename, uploaded: payload.image }]
        : [];
      state.preview = payload.preview;
      state.price = payload.priceSummary;
    });
    builder.addMatcher(api.endpoints.postV1AccountPrintAdvertPreview.matchFulfilled, (state, { payload }) => {
      if (!state.previewContext) {
        state.previewContext = payload.eInseratContext;
      }
      if (!state.images.length && payload.image) {
        state.images = [{ id: payload.image.id.toString(), name: payload.image.filename, uploaded: payload.image }];
      }
      state.preview = payload.preview;
      state.price = payload.priceSummary;
    });
  },
});

export default adSlice.reducer;

export const adActions = adSlice.actions;
