import { forwardRef, SystemStyleObject, Tag, TagProps, useStyleConfig } from '@chakra-ui/react';
import { SelectableSystemStyleObject, SelectableTagComponentName } from '../theme';

export interface SelectableTagProps extends TagProps {
  disabled?: boolean;
  isSelected?: boolean;
  _selected?: SystemStyleObject;
}

const SelectableTag = forwardRef<SelectableTagProps, typeof Tag>(({ isSelected, ...rest }, ref) => {
  let styles = useStyleConfig(SelectableTagComponentName, rest) as SelectableSystemStyleObject;
  if (isSelected) {
    styles = { ...styles, ...styles._selected };
  }
  return <Tag ref={ref} sx={styles} {...rest} />;
});

export default SelectableTag;
