import { FC, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { AuthenticationTemplate } from '@/authentication';
import { PageTitle } from '@/components/global';
import { Page, paths } from '@/config';
import { useAccounts } from '@/hooks';

type PageRouteProps = { page: Page };

const PageRoute: FC<PageRouteProps> = ({ page }) => {
  const { pathname } = useLocation();

  const { isNewUser } = useAccounts();
  const Component = page.component;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <PageTitle title={page.title} />
      {page.restricted ? (
        <AuthenticationTemplate>
          {page.requiresAccount && isNewUser ? <Navigate to={paths.ACCOUNT} /> : <Component />}
        </AuthenticationTemplate>
      ) : (
        <Component />
      )}
    </>
  );
};

export default PageRoute;
