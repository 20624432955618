import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { CardProps, ChakraProps, Flex, IconButton, Image, Tooltip } from '@chakra-ui/react';
import { DeleteIcon, DragHandleIcon, EditIcon } from '@chakra-ui/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FileInputClickBox, SelectableCard } from '@netiva/classifieds-ui';

import { AdFile } from '@/store/ad/types';

export interface ThumbnailProps extends CardProps {
  image: AdFile;
  onRemove: (e: React.MouseEvent) => void;
  onReplace: (e: React.MouseEvent) => void;
  allowReorder?: boolean;
  maxImageSize?: ChakraProps['maxWidth'];
}

export const Thumbnail: FunctionComponent<ThumbnailProps> = ({
  image,
  onRemove,
  onReplace,
  allowReorder,
  maxImageSize,
  ...rest
}) => {
  const { t } = useTranslation();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: image.id });

  if (!image) return null;

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <SelectableCard
      ref={setNodeRef}
      padding={0}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      shadow="around"
      cursor="default"
      _hover={{ bgColor: 'surface', shadow: 'heavy' }}
      sx={style}
      {...rest}
    >
      <Image
        borderTopRadius="default"
        objectFit="scale-down"
        width="100%"
        maxWidth={maxImageSize}
        maxHeight={maxImageSize}
        src={
          image.uploaded
            ? `data:${image.uploaded.contentType};base64, ${image.uploaded.base64Data}`
            : image.existing?.url
        }
        alt={t('ad.steps.files.images.label')}
      />
      <Flex justify="center" borderBottomRadius="default" bgColor="surface">
        <Tooltip label={t('ad.steps.files.images.replace')}>
          <FileInputClickBox>
            <IconButton
              icon={<EditIcon />}
              variant="unstyled"
              onClick={onReplace}
              aria-label={t('ad.steps.files.images.replace')}
            />
          </FileInputClickBox>
        </Tooltip>
        <Tooltip label={t('ad.steps.files.images.remove')}>
          <IconButton
            icon={<DeleteIcon />}
            variant="unstyled"
            onClick={onRemove}
            aria-label={t('ad.steps.files.images.remove')}
          />
        </Tooltip>
        {allowReorder && (
          <Tooltip label={t('ad.steps.files.images.move')}>
            <IconButton
              icon={<DragHandleIcon />}
              variant="unstyled"
              {...attributes}
              {...listeners}
              cursor="grab"
              aria-label={t('ad.steps.files.images.move')}
            />
          </Tooltip>
        )}
      </Flex>
    </SelectableCard>
  );
};
