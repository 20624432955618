import * as generatedApi from './api.generated';
export * from './api.generated';

export const globalTag = 'Global' as const;
export const listId = 'LIST' as const;

const getListId = <TagType extends string = never>(type: TagType): { type: TagType; id: string | number } => {
  return { type, id: listId };
};

function provideListTags<TagType extends string = never, ResultType = object>(
  result: ResultType | undefined,
  type: TagType,
  idMap: (result: ResultType) => (string | number)[]
) {
  if (result) {
    return [...idMap(result).map((id) => ({ type, id })), getListId(type), globalTag];
  }
  return [getListId(type), globalTag];
}

function provideTag<TagType extends string = never>(type: TagType, id: string | number | undefined) {
  return [{ type, id }, globalTag];
}

// add the 'Global' tag to all get* endpoints so we can invalidate all queries
// this is a workaround for the resetApiState() method that is broken in the current version of RTK Query
// call dispatch(api.util.invalidateTags(['Global'])) to reset the state of all queries
// ATTENTION: when overriding an endpoint below, the global tag has to be added to the providesTags list!
const enhancedEndpoints: any = {};
for (const name in generatedApi.api.endpoints) {
  if (Object.prototype.hasOwnProperty.call(generatedApi.api.endpoints, name) && name.startsWith('get')) {
    enhancedEndpoints[name] = {
      providesTags: [globalTag],
    };
  }
}
const api = generatedApi.api.enhanceEndpoints({
  addTagTypes: [globalTag],
  endpoints: enhancedEndpoints,
});

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: ['Account', 'Category', 'DataObject', 'Favorite', 'PageContent'],
  endpoints: {
    // public endpoints
    getV1Category: {
      providesTags: (result) => provideListTags(result, 'Category', (res) => res.categories.map((c) => c.id)),
    },
    getV1DataObject: {
      providesTags: (result) => provideListTags(result, 'DataObject', (res) => res.items.map((d) => d.id)),
    },
    getV1DataObjectById: {
      providesTags: (result) => provideTag('DataObject', result?.id),
    },
    getV1Favorite: {
      providesTags: (result) => provideListTags(result, 'Favorite', (res) => res.dataObjectIds),
    },
    deleteV1Favorite: {
      invalidatesTags: [getListId('Favorite')],
    },
    postV1Favorite: {
      invalidatesTags: [getListId('Favorite')],
    },

    // account endpoints
    getV1AccountAccount: {
      providesTags: (result) => provideListTags(result, 'Account', (res) => res.accounts.map((a) => a.id)),
    },
    getV1AccountAccountById: {
      providesTags: (result) => provideTag('Account', result?.account.accountId),
    },
    postV1AccountAccount: {
      invalidatesTags: [getListId('Account')],
    },
    putV1AccountAccountById: {
      invalidatesTags: (result) => [getListId('Account'), { type: 'Account', id: result?.account.accountId }],
    },
    getV1AccountDataObject: {
      providesTags: (result) => provideListTags(result, 'DataObject', (res) => res.items.map((d) => d.id)),
    },
    getV1AccountDataObjectById: {
      providesTags: (result) => provideTag('DataObject', result?.dataObject.id),
      // always refetch details
      keepUnusedDataFor: 0,
    },
    getV1AccountPrintAdvertById: {
      providesTags: (result) => provideTag('DataObject', result?.dataObject.id),
      // always refetch details
      keepUnusedDataFor: 0,
    },
    postV1AccountDataObject: {
      invalidatesTags: [getListId('DataObject')],
    },
    postV1AccountPrintAdvert: {
      invalidatesTags: [getListId('DataObject')],
    },
    putV1AccountDataObjectById: {
      invalidatesTags: (result) => [getListId('DataObject'), { type: 'DataObject', id: result?.dataObject.id }],
    },
    putV1AccountPrintAdvertById: {
      invalidatesTags: (result) => [getListId('DataObject'), { type: 'DataObject', id: result?.dataObject.id }],
    },
    getV1AccountPublicationOptions: {
      keepUnusedDataFor: 0,
    },
    postV1AccountPublicationDataObjectById: {
      invalidatesTags: [getListId('DataObject')],
    },
    putV1AccountDataObjectByIdActivate: {
      invalidatesTags: (_, __, req) => [getListId('DataObject'), { type: 'DataObject', id: req.id }],
    },
    putV1AccountDataObjectByIdDeactivate: {
      invalidatesTags: (_, __, req) => [getListId('DataObject'), { type: 'DataObject', id: req.id }],
    },
    deleteV1AccountDataObjectById: {
      invalidatesTags: [getListId('DataObject')],
    },
    postV1AccountDataObjectByIdDuplicate: {
      invalidatesTags: [getListId('DataObject')],
    },
  },
});

export default enhancedApi;
