import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Box, chakra, Flex, Heading } from '@chakra-ui/react';
import _isEqual from 'lodash/isEqual';
import { accountApi, AccountPrintAdvertPreviewPrintAdvertRequest } from '@netiva/classifieds-api';
import { useDebounce, usePrevious } from '@netiva/classifieds-common';
import { Loader } from '@netiva/classifieds-ui';

import { useAppSelector } from '@/store';
import { useAdParams, useAdSteps } from '../../../hooks';
import { getFormDataValues } from '../../../utils';

export const EInseratPreview: FC = () => {
  const { t } = useTranslation();

  const { currentStep } = useAdSteps();
  const { dataObjectId } = useAdParams();
  const { formData, images, preview, previewContext } = useAppSelector((state) => state.ad);
  const [errorMessage, setErrorMessage] = useState('');
  const [getPreview, { isLoading }] = accountApi.useGetPrintAdvertPreview();

  const requestBody = useMemo(() => {
    if (Object.keys(formData).length && currentStep?.showPreview) {
      const result: AccountPrintAdvertPreviewPrintAdvertRequest = {
        dataObjectId,
        values: getFormDataValues(formData),
        eInseratContext: previewContext,
      };
      if (images[0]?.uploaded) {
        result.image = images[0].uploaded;
      }
      return result;
    }
    return null;
  }, [currentStep?.showPreview, dataObjectId, formData, images, previewContext]);

  const debouncedRequestBody = useDebounce(requestBody, 1000);
  const prevDebouncedRequestBody = usePrevious(debouncedRequestBody, debouncedRequestBody);

  // if ad form data entry or image is updated, fetch the preview
  useEffect(() => {
    // perform deep comparison to prevent unnecessary API calls
    if (debouncedRequestBody && !_isEqual(debouncedRequestBody, prevDebouncedRequestBody)) {
      setErrorMessage('');
      getPreview({ accountPrintAdvertPreviewPrintAdvertRequest: debouncedRequestBody })
        .unwrap()
        .catch((error) => {
          setErrorMessage(t('ad.preview.error'));
          console.log(error.message);
        });
    }
  }, [debouncedRequestBody, getPreview, prevDebouncedRequestBody, t]);

  if (errorMessage !== '') {
    return <Alert status="warning">{errorMessage}</Alert>;
  }

  return (
    <Flex flexDir="column" align="flex-start" marginBottom={4}>
      <Heading as="h3" size="sm">
        {t('preview.title')}
      </Heading>
      <Box position="relative" minW="100px" minH="100px">
        <Loader isLoading={isLoading} size="lg" />
        {preview && (
          <chakra.img src={preview.dataUri} alt={t('preview.title')} maxWidth="100%" boxShadow="default" mt={2} />
        )}
      </Box>
    </Flex>
  );
};
