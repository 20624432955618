import { FunctionComponent } from 'react';
import { Select } from '@chakra-ui/react';

import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { AdAttributeItems } from '@/store/ad/types';

export type SelectionInputProps = {
  id: number;
  name: string;
  placeholder?: string;
  options: AdAttributeItems[];
};

export const SelectionInput: FunctionComponent<SelectionInputProps> = ({ id, name, placeholder, options }) => {
  const dispatch = useAppDispatch();
  const { formData } = useAppSelector((state) => state.ad);

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(adActions.updateFormData({ key: id, entries: e.target.value ? [parseInt(e.target.value)] : [] }));
  };

  return (
    <Select name={name} onChange={handleOnChange} defaultValue={formData[id]?.entries?.[0]?.toString() || ''}>
      <option value="">{placeholder}</option>
      {options.map((option, index) => (
        <option key={index} value={option.id}>
          {option.name}
        </option>
      ))}
    </Select>
  );
};
