import { FC } from 'react';
import { AdAttribute, AdStepItem } from '@/store/ad/types';

import { StepComponents } from '../constants';
import { AttributeFormControl } from './AttributeFormControl';

export type StepComponentProps = {
  stepItem: AdStepItem;
  attribute?: AdAttribute;
};

export const StepComponent: FC<StepComponentProps> = ({ stepItem, attribute }) => {
  if (stepItem.type === 'Attribute' && attribute && attribute.editable) {
    return <AttributeFormControl stepItem={stepItem} attribute={attribute} />;
  }

  if (stepItem.type === 'Component' && stepItem.componentName) {
    const Component = StepComponents[stepItem.componentName];
    if (!Component) {
      console.warn(`Component ${stepItem.componentName} not found.`);
      return null;
    }
    return <Component attribute={attribute} />;
  }

  return null;
};
