import { FunctionComponent } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { SelectableCard } from '@netiva/classifieds-ui';

import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { AdAttributeItems } from '@/store/ad/types';
import { AdComponentProps } from '../types';

export const ColumnCountSelector: FunctionComponent<AdComponentProps> = ({ attribute }) => {
  const dispatch = useAppDispatch();
  const { formData } = useAppSelector((state) => state.ad);

  const handleClick = (option: AdAttributeItems) => {
    if (attribute) {
      dispatch(adActions.updateFormData({ key: attribute.id, entries: [option.id] }));
    }
  };

  return (
    <Flex justify="space-between" gap={4} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
      {attribute &&
        attribute.items.map((option) => (
          <SelectableCard
            key={option.id}
            onClick={() => handleClick(option)}
            isSelected={formData[attribute.id]?.entries?.[0] === option.id}
            display="flex"
            flexDir="column"
            alignItems="center"
            width={{ base: 'full', md: `${32 * parseInt(option.key || '1')}%` }}
          >
            <img src={`/assets/images/column-width-${option.key}.png`} alt={option.name || ''} />
            <Text fontWeight="bold" mt={4}>
              {option.name}
            </Text>
          </SelectableCard>
        ))}
    </Flex>
  );
};
