import { FC, memo } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, AlertIcon, AlertTitle, Button, Text } from '@chakra-ui/react';

import { DefaultLayout as Layout } from '@/components/layout';
import { paths } from '@/config';
import { useAppSelector } from '@/store';
import { useAuth } from '@/hooks';

export const LogoutPage: FC = memo(() => {
  const isLocked = useAppSelector((state) => state.auth.isLocked);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();

  const handleClick = () => {
    navigate(paths.ACCOUNT);
  };

  if (isAuthenticated) {
    return <Navigate to={paths.ACCOUNT} />;
  }

  return (
    <Layout>
      {isLocked ? (
        <Alert status="error" marginBottom={25}>
          <AlertIcon />
          <AlertTitle dangerouslySetInnerHTML={{ __html: t('logout.lockedMessage') }} />
        </Alert>
      ) : (
        <>
          <Text marginBottom={10}>{t('logout.message')}</Text>
          <Text marginBottom={10}>
            <Button onClick={handleClick}>{t('logout.login')}</Button>
          </Text>
        </>
      )}
      <Text marginBottom={10}>
        <a href={t('logout.publicPortalLinkUrl')}>
          <Button>{t('logout.publicPortalLinkTitle')}</Button>
        </a>
      </Text>
    </Layout>
  );
});
