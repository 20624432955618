import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@chakra-ui/react';

import { accountApi, AddressRec } from '@netiva/classifieds-api';

import { useAppDispatch, useAppSelector } from '@/store';
import { accountActions } from '@/store/account';
import { SaveAccount } from '@/store/account/types';
import { createFormData } from './utils';

export type UseAccountFormProps = {
  onCancel: () => void;
};

export const useAccountForm = ({ onCancel }: UseAccountFormProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const toast = useToast();

  const { accountAddress, accountId, formData, isFormValid, isLoading, isNewUser } = useAppSelector(
    (state) => state.account
  );

  const [createAccount, { isLoading: isCreatingAccount }] = accountApi.useCreateAccount();
  const [updateAccount, { isLoading: isUpdatingAccount }] = accountApi.useUpdateAccount();
  const { data: loginInfo, isLoading: isLoadingLoginInfo } = accountApi.useGetLoginInfo(
    {},
    {
      skip: isLoading || !isNewUser || !!accountAddress,
    }
  );

  // prefill values for new users with data from the identity provider
  useEffect(() => {
    if (!isLoadingLoginInfo && loginInfo && !isLoading && !accountAddress) {
      dispatch(
        accountActions.setFormData({
          firstname: loginInfo.firstName,
          lastname: loginInfo.lastName,
          street: loginInfo.streetAddress,
          city: loginInfo.city,
          zip: loginInfo.zipCode,
          countryIso2: loginInfo.countryCode,
          email: loginInfo.email,
        })
      );
    }
  }, [accountAddress, dispatch, isLoading, isLoadingLoginInfo, loginInfo]);

  useEffect(() => {
    if (accountAddress) {
      const formData = createFormData(accountAddress);
      dispatch(accountActions.setFormData(formData));
    }
  }, [accountAddress, dispatch]);

  const showErrorMessage = useCallback(() => {
    toast({ status: 'error', description: t('ad.error.missingFields'), isClosable: true });
  }, [t, toast]);

  const showSuccessMessage = useCallback(() => {
    toast({ status: 'success', description: t('account.update.success') });
  }, [t, toast]);

  const saveAccount: SaveAccount = useCallback(() => {
    if (!isFormValid) {
      showErrorMessage();
      return false;
    }

    isNewUser && dispatch(accountActions.setIsNewUser(false));

    if (accountId) {
      updateAccount({
        id: accountId,
        accountAccountUpdateAccountRequest: {
          account: {
            accountId: accountId,
            address: formData as AddressRec,
          },
        },
      })
        .unwrap()
        .then(() => {
          showSuccessMessage();
          onCancel();
        });
    } else {
      createAccount({
        accountAccountCreateAccountRequest: {
          address: formData as AddressRec,
        },
      })
        .unwrap()
        .then((response) => {
          dispatch(accountActions.setAccount(response.account));
          showSuccessMessage();
          onCancel();
        });
    }

    return true;
  }, [
    accountId,
    createAccount,
    dispatch,
    formData,
    isFormValid,
    isNewUser,
    onCancel,
    showErrorMessage,
    showSuccessMessage,
    updateAccount,
  ]);

  return {
    formData,
    saveAccount,
    isFormValid,
    isNewUser,
    isLoading: isLoading || isCreatingAccount || isUpdatingAccount,
  };
};
