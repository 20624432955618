import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuButton, MenuItem, MenuList, SelectProps } from '@chakra-ui/react';

import { SupportedLanguages } from '@/environment';
import { ChevronDownIcon } from '@chakra-ui/icons';

export const LanguageSwitcher: FC<SelectProps> = (props) => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  if (SupportedLanguages.length < 2) {
    return null;
  }

  return (
    <Menu>
      <MenuButton as={Button} variant="outline" size="sm" rightIcon={<ChevronDownIcon />}>
        {t('languages.select')}
      </MenuButton>
      <MenuList>
        {SupportedLanguages.map((lng) => (
          <MenuItem key={lng} onClick={() => handleLanguageChange(lng)}>
            {t(`languages.${lng}`)}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
