import { FC, ReactNode } from 'react';
import { Box, BoxProps, chakra, useMultiStyleConfig } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { UsePaginationResult } from '@netiva/classifieds-common';

import { PaginationComponentName } from '../theme';

export type LinkRenderer = (page: number, children: ReactNode) => ReactNode;

export type PaginationProps = BoxProps &
  UsePaginationResult & {
    renderLink: LinkRenderer;
    spacer?: ReactNode;
  };

const Item = chakra(Box);

export const Pagination: FC<PaginationProps> = ({
  currentPage,
  endPage,
  pages,
  renderLink,
  startPage,
  totalPages,
  spacer: spacerOverride,
  ...rest
}) => {
  const styles = useMultiStyleConfig(PaginationComponentName, { ...rest });

  if (totalPages < 2) return null;

  const spacer = spacerOverride || <>...</>;

  return (
    <Box sx={styles.container} {...rest}>
      {currentPage > 1 &&
        renderLink(
          currentPage - 1,
          <Item sx={styles.icon}>
            <ChevronLeftIcon />
          </Item>
        )}

      {startPage > 1 && renderLink(1, <Item sx={styles.item}>{1}</Item>)}

      {startPage > 2 && spacer}

      {pages.map((page, i) =>
        renderLink(
          page,
          <Item key={i} sx={currentPage === page ? styles.itemActive : styles.item}>
            {page}
          </Item>
        )
      )}

      {endPage < totalPages - 1 && spacer}

      {endPage < totalPages && renderLink(totalPages, <Item sx={styles.item}>{totalPages}</Item>)}

      {currentPage < totalPages &&
        renderLink(
          currentPage + 1,
          <Item sx={styles.icon}>
            <ChevronRightIcon />
          </Item>
        )}
    </Box>
  );
};
