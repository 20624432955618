import { FC, memo, useEffect } from 'react';

import { Loader } from '@netiva/classifieds-ui';

import { DefaultLayout as Layout } from '@/components/layout';
import { useAuth } from '@/hooks';

export const LoginPage: FC = memo(() => {
  const { login } = useAuth();

  useEffect(() => {
    // login();
  }, [login]);

  return (
    <Layout>
      <Loader isLoading />
    </Layout>
  );
});
