import { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export type PageTitleProps = {
  title?: string;
  applicationTitle?: string;
  separator?: string;
};

export const PageTitle: FC<PageTitleProps> = ({
  title,
  applicationTitle = 'application.meta.titlepostfix',
  separator = '|',
}) => {
  const { t } = useTranslation();
  const pageTitle = useMemo(
    () =>
      `${t(title || 'page.defaultTitle')} ${separator || '|'} ${t(
        applicationTitle || 'application.meta.titlepostfix'
      )}`,
    [applicationTitle, separator, t, title]
  );
  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  );
};
