import { createContext, FC, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToast } from '@chakra-ui/react';

import { accountApi, AccountSharedDataObjectRec } from '@netiva/classifieds-api';

import { paths } from '@/config';
import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { AdFormData, AdStep, AdValidationStatus, BulkUpdateAdFormData } from '@/store/ad/types';

import { PopulateAdFormData } from '../types';
import { useAdNavigation } from '../hooks/useAdNavigation';
import { useAdParams } from '../hooks/useAdParams';
import { useAdSteps } from '../hooks/useAdSteps';
import { useAdValidator } from '../hooks/useAdValidator';
import { useAdPublishing } from '../hooks/useAdPublishing';
import { getFilesRequestParams, getFormDataValues } from '../utils';

export type AdContextType = {
  steps: AdStep[];
  validationStatus: AdValidationStatus;
  categoryId?: number;
  formData: AdFormData;
  selectedIssueDates: string[];
  bookedIssueDates: string[];
  currentStep?: AdStep;
  currentStepIndex: number;
  currentStepKey?: string;
  gotoNextStep: (skipValidation?: boolean) => void;
  gotoPrevStep: () => void;
  gotoStep: (stepKey: string, skipValidation?: boolean) => void;
  save: () => Promise<void>;
  saveAndPublish: () => Promise<void>;
  isNew: boolean;
  isValid: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  hasChanges: boolean;
  canSave: boolean;
  dataObject?: AccountSharedDataObjectRec;
};

const noop = () => undefined;
const noopP = () => Promise.resolve();

export const AdContext = createContext<AdContextType>({
  bookedIssueDates: [],
  canSave: false,
  currentStepIndex: -1,
  formData: {},
  gotoNextStep: noop,
  gotoPrevStep: noop,
  gotoStep: noop,
  hasChanges: false,
  isLoading: false,
  isNew: true,
  isSubmitting: false,
  isValid: false,
  save: noopP,
  saveAndPublish: noopP,
  selectedIssueDates: [],
  steps: [],
  validationStatus: {},
});

export type AdProviderProps = PropsWithChildren;

export const AdProvider: FC<AdProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  const {
    bookedIssueDates,
    categoryId,
    dataObjectType,
    platform,
    documents,
    formData,
    images,
    previewContext,
    selectedIssueDates,
    validationStatus,
    hasChanges,
  } = useAppSelector((state) => state.ad);

  // get request params
  const { currentStepKey, dataObjectId, isNew } = useAdParams();

  const { currentStep, currentStepIndex, isLoading: isLoadingSteps, steps } = useAdSteps();
  const isValid = useMemo(() => !!validationStatus[currentStepKey], [currentStepKey, validationStatus]);

  const getValidationStatus = useAdValidator();
  const validate = () => {
    const validationStatus = getValidationStatus();
    for (let i = 0; i < steps.length; i++) {
      if (!validationStatus[steps[i].key]) {
        toast({ status: 'error', description: t('ad.submit.validationError'), duration: 5000, isClosable: true });
        return false;
      }
    }
    return true;
  };

  // reset data on mount
  useEffect(() => {
    dispatch(adActions.resetData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch data
  const isPrintAdvert = dataObjectType === 4;
  const { data: dataObjectData, isLoading: isLoadingDataObject } = accountApi.useGetDataObjectById(
    { id: dataObjectId! },
    { skip: !dataObjectId || isPrintAdvert }
  );
  const { data: printAdvertData, isLoading: isLoadingPrintAdvert } = accountApi.useGetPrintAdvertById(
    { id: dataObjectId! },
    { skip: !dataObjectId || !isPrintAdvert }
  );
  const dataObject = useMemo(
    () => (isPrintAdvert ? printAdvertData?.dataObject : dataObjectData?.dataObject),
    [dataObjectData?.dataObject, printAdvertData?.dataObject, isPrintAdvert]
  );
  const isLoading = isLoadingDataObject || isLoadingPrintAdvert || isLoadingSteps;

  const [createPrintAdvert, { isLoading: isCreatingPrintAdvert }] = accountApi.useCreatePrintAdvert();
  const [updatePrintAdvert, { isLoading: isUpdatingPrintAdvert }] = accountApi.useUpdatePrintAdvert();
  const [createDataObject, { isLoading: isCreatingDataObject }] = accountApi.useCreateDataObject();
  const [updateDataObject, { isLoading: isUpdatingDataObject }] = accountApi.useUpdateDataObject();
  const [publishDataObject, { isLoading: isPublishing }] = accountApi.usePublishDataObject();
  const isSubmitting =
    isCreatingDataObject || isUpdatingDataObject || isCreatingPrintAdvert || isUpdatingPrintAdvert || isPublishing;

  const { gotoNextStep, gotoPrevStep, gotoStep } = useAdNavigation();

  // on component mount
  useEffect(() => {
    if (isNew) {
      // ensure data object type and category has been selected
      if (!platform || !dataObjectType) {
        gotoStep('platform', true);
      } else if (!categoryId) {
        gotoStep('category', true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, dataObjectType, platform]);

  // on ad attribute steps set, create steps object
  useEffect(() => {
    // for existing ads...
    if (!isNew) {
      // mark all steps as completed
      dispatch(
        adActions.updateValidationStatus(
          steps.reduce((completedSteps, adStep) => {
            return {
              ...completedSteps,
              [adStep.key]: true,
            };
          }, {})
        )
      );
    }
  }, [dispatch, dataObjectId, isNew, steps]);

  /** updates ad form data object in a bulk (for existing ads, on initial ad load) */
  const bulkUpdateFormData: BulkUpdateAdFormData = useCallback(
    (values) => {
      values && dispatch(adActions.bulkUpdateFormData(values));
    },
    [dispatch]
  );

  /** populates ad form data on initial ad fetch */
  const populateAdFormData: PopulateAdFormData = useCallback(
    (values) => {
      bulkUpdateFormData(
        values.reduce((values: AdFormData, value) => {
          return {
            ...values,
            [value.attributeId]: {
              value: value.value,
              entries: value.entries,
            },
          } as AdFormData;
        }, {})
      );
    },
    [bulkUpdateFormData]
  );

  // call prepopulate function on data object fetch
  useEffect(() => {
    if (dataObject) {
      dispatch(adActions.setPlatform(dataObject.platform));
      dispatch(adActions.setDataObjectType(dataObject.type));
      dispatch(adActions.setCategoryId(dataObject.categoryId));
      dispatch(adActions.setStatus(dataObject.status));
      populateAdFormData(dataObject.values);
    }
  }, [dataObject, dispatch, populateAdFormData]);

  /** submits ad data at the last step */
  const { selectedContractId, selectedProductId, selectedExtensionProductIds } = useAdPublishing();

  const saveDataObject = async () => {
    const values = getFormDataValues(formData);
    const files = getFilesRequestParams(images, documents);

    const savePromise = isNew
      ? createDataObject({
          accountDataObjectCreateDataObjectRequest: {
            categoryId: categoryId!,
            type: dataObjectType!,
            values,
            files,
          },
        })
      : updateDataObject({
          id: dataObjectId,
          accountDataObjectUpdateDataObjectRequest: {
            id: dataObjectId,
            values,
            files,
          },
        });

    return await savePromise.unwrap();
  };

  const saveAndPublishDataObject = async () => {
    const saveResponse = await saveDataObject();

    return publishDataObject({
      id: saveResponse.dataObject.id,
      accountPublicationPublishDataObjectRequest: {
        dataObjectId: saveResponse.dataObject.id,
        contractId: selectedContractId,
        productId: selectedProductId,
        extensionProductIds: selectedExtensionProductIds,
      },
    }).unwrap();
  };

  const saveAndPublishPrintAdvert = () => {
    const values = getFormDataValues(formData);
    const image = images[0]?.uploaded;

    const promise = isNew
      ? createPrintAdvert({
          accountPrintAdvertCreatePrintAdvertRequest: {
            categoryId: categoryId!,
            type: dataObjectType!,
            selectedIssues: selectedIssueDates,
            eInseratContext: previewContext,
            values,
            image,
          },
        })
      : updatePrintAdvert({
          id: dataObjectId,
          accountPrintAdvertUpdatePrintAdvertRequest: {
            id: dataObjectId!,
            eInseratContext: previewContext,
            selectedIssues: selectedIssueDates,
            values,
            image,
          },
        });

    return promise.unwrap();
  };

  const save = () => {
    return saveDataObject()
      .then(() => {
        toast({ status: 'success', description: t('ad.save.success') });
        navigate(paths.MYADS, { replace: true });
      })
      .catch(() => {
        toast({ status: 'error', description: t('ad.save.error'), duration: null, isClosable: true });
      });
  };

  const saveAndPublish = () => {
    if (!validate()) {
      return Promise.resolve();
    }

    const submit = dataObjectType === 4 ? saveAndPublishPrintAdvert : saveAndPublishDataObject;

    return submit()
      .then(() => {
        toast({ status: 'success', description: t('ad.submit.success') });
        navigate(paths.MYADS, { replace: true });
      })
      .catch(() => {
        toast({ status: 'error', description: t('ad.submit.error'), duration: null, isClosable: true });
      });
  };

  return (
    <AdContext.Provider
      value={{
        steps,
        validationStatus,
        categoryId,
        formData,
        selectedIssueDates,
        bookedIssueDates,
        currentStep,
        currentStepIndex,
        currentStepKey,
        gotoNextStep,
        gotoPrevStep,
        gotoStep,
        save,
        saveAndPublish,
        isNew,
        isValid,
        isLoading,
        isSubmitting,
        hasChanges,
        canSave: !!categoryId && !!dataObjectType,
        dataObject,
      }}
    >
      {children}
    </AdContext.Provider>
  );
};
