import { FC, Fragment, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Wrap } from '@chakra-ui/react';
import { accountApi } from '@netiva/classifieds-api';
import { flattenTree } from '@netiva/classifieds-common';
import { Loader, SelectableTag } from '@netiva/classifieds-ui';

import { useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { AdCategory } from '@/store/ad/types';
import { AdComponentProps } from '../types';

export const CategorySelector: FC<AdComponentProps> = () => {
  const dispatch = useDispatch();
  const { categoryId } = useAppSelector((state) => state.ad);

  const { data: categoriesData, isLoading } = accountApi.useGetCategories({ recursive: true });
  const topCategories = useMemo(() => categoriesData?.categories || [], [categoriesData?.categories]);
  const allCategories = useMemo(() => flattenTree(topCategories, (c) => c.children), [topCategories]);

  const categoryPath = useMemo(() => {
    const result: AdCategory[] = [];

    let id: number | null | undefined = categoryId;
    do {
      const tmpId: number | null | undefined = id;
      const category: AdCategory | undefined = allCategories.find((c) => c.id === tmpId);

      if (category) {
        result.push(category);
      }
      id = category?.parentId;
    } while (id);
    return result.reverse();
  }, [allCategories, categoryId]);

  const selectCategory = useCallback(
    (category: AdCategory) => {
      dispatch(adActions.setCategoryId(category.id));
    },
    [dispatch]
  );

  const renderCategoryTag = useCallback(
    (category: AdCategory) => {
      const isSelectedParent = categoryPath.slice(0, -1).some((c) => c.id === category.id);
      return (
        <SelectableTag
          key={category.id}
          fontSize="md"
          fontWeight="bold"
          onClick={() => selectCategory(category)}
          isSelected={category.id === categoryId || isSelectedParent}
          colorScheme={isSelectedParent ? 'gray' : undefined}
        >
          {category.name}
        </SelectableTag>
      );
    },
    [categoryId, categoryPath, selectCategory]
  );

  return (
    <>
      <Wrap spacing={3}>
        <Loader isLoading={isLoading} />
        {topCategories.map(renderCategoryTag)}
      </Wrap>
      {categoryPath.map(
        (parent) =>
          parent.children && (
            <Fragment key={parent.id}>
              <Divider my={4} />
              <Wrap spacing={3}>{parent.children.map(renderCategoryTag)}</Wrap>
            </Fragment>
          )
      )}
    </>
  );
};
