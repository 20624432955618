import React, { FunctionComponent } from 'react';
import { Input, InputProps } from '@chakra-ui/react';

import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { AdAttribute } from '@/store/ad/types';

export type TextInputProps = {
  attribute: AdAttribute;
};

export const TextInput: FunctionComponent<TextInputProps> = ({ attribute }) => {
  const dispatch = useAppDispatch();
  const { formData } = useAppSelector((state) => state.ad);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(adActions.updateFormData({ key: attribute.id, value: e.target.value }));
  };

  const additionalProps: Partial<InputProps> = {};

  if (attribute.type === 'Decimal') {
    additionalProps.type = 'number';
    additionalProps.step = 0.01;
  } else if (attribute.type === 'Integer') {
    additionalProps.type = 'number';
    additionalProps.step = 1;
  }

  return (
    <Input
      name={attribute.name || attribute.id.toString()}
      value={formData[attribute.id]?.value || ''}
      maxLength={attribute.maxLength || undefined}
      onChange={handleOnChange}
      {...additionalProps}
    />
  );
};
