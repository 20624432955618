import { AnyAction, AsyncThunkAction, createListenerMiddleware, isRejected } from '@reduxjs/toolkit';
import type { ErrorResult } from './api.generated';
import { dispatchError } from './config';

export const QUERY_ERROR = 'QUERY_ERROR';

export type QueryErrorPayload = {
  status: number | 'FETCH_ERROR';
  data?: ErrorResult;
  isHandled?: boolean;
};
type QueryAction = AsyncThunkAction<any, any, never> & {
  payload: QueryErrorPayload;
};
type RejectedQueryAction = ReturnType<QueryAction>;

const isRejectedQuery = (action: AnyAction): action is RejectedQueryAction => {
  if (isRejected(action) && action.payload) {
    const payload = action.payload as Partial<QueryErrorPayload>;
    return (payload.status || 0) >= 400 || payload.status === 'FETCH_ERROR';
  }
  return false;
};

const queryErrorMiddleware = createListenerMiddleware();
queryErrorMiddleware.startListening({
  matcher: isRejectedQuery,
  effect: (action: QueryAction, api) => {
    dispatchError(action.payload, api.dispatch);
  },
});

export default queryErrorMiddleware.middleware;
