import { FC, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CardBody, CardHeader, chakra, Flex, Heading, Image } from '@chakra-ui/react';
import { accountApi, AccountConfigurationPlatformListResponseItem } from '@netiva/classifieds-api';
import { Loader, SelectableCard } from '@netiva/classifieds-ui';

import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { useAdNavigation } from '../hooks';

const Container = chakra(Flex, {
  baseStyle: {
    gap: 8,
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
});

export const PlatformSelector: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { platform, dataObjectType } = useAppSelector((state) => state.ad);
  const { gotoNextStep } = useAdNavigation();
  const isHidden = useRef(true);

  const { data: platformsData, isLoading: isLoadingPlatforms } = accountApi.useGetConfigurationPlatforms({});
  const platforms = useMemo(() => platformsData?.items || [], [platformsData]);
  const selectedPlatform = useMemo(() => platforms.find((p) => p.name === platform), [platform, platforms]);

  useEffect(() => {
    isHidden.current = true;
    let goNext = false;

    if (!isLoadingPlatforms && platforms.length === 1) {
      dispatch(adActions.setPlatform(platforms[0].name));

      if (platforms[0].dataObjectTypes.length === 1) {
        dispatch(adActions.setDataObjectType(platforms[0].dataObjectTypes[0].id));
        goNext = true;
      }
    }

    if (goNext) {
      gotoNextStep(true);
    } else {
      isHidden.current = false;
    }
  }, [dispatch, gotoNextStep, isLoadingPlatforms, platforms]);

  const handlePlatformSelect = (platform: AccountConfigurationPlatformListResponseItem) => {
    dispatch(adActions.setPlatform(platform.name));
    if (platform.dataObjectTypes.length === 1) {
      dispatch(adActions.setDataObjectType(platform.dataObjectTypes[0].id));
    }
  };
  const handleTypeSelect = (type: number) => {
    dispatch(adActions.setDataObjectType(type));
  };

  if (isHidden.current) {
    return null;
  }

  return (
    <>
      <Loader isLoading={isLoadingPlatforms} />
      <Container>
        {platforms.map((p) => (
          <SelectableCard
            key={p.name}
            onClick={() => handlePlatformSelect(p)}
            isSelected={p.name === platform}
            width={{ base: 'full', lg: '20%' }}
          >
            <CardHeader>
              <Heading as="h3" size="sm" textAlign="center">
                {t(`ad.steps.platform.platforms.${p.name.toLowerCase()}`)}
              </Heading>
            </CardHeader>
            <CardBody>
              <Flex justifyContent="center" alignItems="center">
                <Image
                  src={`/assets/platforms/${p.name.toLowerCase()}/icon.svg`}
                  width="16"
                  height="16"
                  fallback={<></>}
                />
              </Flex>
            </CardBody>
          </SelectableCard>
        ))}
      </Container>
      <Container>
        {selectedPlatform &&
          selectedPlatform.dataObjectTypes.length > 1 &&
          selectedPlatform.dataObjectTypes.map((type) => (
            <SelectableCard
              key={type.id}
              onClick={() => handleTypeSelect(type.id)}
              isSelected={type.id === dataObjectType}
              width={{ base: 'full', lg: '20%' }}
            >
              {t(`ad.steps.platform.types.${type.type.toLowerCase()}`)}
            </SelectableCard>
          ))}
      </Container>
    </>
  );
};
