import { FC } from 'react';
import { chakra, HTMLChakraProps, ThemingProps, useStyleConfig } from '@chakra-ui/react';

import { DescriptionListTermComponentName } from '../../theme';

export type DescriptionListTermProps = HTMLChakraProps<'dt'> & ThemingProps<'DescriptionListTerm'>;

export const DescriptionListTerm: FC<DescriptionListTermProps> = (props) => {
  const style = useStyleConfig(DescriptionListTermComponentName, props);
  return <chakra.dt __css={style} {...props} />;
};
