import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, chakra, Divider, Flex, Image, List, ListIcon, ListItem, Text } from '@chakra-ui/react';
import { DescriptionList, DescriptionListItem, DescriptionListTerm, Loader } from '@netiva/classifieds-ui';
import { HiOutlineDocumentText } from 'react-icons/hi';

import { useAppSelector } from '@/store';
import { AttributeDisplay } from './AttributeDisplay';
import { useAdAttributes, useAdPublishing } from '../hooks';
import { useCurrencyFormatter } from '@/hooks';

const Section = chakra(Box, {
  baseStyle: {
    marginBottom: 4,
  },
});

const SectionTitle = chakra(Text, {
  baseStyle: {
    fontSize: 'md',
    fontWeight: 'bold',
    marginBottom: 4,
  },
});

export const Review: FC = () => {
  const { t } = useTranslation();
  const formatter = useCurrencyFormatter();

  const { formData, images, documents } = useAppSelector((state) => state.ad);
  const { attributes, isLoading: isLoadingAttributes } = useAdAttributes();
  const { activePublication, selectedContract, selectedProduct, selectedExtensionProducts, requiresPublication } =
    useAdPublishing();

  return (
    <>
      <Section>
        <SectionTitle>{t('ad.steps.review.details')}</SectionTitle>
        <DescriptionList position="relative">
          <Loader isLoading={isLoadingAttributes} />
          {attributes.map((attribute) => {
            const value = formData[attribute.id];
            return value && <AttributeDisplay key={attribute.id} attribute={attribute} value={value} />;
          })}
        </DescriptionList>
      </Section>
      <Divider my={4} orientation="horizontal" />
      {!!images.length && (
        <>
          <Section>
            <SectionTitle>{t('ad.steps.review.images')}</SectionTitle>
            <Flex gap={4} wrap="wrap">
              {images.map((img) => {
                const src = img.uploaded
                  ? `data:${img.uploaded.contentType};base64, ${img.uploaded.base64Data}`
                  : img.existing?.url;
                return (
                  <Image
                    src={src || img.uploaded?.base64Data}
                    alt={img.name}
                    maxWidth={['40%', '120px']}
                    maxHeight="80px"
                  />
                );
              })}
            </Flex>
          </Section>
          <Divider my={4} orientation="horizontal" />
        </>
      )}
      {!!documents.length && (
        <>
          <Section>
            <SectionTitle>{t('ad.steps.review.documents')}</SectionTitle>
          </Section>
          <List>
            {documents.map((doc) => (
              <ListItem>
                <ListIcon as={HiOutlineDocumentText} />
                {doc.name}
              </ListItem>
            ))}
          </List>
          <Divider my={4} orientation="horizontal" />
        </>
      )}
      <Section>
        <SectionTitle>
          {requiresPublication ? t('ad.steps.review.publishing') : t('ad.steps.publishing.currentPublication')}
        </SectionTitle>
        <DescriptionList>
          <DescriptionListTerm>{t('ad.steps.review.product')}</DescriptionListTerm>
          {activePublication ? (
            <>
              {<DescriptionListItem>{activePublication.contract.product.name}</DescriptionListItem>}
              {activePublication.contract.activeExtensions.map((ext) => (
                <DescriptionListItem>{ext.product.name}</DescriptionListItem>
              ))}
            </>
          ) : (
            selectedProduct && (
              <>
                {selectedProduct.name}
                {!selectedContract && <> - {formatter.format(selectedProduct.price)}</>}
              </>
            )
          )}
          {selectedContract && (
            <DescriptionListItem>
              {selectedContract.product.name} ({selectedContract.quantity - selectedContract.available} /{' '}
              {selectedContract.quantity})
            </DescriptionListItem>
          )}
          {selectedExtensionProducts.map((ep) => (
            <DescriptionListItem key={ep.id}>
              {ep.name} - ({formatter.format(ep.price)})
            </DescriptionListItem>
          ))}
        </DescriptionList>
      </Section>
    </>
  );
};
