import type { AdStep } from '@/store/ad/types';
import {
  CategorySelector,
  ColumnCountSelector,
  DocumentSelector,
  EInseratReview,
  ImageSelector,
  IssueDateSelector,
  LocationTypeahead,
  PlatformSelector,
  Publishing,
  Review,
  StyleSelector,
} from './components';
import { AdComponentType } from './types';

export const CommonSteps: AdStep[] = [
  {
    key: 'platform',
    hidden: true,
    titleResourceKey: 'ad.steps.platform.title',
    introResourceKey: 'ad.steps.platform.description',
    showPreview: false,
    showPriceSummary: false,
    showPriceSummaryTotal: false,
    items: [
      {
        type: 'Component',
        componentName: 'PlatformSelector',
        sortOrder: 1,
      },
    ],
  },
  {
    key: 'category',
    hidden: true,
    titleResourceKey: 'ad.steps.category.title',
    showPreview: false,
    showPriceSummary: false,
    showPriceSummaryTotal: false,
    items: [
      {
        type: 'Component',
        componentName: 'CategorySelector',
        sortOrder: 1,
      },
    ],
  },
];

export const StepComponents: Record<string, AdComponentType> = {
  CategorySelector,
  ColumnCountSelector,
  DocumentSelector,
  EInseratReview,
  IssueDateSelector,
  PlatformSelector,
  Publishing,
  Review,
  StyleSelector,
  SingleImageSelector: (props) => <ImageSelector {...props} />,
  MultiImageSelector: (props) => <ImageSelector multiple {...props} />,
  LocationTypeahead,
};
