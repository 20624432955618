import { ReactNode, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@chakra-ui/react';
import { parseQueryValue, usePaginationCore, UsePaginationProps } from '@netiva/classifieds-common';
import { useQueryParams } from './useQueryParams';

export const usePagination = ({ totalPages, maxPages }: Omit<UsePaginationProps, 'currentPage'>) => {
  const queryParams = useQueryParams();
  const currentPage = parseQueryValue(queryParams.get('p'), parseInt) || 1;

  const paginationResult = usePaginationCore({ currentPage, totalPages, maxPages });

  const renderLink = useCallback((page: number, children: ReactNode) => {
    return (
      <Link as={RouterLink} key={page} to={`?p=${page}`}>
        {children}
      </Link>
    );
  }, []);

  return {
    ...paginationResult,
    renderLink,
  };
};
