import { Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { FC } from 'react';

export type ActionButtonProps = {
  label: string;
  icon?: React.ReactElement;
  iconType?: React.ElementType;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const ActionButton: FC<ActionButtonProps> = ({ icon, iconType, label, onClick }) => {
  return (
    <Tooltip label={label} hasArrow placement="top">
      <IconButton
        icon={iconType ? <Icon as={iconType} boxSize={4} /> : icon}
        aria-label={label}
        variant="ghost"
        colorScheme="neutral"
        onClick={onClick}
      />
    </Tooltip>
  );
};
