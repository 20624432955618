import { EventType, InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import jwt_decode from 'jwt-decode';

import { AuthConfig, ApiScopes, ResetPasswordUrl } from './config';
import { CustomNavigationClient } from './CustomNavigationClient';

const ErrorCodes = {
  PASSWORD_FORGOTTEN: 'AADB2C90118',
};

const navigationClient = new CustomNavigationClient();
let msalInstance: PublicClientApplication;

export const getInstance = () => {
  if (!msalInstance) {
    msalInstance = new PublicClientApplication(AuthConfig);
    msalInstance.setNavigationClient(navigationClient);

    msalInstance.addEventCallback((event) => {
      if (
        event.eventType === EventType.LOGIN_FAILURE &&
        event.error?.message.includes(ErrorCodes.PASSWORD_FORGOTTEN) &&
        ResetPasswordUrl
      ) {
        // we need to disable navigation because MSAL's internal error handling will run after this
        // and will redirect to the login page again if the sign-up/sign-in flow does not have self-service password reset enabled
        // (which is the case for tierwelt, which has a custom password reset page)
        navigationClient.disableNavigation();
        window.location.href = ResetPasswordUrl;
      }
    });
  }

  return msalInstance;
};

export const getAccessToken = async () => {
  const msalInstance = getInstance();
  await msalInstance.handleRedirectPromise();
  const account = msalInstance.getAllAccounts()[0];
  if (!account) {
    console.error('no account');
    return null;
  }
  const accessTokenRequest = {
    scopes: ApiScopes,
    account: account,
  };
  return msalInstance
    .acquireTokenSilent(accessTokenRequest)
    .then(function (accessTokenResponse) {
      return accessTokenResponse.accessToken;
    })
    .catch(function (error) {
      console.warn(error);

      if (error instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect(accessTokenRequest).catch(function (innerError) {
          console.error(innerError);
          // if we encounter a second error this probably means that the auth cache is in an invalid state,
          // i.e. the handleRedirectPromise() function didn't run on a previous request,
          // so we clean the msal cache and try again.
          msalInstance['browserStorage'].clear();
          msalInstance.acquireTokenRedirect(accessTokenRequest);
        });
      }
      return null;
    });
};

type DecodedAccessToken = {
  emails: string[];
};

export const getDecodedAccessToken = async () => {
  const token = await getAccessToken();
  if (!token) {
    return null;
  }
  return jwt_decode<DecodedAccessToken>(token as string);
};
