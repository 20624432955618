import { FC, useEffect, useRef } from 'react';
import { $getRoot, TextNode } from 'lexical';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import type { HtmlConverter } from './converters';

type HtmlValuePluginProps = {
  converter: HtmlConverter;
  value?: string;
  onChange?: (newValue: string) => void;
};

// Bug in lexical: it expects a <u> element when parsing HTML in $generateNodesFromDOM
// but returns a <span> when generating HTML in $generateHtmlFromNodes
// see: https://github.com/facebook/lexical/issues/2452
const IS_UNDERLINE = 1 << 3;
const defaultExportDOM = TextNode.prototype.exportDOM;
TextNode.prototype.exportDOM = function (editor) {
  if (this.__format & IS_UNDERLINE) {
    const dom = document.createElement('u');
    dom.textContent = this.__text;
    const textTheme = editor._config.theme.text;
    if (textTheme) {
      dom.className = [textTheme.base, textTheme.underline].join(' ');
    }
    return { element: dom };
  }
  return defaultExportDOM.apply(this, [editor]);
};

export const HtmlValuePlugin: FC<HtmlValuePluginProps> = ({ converter, value, onChange }) => {
  const htmlRef = useRef('');
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const removeUpdateListener = editor.registerUpdateListener(() => {
      editor.update(() => {
        let html = $generateHtmlFromNodes(editor, null);
        html = converter ? converter.convertFromEditorHtml(html) : html;
        if (htmlRef.current !== html) {
          htmlRef.current = html;
          onChange?.(html);
        }
      });
    });
    return () => {
      removeUpdateListener();
    };
  }, [converter, editor, onChange]);

  useEffect(() => {
    if (value !== htmlRef.current && value) {
      editor.update(() => {
        const convertedValue = converter ? converter.convertToEditorHtml(value) : value;
        const parser = new DOMParser();
        const dom = parser.parseFromString(convertedValue, 'text/html');
        const nodes = $generateNodesFromDOM(editor, dom);

        const root = $getRoot();
        root.clear();
        root.append(...nodes);
      });
    }
  }, [converter, editor, value]);

  return null;
};
