import { FC, memo } from 'react';
import { Navigate } from 'react-router-dom';
import { Loader } from '@netiva/classifieds-ui';

import { AuthMode } from '@/authentication';
import { paths } from '@/config';
import { useAuth } from '@/hooks';

export const AuthPage: FC = memo(() => {
  const { isAuthenticated, isLoading } = useAuth();

  if (AuthMode === 'oauth') {
    if (isAuthenticated && !isLoading) {
      // TODO handle return URL
      return <Navigate to={paths.ACCOUNT} />;
    }
  }

  // MSAL will handle the auth request and redirect accordingly.

  // Render an empty page during authentication.
  return <Loader isLoading />;
});
