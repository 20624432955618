import { TAuthConfig } from 'react-oauth2-code-pkce';

const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
const authorizeEndpoint = process.env.REACT_APP_OAUTH_AUTH_ENDPOINT;
const tokenEndpoint = process.env.REACT_APP_OAUTH_TOKEN_ENDPOINT;

export const AuthConfig: TAuthConfig = {
  clientId: clientId!,
  authorizationEndpoint: authorizeEndpoint!,
  tokenEndpoint: tokenEndpoint!,
  redirectUri: window.location.origin + '/auth/callback',
  autoLogin: false,
};
