import { MsalProvider } from '@azure/msal-react';
import { FC, PropsWithChildren } from 'react';
import { AuthProvider } from 'react-oauth2-code-pkce';

import { AuthMode as AuthModeEnv } from '@/environment';
import { getInstance } from './msal';
import { AuthConfig as OAuthConfig } from './oauth';

type AuthenticationMode = 'msal' | 'oauth';

export const AuthMode = AuthModeEnv as AuthenticationMode;

export const AuthenticationProvider: FC<PropsWithChildren> = ({ children }) => {
  switch (AuthMode) {
    case 'msal':
      return <MsalProvider instance={getInstance()}>{children}</MsalProvider>;
    case 'oauth':
      return <AuthProvider authConfig={OAuthConfig}>{children}</AuthProvider>;
    default:
      return null;
  }
};
