import { ThunkDispatch } from 'redux-thunk';

import api, { globalTag } from './api';
export * from './api';
export { api };
export { initialize as initApi } from './config';
export { default as queryErrorMiddleware, QUERY_ERROR } from './queryErrorMiddleware';
export type { QueryErrorPayload } from './queryErrorMiddleware';

export const waitForAllOperations = async (dispatch: ThunkDispatch<any, any, any>) => {
  await Promise.all(dispatch(api.util.getRunningQueriesThunk()));
};

export const resetApiState = () => {
  // TODO resetApiState is currently bugged in RTK Query
  // dispatch(api.util.resetApiState());
  // in its stead we invalidate the 'Global' tag that should be set on all queries, which has the same effect
  return api.util.invalidateTags([globalTag]);
};

// re-export api hooks with clearer names
export const publicApi = {
  getCategories: api.endpoints.getV1Category,
  useGetCategories: api.useGetV1CategoryQuery,
  getDataObjects: api.endpoints.getV1DataObject,
  useGetDataObjects: api.useGetV1DataObjectQuery,
  useGetDataObjectsLazy: api.useLazyGetV1DataObjectQuery,
  getDataObjectById: api.endpoints.getV1DataObjectById,
  useGetDataObjectById: api.useGetV1DataObjectByIdQuery,
  getAttribute: api.endpoints.getV1Attribute,
  useGetAttribute: api.useGetV1AttributeQuery,
  getGeoLocations: api.endpoints.getV1GeoLocations,
  useGetGeoLocations: api.useGetV1GeoLocationsQuery,
  getGeoRegions: api.endpoints.getV1GeoRegions,
  useGetGeoRegions: api.useGetV1GeoRegionsQuery,
  postDataObjectByIdContact: api.endpoints.postV1DataObjectByIdContact,
  usePostDataObjectByIdContact: api.usePostV1DataObjectByIdContactMutation,
  useGetFavorites: api.useGetV1FavoriteQuery,
  useSaveFavorites: api.usePostV1FavoriteMutation,
  useDeleteFavorites: api.useDeleteV1FavoriteMutation,
  getPageContent: api.endpoints.getV1PageContent,
  useGetPageContent: api.useGetV1PageContentQuery,
};

export const accountApi = {
  useGetAccounts: api.useGetV1AccountAccountQuery,
  useCreateAccount: api.usePostV1AccountAccountMutation,
  useGetAccountById: api.useGetV1AccountAccountByIdQuery,
  useUpdateAccount: api.usePutV1AccountAccountByIdMutation,
  useGetLoginInfo: api.useGetV1AccountAccountLoginInfoQuery,
  useGetAccountAttributes: api.useGetV1AccountAttributeQuery,
  useGetCategories: api.useGetV1AccountCategoryQuery,
  useGetDataObjects: api.useGetV1AccountDataObjectQuery,
  useCreateDataObject: api.usePostV1AccountDataObjectMutation,
  useGetDataObjectById: api.useGetV1AccountDataObjectByIdQuery,
  useUpdateDataObject: api.usePutV1AccountDataObjectByIdMutation,
  useGetPrintAdvertIssueDates: api.useGetV1AccountPrintAdvertIssueDatesQuery,
  useGetPrintAdvertById: api.useGetV1AccountPrintAdvertByIdQuery,
  useUpdatePrintAdvert: api.usePutV1AccountPrintAdvertByIdMutation,
  useGetPrintAdvertPreview: api.usePostV1AccountPrintAdvertPreviewMutation,
  useCreatePrintAdvert: api.usePostV1AccountPrintAdvertMutation,
  useGetConfigurationProcess: api.useGetV1AccountConfigurationProcessQuery,
  useGetConfigurationPlatforms: api.useGetV1AccountConfigurationPlatformsQuery,
  useGetConfigurationDataObjectTypes: api.useGetV1AccountConfigurationDataObjectTypesQuery,
  useGetProducts: api.useGetV1AccountProductsQuery,
  useGetContracts: api.useGetV1AccountContractsQuery,
  useGetPublications: api.useGetV1AccountPublicationDataObjectByIdQuery,
  useGetPublicationOptions: api.useGetV1AccountPublicationOptionsQuery,
  usePublishDataObject: api.usePostV1AccountPublicationDataObjectByIdMutation,
  useActivateDataObject: api.usePutV1AccountDataObjectByIdActivateMutation,
  useDeactivateDataObject: api.usePutV1AccountDataObjectByIdDeactivateMutation,
  useDeleteDataObject: api.useDeleteV1AccountDataObjectByIdMutation,
  useDuplicateDataObject: api.usePostV1AccountDataObjectByIdDuplicateMutation,
  useSaveLogo: api.usePostV1AccountLogoMutation,
  useDeleteLogo: api.useDeleteV1AccountLogoMutation,
  useGetLogins: api.useGetV1AccountLoginsQuery,
  useAddLogin: api.usePostV1AccountLoginsMutation,
  useRemoveLogin: api.useDeleteV1AccountLoginsByIdMutation,
  useSetLoginRole: api.usePutV1AccountLoginsSetRoleMutation,
  useGetAccountInvitations: api.useGetV1AccountLoginsInvitationsQuery,
  useAcceptAccountInvitation: api.usePutV1AccountLoginsAcceptInvitationMutation,
};
