export const getAcceptLanguage = (lng: string) => {
  const acceptLanguages = [lng];
  switch (lng) {
    case 'de':
    case 'fr':
    case 'it':
      acceptLanguages.push(`${lng}-CH`);
      break;
    case 'en':
      acceptLanguages.push(`${lng}-US`);
      acceptLanguages.push(`${lng}-GB`);
      break;
  }
  return acceptLanguages.join(',');
};
