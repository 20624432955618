import { defineStyleConfig } from '@chakra-ui/react';
import { SelectableSystemStyleObject } from '../types';

export const SelectableCardComponentName = 'SelectableCard' as const;

export const SelectableCardStyle = defineStyleConfig({
  baseStyle: ({ colorScheme }) =>
    ({
      bgColor: 'surface',
      borderRadius: 'default',
      padding: 4,
      cursor: 'pointer',
      transition: '0.5s ease',
      shadow: 'default',
      _hover: {
        bgColor: `surfaceHover`,
        shadow: 'heavy',
      },
      _selected: {
        bgColor: `${colorScheme}.500`,
        _hover: {
          bgColor: `${colorScheme}.300`,
        },
      },
    } as SelectableSystemStyleObject),
});
