import { ForwardedRef, ReactNode, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  Box,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  List,
  ListItem,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { forwardRefGeneric, useOutsideClick } from '@netiva/classifieds-common';

import { Loader } from './Loader';
import { TypeaheadComponentName } from '../theme';

type ItemSelector<TItem> = (item: TItem) => void;
type ItemRenderer<TItem> = (item: TItem, onSelect: ItemSelector<TItem>, isHovered: boolean, index: number) => ReactNode;

export interface TypeaheadProps<TItem> extends InputProps {
  isLoading?: boolean;
  items: TItem[];
  onItemSelected: ItemSelector<TItem>;
  renderItem?: ItemRenderer<TItem>;
}

function TypeaheadInner<TItem>(
  { isLoading, items, onItemSelected, renderItem, children, ...rest }: TypeaheadProps<TItem>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const styles = useMultiStyleConfig(TypeaheadComponentName, { input: rest });

  const [hoveredIndex, setHoveredIndex] = useState<number>();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  useEffect(() => {
    setHoveredIndex(undefined);
  }, [items]);

  const inputRef = useRef<HTMLInputElement | null>(null);
  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => inputRef.current);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideClick(wrapperRef, () => {
    setIsDropdownVisible(false);
  });

  const itemRenderer: ItemRenderer<TItem> =
    renderItem ||
    ((item, onSelect, isHovered, index) => {
      return (
        <ListItem key={index} onClick={() => onSelect(item)} backgroundColor={isHovered ? 'Highlight' : 'Background'}>
          {`${item}`}
        </ListItem>
      );
    });

  const handleItemSelected = (item: TItem) => {
    onItemSelected(item);
    inputRef.current?.focus();
    setIsDropdownVisible(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    setIsDropdownVisible(true);
    if (e.code === 'ArrowUp') {
      setHoveredIndex((val) => {
        const len = items.length;
        return typeof val === 'undefined' ? items.length - 1 : (((val - 1) % len) + len) % len;
      });
      e.preventDefault();
    }
    if (e.code === 'ArrowDown') {
      setHoveredIndex((val) => {
        return typeof val === 'undefined' ? 0 : (val + 1) % items.length;
      });
      e.preventDefault();
    }
    if (e.code === 'Enter') {
      if (typeof hoveredIndex !== 'undefined') {
        handleItemSelected(items[hoveredIndex]);
      }
      e.preventDefault();
    }
  };

  const handleBlur = (e: React.FocusEvent) => {
    if (wrapperRef.current && wrapperRef.current.contains(e.relatedTarget)) {
      return;
    }

    setIsDropdownVisible(false);
  };

  return (
    <Box ref={wrapperRef} position="relative">
      <InputGroup>
        <Input
          sx={styles.input}
          ref={inputRef}
          type="text"
          autoComplete="off"
          onFocus={() => setIsDropdownVisible(true)}
          onClick={() => setIsDropdownVisible(true)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          {...rest}
        />
        {children}
        <InputRightElement>
          <Loader color="neutral" isLoading={isLoading || false} />
        </InputRightElement>
      </InputGroup>
      {isDropdownVisible && (
        <List sx={styles.dropdown} tabIndex={-1}>
          {items.map((item, index) => itemRenderer(item, handleItemSelected, index === hoveredIndex, index))}
        </List>
      )}
    </Box>
  );
}

export const Typeahead = forwardRefGeneric(TypeaheadInner);
