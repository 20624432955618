export const getQueryValue = (value: string | string[] | undefined, defaultValue = ''): string | undefined => {
  if (value) {
    if (typeof value === 'string') {
      return value;
    }
    if (value.length) {
      return value[0];
    }
  }

  return defaultValue;
};

export const parseQueryValue = <T>(
  value: string | string[] | null | undefined,
  parser: (value: string) => T
): T | undefined => {
  if (value) {
    if (typeof value === 'string') {
      return parser(value);
    }
    if (value.length) {
      return parser(value[0]);
    }
  }

  return undefined;
};

export const parseQueryValues = <T>(value: string | string[] | null | undefined, parser: (value: string) => T): T[] => {
  if (value) {
    if (typeof value === 'string') {
      return [parser(value)];
    }
    return value.map((item) => parser(item));
  }

  return [];
};
