import { FC } from 'react';
import { Flex, FormControl, FormLabel, Input, Select } from '@chakra-ui/react';
import { DataObjectStatus } from '@netiva/classifieds-api';
import { useTranslation } from 'react-i18next';

export interface FilterProps {
  filter?: string;
  onFilterChanged: (newValue: string) => void;
  status?: DataObjectStatus;
  onStatusChanged: (newValue: DataObjectStatus) => void;
}

export const Filter: FC<FilterProps> = ({ filter, onFilterChanged, status, onStatusChanged }) => {
  const { t } = useTranslation();

  return (
    <Flex gap={4} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
      <FormControl>
        <FormLabel>{t('myads.filter.text')}</FormLabel>
        <Input type="text" value={filter} onChange={(e) => onFilterChanged(e.target.value)} />
      </FormControl>
      <FormControl>
        <FormLabel>{t('myads.filter.status')}</FormLabel>
        <Select value={status} onChange={(e) => onStatusChanged((e.target.value as DataObjectStatus) || undefined)}>
          <option value="">{t('myads.filter.status.all')}</option>
          <option value={'Active' as DataObjectStatus}>{t('ad.status.active')}</option>
          <option value={'Draft' as DataObjectStatus}>{t('ad.status.draft')}</option>
          <option value={'Disabled' as DataObjectStatus}>{t('ad.status.disabled')}</option>
          <option value={'Expired' as DataObjectStatus}>{t('ad.status.expired')}</option>
          <option value={'Approved' as DataObjectStatus}>{t('ad.status.approved')}</option>
          <option value={'ToApprove' as DataObjectStatus}>{t('ad.status.toapprove')}</option>
        </Select>
      </FormControl>
    </Flex>
  );
};
