import { FunctionComponent } from 'react';

import { AdAttribute } from '@/store/ad/types';

import { CheckboxInput } from './CheckboxInput';
import { HtmlInput } from './HtmlInput';
import { SelectionInput } from './SelectionInput';
import { TextInput } from './TextInput';

export type AttributeInputProps = {
  attribute: AdAttribute;
};

export const AttributeInput: FunctionComponent<AttributeInputProps> = ({ attribute }) => {
  if (!attribute.name || !attribute.editable) {
    return null;
  }
  if (attribute.type === 'SelectSingle' || attribute.type === 'SelectSingleSearchMulti')
    return attribute.items ? (
      <SelectionInput id={attribute.id} name={attribute.name} options={attribute.items} />
    ) : null;
  if (attribute.type === 'HTML') return attribute.name ? <HtmlInput id={attribute.id} /> : null;
  if (attribute.type === 'Boolean') return <CheckboxInput id={attribute.id} name={attribute.name} />;
  return <TextInput attribute={attribute} />;
};
