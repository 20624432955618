import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import { LayoutContainer } from '@netiva/classifieds-ui';

import type { LayoutProps } from './types';

export type AdvertiseLayoutProps = LayoutProps & {
  isNew: boolean;
  onClose: VoidFunction;
};

export const AdvertiseLayout: FC<AdvertiseLayoutProps> = memo(({ isNew, title, onClose, children }) => {
  const { t } = useTranslation();
  return (
    <Box bgColor="surface" minHeight="100vh">
      <LayoutContainer padding={[0, 4]} py={[0, 14]}>
        <Flex
          justify="space-between"
          align="center"
          bgColor="dark"
          boxShadow="default"
          px={[4, 6, 8]}
          py={4}
          color="neutral.50"
        >
          <Heading as="h1" size="md" color="white">
            {title || t(isNew ? 'ad.create.title' : 'ad.edit.title')}
          </Heading>
          <Button variant="outline" size="sm" onClick={onClose}>
            {t('ui.buttons.close')}
          </Button>
        </Flex>

        <Box bgColor="white" boxShadow="default" padding={[4, 6, 8]}>
          {children}
        </Box>
      </LayoutContainer>
    </Box>
  );
});
