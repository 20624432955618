import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@chakra-ui/react';

import { Loader } from '@netiva/classifieds-ui';

export type SubmitButtonProps = {
  isSubmitting: boolean;
  onSubmit: () => void;
};

export const SubmitButton: FunctionComponent<SubmitButtonProps> = ({ isSubmitting, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Box position="relative">
      <Loader isLoading={isSubmitting} size="md" />
      <Button onClick={onSubmit} disabled={isSubmitting}>
        {t('ad.steps.review.submit')}
      </Button>
    </Box>
  );
};
