import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';

import { useAdAttributes } from './useAdAttributes';
import { useAdSteps } from './useAdSteps';

export const useAdValidator = () => {
  const dispatch = useAppDispatch();
  const { attributes } = useAdAttributes();
  const {
    categoryId,
    dataObjectType,
    status,
    formData,
    selectedIssueDates,
    validationStatus,
    selectedProductId,
    selectedContractId,
    selectedExtensionProductIds,
  } = useAppSelector((state) => state.ad);
  const { steps } = useAdSteps();

  const validate = useCallback(() => {
    const newValidationStatus = { ...validationStatus };
    steps.forEach((step) => {
      let valid = true;

      step.items?.forEach((item) => {
        // check for the attributes in the current step
        if (item.type === 'Attribute' && attributes) {
          const attribute = attributes.find((attribute) => attribute.id === item.attributeId);
          if (attribute && attribute.mandatory) {
            if (!formData[attribute.id]) valid = false;
            if (!formData[attribute.id]?.value && !formData[attribute.id]?.entries?.length) valid = false;
          }
        }
        // check for values on components
        if (item.type === 'Component') {
          switch (item.componentName) {
            case 'IssueDateSelector':
              // check print issues
              if (selectedIssueDates.length === 0) {
                valid = false;
              }
              break;
            case 'CategorySelector':
              if (!categoryId) {
                valid = false;
              }
              break;
            case 'DataObjectTypeSelector':
              if (!dataObjectType) {
                valid = false;
              }
              break;
            case 'Publishing':
              if (status !== 'Active' && status !== 'ToApprove') {
                valid = !!selectedContractId || !!selectedProductId || selectedExtensionProductIds.length > 0;
              }
              break;
          }
        }
      });

      newValidationStatus[step.key] = valid;
    });

    dispatch(adActions.updateValidationStatus(newValidationStatus));

    return newValidationStatus;
  }, [
    attributes,
    categoryId,
    dataObjectType,
    dispatch,
    formData,
    selectedContractId,
    selectedExtensionProductIds.length,
    selectedIssueDates.length,
    selectedProductId,
    status,
    steps,
    validationStatus,
  ]);

  return validate;
};
