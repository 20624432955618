import { createListenerMiddleware } from '@reduxjs/toolkit';
import { resetApiState } from '@netiva/classifieds-api';

import { accountActions } from './account';
import { adActions } from './ad';
import type { RootState } from '.';

// reset API state (clear cache) when state changes of properties that are automatically added to requests occur
// (i.e. X-... headers)
const resetApiStateMiddleware = createListenerMiddleware();

resetApiStateMiddleware.startListening({
  actionCreator: adActions.setPlatform,
  effect: (action, listenerApi) => {
    const prevState = listenerApi.getOriginalState() as RootState;
    if (action.payload !== prevState.ad.platform) {
      listenerApi.dispatch(resetApiState());
    }
  },
});

resetApiStateMiddleware.startListening({
  actionCreator: accountActions.setAccountId,
  effect: async (action, listenerApi) => {
    const prevState = listenerApi.getOriginalState() as RootState;
    if (action.payload !== prevState.account.accountId) {
      listenerApi.dispatch(resetApiState());
    }
  },
});

export { resetApiStateMiddleware };
