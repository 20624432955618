import { FC, useCallback, useEffect, useState } from 'react';
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND, TextFormatType } from 'lexical';
import { ToolbarButton, ToolbarButtonProps } from './ToolbarButton';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

type FormatButtonProps = ToolbarButtonProps & {
  format: TextFormatType;
};

export const FormatButton: FC<FormatButtonProps> = ({ className, format, children, ...rest }) => {
  const [editor] = useLexicalComposerContext();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const removeUpdateListener = editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const hasFormat = selection.hasFormat(format);
          setIsActive(hasFormat);
        }
      });
    });

    return () => {
      removeUpdateListener();
    };
  }, [editor, format]);

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      editor.dispatchCommand(FORMAT_TEXT_COMMAND, format);
    },
    [editor, format]
  );

  return (
    <ToolbarButton className={className} onClick={handleClick} isActive={isActive} {...rest}>
      {children}
    </ToolbarButton>
  );
};
