import { Configuration as MsalConfiguration } from '@azure/msal-browser';

const clientId = process.env.REACT_APP_AZURE_AD_B2C_CLIENT_ID;
const tenantId = process.env.REACT_APP_AZURE_AD_B2C_TENANT_ID;
const authority = process.env.REACT_APP_AZURE_AD_B2C_AUTHORITY;
const scopesAuthority = process.env.REACT_APP_AZURE_AD_B2C_SCOPES_AUTHORITY;
const userFlow = process.env.REACT_APP_AZURE_AD_B2C_USER_FLOW;
const resetPasswordUrl = process.env.REACT_APP_RESET_PASSWORD_URL;

export const AuthConfig: MsalConfiguration = {
  auth: {
    clientId: clientId!,
    authority: `https://${authority}/${tenantId}/${userFlow}`,
    knownAuthorities: [authority!],
    redirectUri: '/auth/callback',
    postLogoutRedirectUri: '/logout',
  },
  cache: {
    cacheLocation: 'localStorage',
    secureCookies: true,
    storeAuthStateInCookie: true,
  },
};

export const ApiScopes = [`https://${scopesAuthority}/classifieds-api/Public.Read`];

export const ResetPasswordUrl = resetPasswordUrl;
