import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

export const HeaderParts = ['container', 'logo'] as const;

export const HeaderComponentName = 'Header' as const;

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(HeaderParts);

const baseStyleContainer = defineStyle({
  bg: 'neutral.800',
  px: { base: 4, md: 4 },
  height: 20,
  alignItems: 'center',
  gap: 4,
  color: 'neutral.50',
  zIndex: 10,
});

const baseStyleLogo = defineStyle({
  display: { base: 'none', md: 'block' },
  bgColor: 'white',
  height: 'full',
  width: { base: 'full', md: '250px', lg: '300px' },
  maxWidth: { base: '30%', md: 'none' },
  marginLeft: -4,
});

const baseStyle = definePartsStyle({
  container: baseStyleContainer,
  logo: baseStyleLogo,
});

export const Header = defineMultiStyleConfig({
  baseStyle,
});
