import { useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { useDebounce } from './useDebounce';

export function useDebouncedState<T>(
  delay: number
): [T | undefined, Dispatch<SetStateAction<T | undefined>>, T | undefined];

export function useDebouncedState<T>(delay: number, initialState: T): [T, Dispatch<SetStateAction<T>>, T];

export function useDebouncedState<T>(
  delay: number,
  initialState?: T | undefined
): [T | undefined, Dispatch<SetStateAction<T | undefined>>, T | undefined] {
  const [value, setValue] = useState(initialState);
  const debouncedValue = useDebounce(value, delay);
  return [value, setValue, debouncedValue];
}
