import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, FormControl, FormLabel, Text, useToast } from '@chakra-ui/react';
import { parseDataURL, readFileAsDataURL } from '@netiva/classifieds-common';
import { FileInput, FileInputClickBox } from '@netiva/classifieds-ui';
import { v4 as uuid } from 'uuid';

import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { EditModal, ImagePanel } from './components';
import { AdComponentProps } from '../../types';
import { AdFile } from '@/store/ad/types';

export type ImageSelectorProps = AdComponentProps & {
  multiple?: boolean;
};

export const ImageSelector: FC<ImageSelectorProps> = ({ multiple }) => {
  const { t } = useTranslation();
  const toast = useToast();

  const dispatch = useAppDispatch();
  const { images } = useAppSelector((state) => state.ad);
  const [uploadedImage, setUploadedImage] = useState<string>();
  const [uploadedImageName, setUploadedImageName] = useState<string>();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [replacingImage, setReplacingImage] = useState<AdFile>();

  const hasImage = images.length > 0;

  const handleSelectFile = async (files: FileList) => {
    const file = files[0];
    const dataUrl = await readFileAsDataURL(file);
    const parsedData = parseDataURL(dataUrl);
    if (parsedData && parsedData.contentType.startsWith('image/')) {
      setUploadedImage(dataUrl);
      setUploadedImageName(file.name);
      setIsEditModalOpen(true);
    } else {
      toast({
        status: 'error',
        title: t('ad.steps.files.images.invalidFile.title'),
        description: t('ad.steps.files.images.invalidFile.description', { name: file.name }),
        duration: 10000,
        isClosable: true,
      });
    }
  };

  const handlEditDone = (imageData: string) => {
    setIsEditModalOpen(false);

    if (!imageData) {
      return;
    }

    const image = {
      id: uuid(),
      name: uploadedImageName || 'unnamed',
      uploaded: {
        id: 0,
        filename: 'ad.png',
        base64Data: imageData.replace(/^data:image\/(png|jpg|jpeg);base64,/, '') || '',
        contentType: 'image/png',
      },
    };
    if (replacingImage || (!multiple && images.length)) {
      dispatch(adActions.replaceImage({ oldImage: replacingImage || images[0], newImage: image }));
      setReplacingImage(undefined);
    } else {
      dispatch(adActions.addImage(image));
    }
    setUploadedImage(undefined);
    setUploadedImageName(undefined);
  };

  const handleRemove = (e: React.MouseEvent, image?: AdFile) => {
    e.preventDefault();
    e.stopPropagation();
    const img = image || images[0];
    img && dispatch(adActions.removeImage(img));
  };
  const handleReplace = (e: React.MouseEvent, image: AdFile) => {
    setReplacingImage(image);
  };

  return (
    <>
      <FormControl>
        <FormLabel>{t('ad.steps.files.images.label', { count: multiple ? 2 : 1 })}</FormLabel>
        <FileInput onFilesSelected={handleSelectFile} accept="image/*">
          {!hasImage && <Text>{t('ad.steps.files.images.drag')}</Text>}
          <ImagePanel
            maxImageSize={multiple ? '150px' : '300px'}
            onRemoveImage={handleRemove}
            onReplaceImage={handleReplace}
            allowReorder={multiple}
            mb={4}
          />
          <Flex wrap="wrap" gap={2}>
            <FileInputClickBox>
              <Button type="button" size="sm" colorScheme="secondary">
                {t(!multiple && hasImage ? 'ad.steps.files.images.replace' : 'ad.steps.files.images.upload')}
              </Button>
            </FileInputClickBox>
          </Flex>
        </FileInput>
      </FormControl>

      <EditModal
        imageData={uploadedImage}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onDone={handlEditDone}
      />
    </>
  );
};
