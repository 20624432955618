import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

export const FileInputComponentName = 'FileInput' as const;
export const FileInputParts = ['container', 'content'] as const;

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(FileInputParts);

const baseStyle = definePartsStyle({
  container: {
    flexDir: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'default',
    borderRadius: 'default',
    minHeight: '100px',
    padding: 4,
    transition: '0.25s',
  },
  content: {
    flexDir: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const FileInputStyle = defineMultiStyleConfig({ baseStyle });
