import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

export const PaginationComponentName = 'Pagination' as const;
export const PaginationParts = ['container', 'icon', 'item', 'itemActive'] as const;

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(PaginationParts);

const baseStyle = definePartsStyle({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    height: '2.5rem',
    width: '2.5rem',
    borderRadius: 'full',
    background: 'primary.500',
    mx: 6,
  },
  item: {
    cursor: 'pointer',
    fontFamily: 'alternate',
    mx: 3,
    borderBottom: 'default',
    borderBottomColor: 'transparent',
  },
  itemActive: {
    cursor: 'pointer',
    fontFamily: 'alternate',
    mx: 3,
    borderBottom: 'default',
    borderBottomColor: 'primary',
  },
});

export const PaginationStyle = defineMultiStyleConfig({
  baseStyle,
});
