import { NavigationClient, NavigationOptions } from '@azure/msal-browser';

/** Extended MSAL navigation client that supports a flag for disabling navigation. */
export class CustomNavigationClient extends NavigationClient {
  private isNavigationDisabled: boolean = false;

  async navigateInternal(url: string, options: NavigationOptions) {
    if (this.isNavigationDisabled) {
      return false;
    }

    return await super.navigateInternal(url, options);
  }

  async navigateExternal(url: string, options: NavigationOptions) {
    if (this.isNavigationDisabled) {
      return false;
    }

    return await super.navigateExternal(url, options);
  }

  disableNavigation() {
    this.isNavigationDisabled = true;
  }

  enableNavigation() {
    this.isNavigationDisabled = false;
  }
}
