import { useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import { de, enUS, frCH, itCH } from 'date-fns/locale';

import { useTranslation } from 'react-i18next';

export const useDate = () => {
  const { i18n } = useTranslation();

  const locale = useMemo(() => {
    switch (i18n.language) {
      case 'de':
        return de;
      case 'fr':
        return frCH;
      case 'it':
        return itCH;
      default:
        return enUS;
    }
  }, [i18n.language]);

  const formatDate = useCallback(
    (dateString: string) => {
      return format(new Date(dateString), 'PPP', { locale });
    },
    [locale]
  );

  return {
    formatDate,
  };
};
