import { FC, useCallback } from 'react';
import { REDO_COMMAND, UNDO_COMMAND } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ButtonGroup } from '@chakra-ui/react';

import { FormatButton } from './FormatButton';
import { ToolbarButton } from './ToolbarButton';

export type ToolbarTexts = {
  bold: string;
  italic: string;
  underline: string;
  undo: string;
  redo: string;
};

type ToolbarProps = {
  texts?: ToolbarTexts;
};

export const Toolbar: FC<ToolbarProps> = ({ texts }) => {
  texts ||= {
    bold: 'Bold',
    italic: 'Italic',
    underline: 'Underline',
    undo: 'Undo',
    redo: 'Redo',
  };

  const [editor] = useLexicalComposerContext();

  const handleUndo = useCallback(() => {
    editor.dispatchCommand(UNDO_COMMAND, undefined);
  }, [editor]);
  const handleRedo = useCallback(() => {
    editor.dispatchCommand(REDO_COMMAND, undefined);
  }, [editor]);

  return (
    <ButtonGroup size="md" borderBottom="default" padding={1} spacing={1}>
      <FormatButton format="bold" title={texts.bold}>
        <span style={{ fontWeight: 'bold' }}>B</span>
      </FormatButton>
      <FormatButton format="italic" title={texts.italic}>
        <span style={{ fontStyle: 'italic' }}>I</span>
      </FormatButton>
      <FormatButton format="underline" title={texts.underline}>
        <span style={{ textDecoration: 'underline' }}>U</span>
      </FormatButton>
      <ToolbarButton type="button" onClick={handleUndo} title={texts.undo}>
        <span>↶</span>
      </ToolbarButton>
      <ToolbarButton type="button" onClick={handleRedo} title={texts.redo}>
        <span>↷</span>
      </ToolbarButton>
    </ButtonGroup>
  );
};
