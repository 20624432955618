import { useCallback, useContext, useEffect } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AuthContext } from 'react-oauth2-code-pkce';

import { AuthMode } from '@/authentication';
import { setAccessToken } from '@/authentication/oauth';
import { paths } from '@/config';

export const useAuth = () => {
  const oauthContext = useContext(AuthContext);
  const msalContext = useMsal();
  const msalIsAuthenticated = useIsAuthenticated();

  useEffect(() => {
    setAccessToken(oauthContext.token);
  }, [oauthContext.token]);

  const login = useCallback(async () => {
    switch (AuthMode) {
      case 'msal':
        await msalContext.instance.loginRedirect();
        break;
      case 'oauth':
        oauthContext.login();
        break;
      default:
        console.error('Invalid authentication mode');
        break;
    }
  }, [msalContext.instance, oauthContext]);

  const logout = useCallback(async () => {
    switch (AuthMode) {
      case 'msal':
        await msalContext.instance.logoutRedirect();
        break;
      case 'oauth':
        // TODO logout doesn't redirect to the logout page at the moment
        oauthContext.logOut();
        window.location.href = paths.LOGOUT;
        break;
      default:
        console.error('Invalid authentication mode');
        break;
    }
  }, [msalContext.instance, oauthContext]);

  const isLoading = msalContext.inProgress !== InteractionStatus.None || oauthContext.loginInProgress;

  return {
    isAuthenticated: msalIsAuthenticated || !!oauthContext.token,
    isLoading,
    login,
    logout,
  };
};
