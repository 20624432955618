import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from './types';

const initialState: AuthState = {
  isAuthorized: window.localStorage.getItem('auth') === 'true',
  isLocked: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    authorizeUser: (state) => {
      state.isAuthorized = true;
    },
    unauthorizeUser: (state) => {
      state.isAuthorized = false;
    },
    setIsLocked: (state, { payload }: PayloadAction<boolean>) => {
      state.isLocked = payload;
    },
  },
});

export default authSlice.reducer;

export const authActions = authSlice.actions;
