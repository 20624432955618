export const parseDataURL = (value: string) => {
  const regex = /^data:(?<contentType>[^;]+);base64,(?<data>.*)$/;
  const result = regex.exec(value);
  if (!result || !result.groups) {
    return null;
  }
  return {
    contentType: result.groups['contentType'],
    data: result.groups['data'],
  };
};

export const readFileAsDataURL = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        resolve(reader.result as string);
      });
      reader.addEventListener('abort', reject);
      reader.addEventListener('error', reject);
      reader.readAsDataURL(file);
    } catch (err) {
      reject(err);
    }
  });
};

export const readFileAsBase64 = async (file: File) => {
  const dataUrl = await readFileAsDataURL(file);
  if (!dataUrl) {
    return null;
  }
  return parseDataURL(dataUrl);
};
