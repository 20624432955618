import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider } from '@chakra-ui/react';
import { DescriptionList, Loader } from '@netiva/classifieds-ui';

import { useAppSelector } from '@/store';
import { Country, Item } from './components';

export type AccountDetailsProps = {
  onEdit: () => void;
};

export const AccountDetails: FunctionComponent<AccountDetailsProps> = ({ onEdit }) => {
  const { t } = useTranslation();
  const { accountAddress, isLoading } = useAppSelector((state) => state.account);

  return (
    <Box maxWidth="500px">
      <Loader isLoading={isLoading} size="lg" showOverlay />

      {accountAddress && (
        <DescriptionList>
          <Item label={t('account.form.name')} value={[accountAddress.firstname, accountAddress.lastname]} />
          <Item label={t('account.form.company')} value={accountAddress.company} />
          <Item label={t('account.form.company2')} value={accountAddress.company2} />
          <Item label={t('account.form.street')} value={[accountAddress.street, accountAddress.streetNo]} />
          <Item label={t('account.form.street2')} value={accountAddress.street2} />
          <Item label={t('account.form.city')} value={[accountAddress.zip, accountAddress.city]} />
          <Item label={t('account.form.email')} value={accountAddress.email} />
          <Item label={t('account.form.phone')} value={accountAddress.phone} />
          <Country label={t('account.form.country')} value={accountAddress.countryIso2} />
        </DescriptionList>
      )}

      <Divider my={4} />

      <Button type="button" size="sm" onClick={onEdit}>
        {t('ui.buttons.edit')}
      </Button>
    </Box>
  );
};
