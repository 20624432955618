import { ThemeClasses } from '../constants';
import { HtmlConverter } from './types';

export const eInseratConverter: HtmlConverter = {
  convertFromEditorHtml: (value) => {
    const parser = new DOMParser();
    const root = parser.parseFromString(value || '', 'text/html');
    convertFromEditorNode(root.body);
    const result = root.body.innerHTML;
    // console.table({ method: 'convertFromEditorHtml', html, result });
    return result;
  },
  convertToEditorHtml: (value) => {
    const parser = new DOMParser();
    const root = parser.parseFromString(value || '', 'text/html');
    convertToEditorNode(root.body);
    const result = root.body.innerHTML;
    // console.table({ method: 'convertToEditorHtml', html, result });
    return result;
  },
};

const convertToEditorNode = (node: ChildNode) => {
  if (node.hasChildNodes()) {
    node.childNodes.forEach(convertToEditorNode);
  }

  const nodeName = node.nodeName.toLowerCase();

  switch (nodeName) {
    case 'body': {
      if (node.childNodes.length) {
        const childNodes = Array.from(node.childNodes);
        // wrap the child nodes in a paragraph
        if (!childNodes.map((cn) => cn.nodeName.toLowerCase()).some((cn) => cn === 'p' || cn === 'div')) {
          const rootParagraph = document.createElement('p');
          rootParagraph.classList.add(ThemeClasses.paragraph);
          rootParagraph.append(...node.childNodes);
          const newBody = document.createElement('body');
          newBody.appendChild(rootParagraph);
          node.replaceWith(newBody);
        }
      }
      break;
    }
    case 'div': {
      const paragraph = document.createElement('p');
      paragraph.classList.add(ThemeClasses.paragraph);
      paragraph.append(...node.childNodes);
      node.replaceWith(paragraph);
      break;
    }
    case '#text': {
      const span = document.createElement('span');
      span.textContent = node.textContent;
      span.classList.add(ThemeClasses.text.base);
      node.replaceWith(span);
      break;
    }
    case 'b':
    case 'i':
    case 'u': {
      let tag = 'span';
      const classes: string[] = [ThemeClasses.text.base];

      switch (nodeName) {
        case 'b':
          tag = 'strong';
          classes.push(ThemeClasses.text.bold);
          break;
        case 'i':
          tag = 'em';
          classes.push(ThemeClasses.text.italic);
          break;
        case 'u':
          tag = 'u';
          classes.push(ThemeClasses.text.underline);
          break;
      }

      getChildNodeClasses(node, classes);

      const element = document.createElement(tag);
      element.textContent = node.textContent;
      element.classList.add(...classes);
      node.replaceWith(element);
      break;
    }
  }
};

const getChildNodeClasses = (node: ChildNode, classes: string[]) => {
  const element = node as Element;
  for (let i = 0; i < element.children.length; i++) {
    const childNode = element.children[i];
    getChildNodeClasses(childNode, classes);
    classes.push(...childNode.classList);
  }
};

const convertFromEditorNode = (node: ChildNode) => {
  if (node.hasChildNodes()) {
    node.childNodes.forEach(convertFromEditorNode);
  }

  switch (node.nodeName.toLowerCase()) {
    case 'p':
      node.replaceWith(...node.childNodes);
      break;
    case 'span':
    case 'b':
    case 'strong':
    case 'i':
    case 'em':
    case 'u': {
      const element = node as HTMLElement;
      if (element.classList.contains(ThemeClasses.text.base)) {
        let newNode: ChildNode = document.createTextNode(node.textContent || '');
        if (element.classList.contains(ThemeClasses.text.underline)) {
          const underline = document.createElement('u');
          underline.appendChild(newNode);
          newNode = underline;
        }
        if (element.classList.contains(ThemeClasses.text.italic)) {
          const italic = document.createElement('i');
          italic.appendChild(newNode);
          newNode = italic;
        }
        if (element.classList.contains(ThemeClasses.text.bold)) {
          const bold = document.createElement('b');
          bold.appendChild(newNode);
          newNode = bold;
        }
        node.replaceWith(newNode);
      }
      element.append(...node.childNodes);
      break;
    }
  }
};
