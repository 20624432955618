export const ThemeClasses = {
  paragraph: 'paragraph',
  text: {
    base: 'text',
    bold: 'bold',
    italic: 'italic',
    underline: 'underline',
  },
} as const;

export const Converters = {
  default: 'default',
  einserat: 'einserat',
} as const;
