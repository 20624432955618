import { HtmlConverter } from './types';

export const defaultConverter: HtmlConverter = {
  convertFromEditorHtml: (value) => {
    const parser = new DOMParser();
    const root = parser.parseFromString(value || '', 'text/html');
    if (root.body.textContent === '') {
      return '';
    }
    return value || '';
  },
  convertToEditorHtml: (value) => {
    return value || '';
  },
};
