import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertDescription, AlertTitle, Divider } from '@chakra-ui/react';
import { DescriptionList, DescriptionListItem, DescriptionListTerm } from '@netiva/classifieds-ui';

import { useDate } from '@/hooks';
import { useAppSelector } from '@/store';
import { Price } from './Price';

export const EInseratReview: FunctionComponent = () => {
  const { selectedIssueDates } = useAppSelector((state) => state.ad);
  const { t } = useTranslation();
  const { formatDate } = useDate();

  return (
    <>
      <DescriptionList>
        <DescriptionListTerm>{t('ad.steps.review.issueDates')}</DescriptionListTerm>
        {selectedIssueDates.map((date) => (
          <DescriptionListItem key={date} textAlign="right">
            {formatDate(date)}
          </DescriptionListItem>
        ))}
      </DescriptionList>
      <Divider my={4} />
      <Price showMultipleIssues />
      <Alert status="info" flexDirection="column">
        <AlertTitle>{t('ad.steps.review.guidelinesMessage.title')}</AlertTitle>
        <AlertDescription dangerouslySetInnerHTML={{ __html: t('ad.steps.review.guidelinesMessage.text') }} />
      </Alert>
    </>
  );
};
