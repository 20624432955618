import { ChangeEvent, FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountFormData } from '@/store/account/types';
import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '@/store';
import { accountActions } from '@/store/account';
import { RequiredAccountFields, SupportedCountries } from '@/config';

export type AccountCountrySelectProps = {
  name: keyof AccountFormData;
  label: string;
  isRequired?: boolean;
};

export type CountryOption = {
  label: string;
  value: string;
};

export const AccountCountrySelect: FC<AccountCountrySelectProps> = ({ name, label }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { formData } = useAppSelector((state) => state.account);
  const value = useMemo(() => (formData[name] as string) || '', [formData, name]);

  const { t: countries } = useTranslation('countries');
  const options = useMemo(() => {
    const options = SupportedCountries.map<CountryOption>((code) => ({ value: code, label: countries(code) }));
    // sort: ch at the top, rest alphabetically
    options.sort((a, b) => (a.value === 'ch' ? -1 : b.value === 'ch' ? 1 : a.label.localeCompare(b.label)));
    return options;
  }, [countries]);

  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch(accountActions.updateFormData({ key: name, value: e.target.value }));
  };

  const isRequired = RequiredAccountFields.includes(name);

  return (
    <FormControl isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>
      <Select name={name} onChange={handleOnChange} value={value.toLowerCase()}>
        <option hidden>{t('account.form.selectCountry')}</option>
        {options.map((item: CountryOption) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};
