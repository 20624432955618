import { FC } from 'react';
import { Container, ContainerProps } from '@chakra-ui/react';

export const LayoutContainer: FC<ContainerProps> = ({ padding = 4, ...rest }) => {
  return (
    <Container
      maxWidth={{
        base: 'full',
        sm: 'layout.sm',
        md: 'layout.md',
        lg: 'layout.lg',
        xl: 'layout.xl',
        '2xl': 'layout.2xl',
      }}
      padding={padding}
      {...rest}
    />
  );
};
